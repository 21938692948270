const styles = {
    padLeftStyle: {
        paddingLeft: '15px',
    },
    padLeftLightStyle: {
        paddingLeft: '10px',
    },
    inlineStyle: {
        display: 'inline',
        marginRight: '20px',
    },
    goodStyle: {
        color: 'green',
    },
    badStyle: {
        color: 'red',
    },
    page: {
        flexDirection: 'row',
        backgroundColor: 'white'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    text: {
        fontSize: 11,
        fontStyle: 'Oswald'
    },
    iframe: {
        width: '90%',
        height: '900px',
        display: 'initial',
        position: 'relative'
    },
    bulletPoint: {
        width: 10,
        fontSize: 10,
    },
    itemContent: {
        fontSize: 10,
        display: 'block',
        fontStyle: 'Oswald',
        flex: 1,
        flexWrap: 'wrap'
    },
    heading: {
        display: 'block',
        fontSize: 17,
        marginTop: 21,
        marginBottom: 21,
        marginLeft: 0,
        marginRight: 0,
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
    subheading: {
        display: 'block',
        fontSize: 13,
        marginTop: 12,
        marginBottom: 12,
        marginLeft: 0,
        marginRight: 0,
        fontWeight: 'bold',
    },
    // Fix text wrapping issue
    item: {
        paddingHorizontal: 8,
        flexDirection: 'row',
        marginBottom: 5,
        display: 'inline-block',
        width: 19,
    },
    paragraph: {
        fontSize: 10,
        display: 'block',
        marginTop: 12,
        marginBottom: 17,
        marginLeft: 0,
        marginRight: 0,
        fontStyle: 'Oswald'
    },
};

export default styles;
