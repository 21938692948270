import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { getAllTeachers, getJobs } from '../../state/actions/dashboardAuth';
import { getDistricts, getDistrictData } from '../../state/actions/districtData';
import { getEmployers } from '../../state/actions/employers';
import { logoutUser } from '../../state/actions/auth';
import ReactTable from '../../components/ReactTable/ReactTable';

const col = createTheme({
  palette: {
    primary: {
      main: '#B31728'
    },
  }
});

class Dashboard extends Component {
     constructor() {
       super();
       this.state = {
        teachersList: [],
        employersList: [],
        districtData: [],
        districts: [],
        jobs: [],
       };
     }

     componentDidMount() {
      this.props.getAllTeachers().then(result => {
        if (this.state.teachersList.length !== result.length) {
          this.setState({
            teachersList: result });
        }
      });

      this.props.getDistrictData().then(result => {
        if (this.state.districtData.length !== result.length) {
        this.setState({
          districtData: result });
        }
      });

      this.props.getEmployers().then(result => {
        if (this.state.employersList.length !== result.length) {
        this.setState({
          employersList: result });
        }
      });

      this.props.getJobs().then(result =>
        this.setState({
          jobs: result }));

     this.props.getDistricts().then(result =>
      this.setState({
        districts: result }));
    }

  render() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={col}>
        <ReactTable
          allTeachers={this.state.teachersList}
          district={this.state.districtData}
          districts={this.state.districts}
          jobs={this.state.jobs}
          employers={this.state.employersList}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
}

const mapStateToProps = state => ({
  user: state.auth.user,
  teachers: state,
  userIsEmployer: state.auth.userIsEmployer,
  postings: state.employer.postings,
  applicants: state.employer.applicants,
  resumeLinks: state.employer.resumeLinks,
  hasStartedNewPosting: state.newJobForm.hasStartedNewPosting,
  employerData: state.employer.employerData,
  externalEmployerData: state.employer.externalEmployerData,
  userData: state.auth.userData,
});

export default withRouter(
  connect(
      mapStateToProps,
      {
          getAllTeachers,
          getDistrictData,
          getDistricts,
          getJobs,
          getEmployers,
          logoutUser,
      }
  )(Dashboard)
);
