import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ApplicationView from '../ApplicationView/ApplicationView';
import EmployerPostingList from '../DistrictPostingList/DistrictPostingList';
import TeacherAppliedJobsList from '../TeacherAppliedJobsList/TeacherAppliedJobsList';
import JobList from '../JobList/JobList';
import TeacherSettings from '../TeacherSettings/TeacherSettings';
import EmployerSettings from '../DistrictSettings/DistrictSettings';
import NewTeacherSetup from '../NewTeacherSetup/NewTeacherSetup';
import DistrictSignUpSuccessPage from '../DistrictSignUpSuccessPage/DistrictSignUpSuccessPage';
import DistrictSignUpPage from '../DistrictSignUpPage/DistrictSignUpPage';
import TeacherSignUpPage from '../TeacherSignUpPage/TeacherSignUpPage';
import SignUpSelect from '../SignUpSelect/SignUpSelect';
import LoginPage from '../LoginPage/LoginPage';
import ForgotPasswordPage from '../ForgotPasswordPage/ForgotPasswordPage';
import AboutUs from '../AboutUs/AboutUs';
import TeacherList from '../TeacherList/TeacherList';
import Faqs from '../Faqs/Faqs';
import Terms from '../TermsAndConditionsPage/TermsAndConditionsPage';
import Privacy from '../PrivacyPolicyPage/PrivacyPolicyPage';
import TeacherView from '../TeacherView/TeacherView';
import Dashboard from '../Dashboard/Dashboard';
import ContactUs from '../ContactUs/ContactUs';
import UserValidationSuccess from '../UserValidationSuccess/UserValidationSuccess';

function MainRouter() {
  return (
    <BrowserRouter>
      <div
        style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%',
        margin: 'auto',
      }}
      >
        <Switch>
          <Route
            path="/application/:jobId/:applicationId"
            component={ApplicationView}
          />
          <Route
            path="/teacher/:teacherId"
            component={TeacherView}
          />
          <Route path="/postings" component={EmployerPostingList} />
          <Route path="/applied" component={TeacherAppliedJobsList} />
          <Route exact path="/" component={JobList} />
          <Route path="/teachersettings" component={TeacherSettings} />
          <Route path="/employersettings" component={EmployerSettings} />
          <Route path="/newteachersetup" component={NewTeacherSetup} />
          <Route path="/terms" component={Terms} />
          <Route path="/privacy" component={Privacy} />
          <Route
            path="/signup/district/success"
            component={DistrictSignUpSuccessPage}
          />
          <Route path="/signup/district" component={DistrictSignUpPage} />
          <Route path="/signup/teacher" component={TeacherSignUpPage} />
          <Route path="/signup" component={SignUpSelect} />
          <Route path="/login" component={LoginPage} />
          <Route path="/forgotpassword" component={ForgotPasswordPage} />
          <Route path="/aboutus" component={AboutUs} />
          <Route path="/faqs" component={Faqs} />
          <Route path="/searchteachers" component={TeacherList} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/uservalidationsuccess" component={UserValidationSuccess} />

        </Switch>
      </div>
    </BrowserRouter>
);
}

export default MainRouter;
