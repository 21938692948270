const styles = {
  formCenterStyle: {
    margin: 'auto',
    width: '50%',
  },
  buttonSpaceStyle: {
    marginRight: '10px',
  },
};

export default styles;
