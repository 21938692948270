import * as types from '../actions/types';

const INITIAL_STATE = {
  isAddingNewQuestion: false,
  isDeletingQuestions: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADDING_NEW_EMPLOYER_QUESTION:
      return { ...INITIAL_STATE, isAddingNewQuestion: true };
    case types.ADD_NEW_EMPLOYER_QUESTION_SUCCESS:
      return { ...INITIAL_STATE, isAddingNewQuestion: false };
    case types.DELETING_QUESTIONS:
      return { ...INITIAL_STATE, isDeletingQuestions: true };
    case types.DELETE_QUESTIONS_SUCCESS:
      return { ...INITIAL_STATE, isDeletingQuestions: false };
    default:
      return state;
  }
};
