import * as types from './types';
const zips = require('us-zips');

const getPositionSuccess = (pos, dispatch) => {
  dispatch({ type: types.UPDATE_CURRENT_GEO, payload: pos });
};


export const updateUserLocation = (zipCode) => dispatch => {
  dispatch({ type: types.LOOKUP_CURRENT_GEO });
  navigator.geolocation.getCurrentPosition(
    pos => getPositionSuccess(pos, dispatch),
    () => {
      try {
        const location = zips[zipCode];
        if (location) {
          dispatch({ type: types.UPDATE_CURRENT_GEO_FROM_ZIPCODE, payload: location });
        } else {
          dispatch({ type: types.UPDATE_CURRENT_GEO_FAILED })
        }
      } catch (err) {
        console.log(err);
      }
    }
  );
};
