import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { some, includes } from 'lodash';
import geolib from 'geolib';
import Fuse from 'fuse.js';
import TextField from '@mui/material/TextField';
import { getJobsList } from '../../state/actions/getJobs';
import { updateUserLocation } from '../../state/actions/geo';
import { logoutUser } from '../../state/actions/auth';
import JobListCard from '../../components/JobListCard/JobListCard';
import SkeletonLoadingCard from '../../components/SkeletonLoadingCard/SkeletonLoadingCard';
import TeacherAlertCard from '../../components/TeacherAlertCard/TeacherAlertCard';
import CustomCard from '../../components/CustomCard/CustomCard';
import LandingComponent from '../../components/LandingComponent/LandingComponent';
import Theme from '../../theme/Theme';
import PageLayout from '../PageLayout/PageLayout';
import CustomSwitch from '../../components/CustomSwitch/CustomSwitch';
import { getTeacherRefLetters, getTeacherResumes, getTeacherTranscripts, getTeacherJobsAppliedFor } from '../../state/actions/getTeachers';

const calcJobDistances = (geo, jobs) => {
  const distances = {};
  try {
    jobs.forEach(job => {
      if (!job['district']['latitude'] || !job['district']['longitude']) {
        distances[job.id] = -1
      } else {
        distances[job.id] = geolib.convertUnit(
          'mi',
          geolib.getDistance(
            { latitude: geo.latitude, longitude: geo.longitude },
            {
              // eslint-disable-next-line
              latitude: job['district']['latitude'],
              // eslint-disable-next-line
              longitude: job['district']['longitude']
            },
            1
          )
        ).toFixed(1);
      }
    });
  } catch (err) {
    console.error(err);
    console.error('There was a problem calculating the distances.');
  }
  return distances;
};

const filterJobsForCredentials = (jobs, userData) => {
  var filteredJobs = [];
  let currentJobGood = false;

  try {
    if (userData.selectedLicensure.length !== 0) {
      jobs.forEach(job => {
        currentJobGood = false;

        userData.selectedLicensure.forEach(userLicense => {
          if (currentJobGood) {
            return;
          }
          // console.log(`Comparing ${job.selectedLicensure} to ${userLicense}`);
          // console.dir(job.selectedLicensure);
          // console.dir(userLicense);
          if (some(job.selectedLicensure, userLicense)) {
            currentJobGood = true;
          }
        });
        if (currentJobGood) {
          filteredJobs.push(job);
        }
      });
    } else {
      // The user has no selectedLicensures at all, so no jobs will match
      // Therefore, we just show them all jobs.
      filteredJobs = jobs
    }
  } catch (err) {
    console.error(err);
  }
  return filteredJobs;
};

class JobList extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    // Don't do anything if we don't have any jobs
    if (nextProps.jobs === null) {
      return nextProps
    }
    // If we haven't filtered jobs for the user yet, do it
    let { filteredJobs, jobDistances, stringSearch, milesSearch, isLoggedIn, searchFormReset,  showMatchingJobs, searchResults } = prevState;
    // first visit to homepage - or any visit of a non logged-in user
    if (!nextProps.userData) {
      // The user is not logged in
      filteredJobs = nextProps.jobs;
      // AB, TODO: Why do we clear out jobDistances here?
      jobDistances = []; // Fix job distances
      if (prevState.isLoggedIn && !searchFormReset) {
        // AB 8/21/22: It's probably OK to keep track of the user logging out. We could look
        // for selectedLicensures, though. We may not even need isLoggedIn in state after that.
        // The user was logged in, and now they are not
        // Clear the search term
        stringSearch = '';
        milesSearch = '';
        searchResults = [];
        searchFormReset = true;
        showMatchingJobs = false;
      }
      return { filteredJobs, jobDistances, stringSearch, milesSearch, searchFormReset, showMatchingJobs, searchResults };
    } else {
      // The user is logged in
      isLoggedIn = true;
      if (
        prevState.filteredJobs === null &&
        nextProps.jobs
      ) {
        filteredJobs = filterJobsForCredentials(
          nextProps.jobs,
          nextProps.userData
        );
      } else if (!prevState.isLoggedIn) {
        // AB 8/21/22: There may be no reason to filter jobs if the user has no selected
        // licensures. We should probably check that instead of isLoggedIn
        // The user is logged in but wasn't before, so we need to filter jobs
        filteredJobs = filterJobsForCredentials(
          nextProps.jobs,
          nextProps.userData
        );
      } else if (prevState.jobs !== nextProps.jobs) {
        // The list of jobs has changed
        filteredJobs = filterJobsForCredentials(
          nextProps.jobs,
          nextProps.userData
        );
      } else {
        filteredJobs = prevState.filteredJobs;
      }
      // Add distances to state when we first receive the jobs
      if (
        nextProps.geo.latitude &&
        nextProps.geo.longitude &&
        nextProps.jobs &&
        nextProps.jobs.length > 0 &&
        prevState.jobDistances.length == 0
      ) {
        jobDistances = calcJobDistances(nextProps.geo, nextProps.jobs);
      }
      return { filteredJobs, jobDistances, isLoggedIn, searchFormReset, showMatchingJobs };
    }
  }

  state = {
    // Jobs returned from backend filtered to those that match the user's licensures
    filteredJobs: null,
    // Text entered in the search field
    stringSearch: '',
    // Text entered in the miles field
    milesSearch: '',
    // Results of filtering either all jobs or filtered jobs by the search field value and/or the miles value
    searchResults: [],
    // Array of distances between user and job, keyed by job id
    jobDistances: [], // jobId -> distance in miles
    // Status of checkbox, show only jobs matching user's licensures or all jobs
    showMatchingJobs: true,
    // User is logged in and data about the user is available in props
    isLoggedIn: null,
    // When set, means the user logged out while looking at a job list, so the search form needs to be reset.
    searchFormReset: false,
  };

  componentDidMount() {
    // TODO: somehow notify that we need to filter jobs again
    // Don't try and fetch the jobs list if the user is logging in
    if (this.props.isLoggingIn == false) {
      this.props.getJobsList();
    }
    if (this.props.userData !== null) {
      if (this.props.geo.gettingUserGeo == null && (this.props.geo.latitude == null || this.props.geo.longitude == null)) {
        this.props.updateUserLocation(this.props.userData.zipCode);
      }
    }
    if (this.props.user !== null) {
      // Fetch the user location from the browser, if we can
      // Use zip from profile (if available), if can't get
      // user location.
      if (!this.props.userIsEmployer) {
        this.props.getTeacherJobsAppliedFor(this.props.user.uid);
        this.props.getTeacherResumes(this.props.user.uid);
        this.props.getTeacherTranscripts(this.props.user.uid);
        this.props.getTeacherRefLetters(this.props.user.uid);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userData !== this.props.userData) {
      this.props.getJobsList();
    } else if (!prevProps.userData && !this.props.isLoggingIn && !this.state.filteredJobs) {
      this.props.getJobsList();
    } else if (prevState.showMatchingJobs != this.state.showMatchingJobs) {
      // The user toggled the switch to match on their credentials.
      this.updateSearchResults()
    }
    // If we have not yet fetched the user location from the browser, then do so now
    // Look up by zip if we can't get user location
    if (this.props.userData !== null && this.props.geo.gettingUserGeo == null && (this.props.geo.latitude == null || this.props.geo.longitude == null)) {
      this.props.updateUserLocation(this.props.userData.zipCode);
    }
    const { userIsEmployer } = this.props;
    if (userIsEmployer) {
      this.props.history.push('/postings');
    } else if (this.props.user !== null) {
      // User is a Teacher, fetch reference letters, transcripts, resumes if don't have already
      if (this.props.teacher.teacherJobsAppliedFor == null && !this.props.teacher.gettingTeacherJobsAppliedFor) {
        this.props.getTeacherJobsAppliedFor(this.props.user.uid);
      }
      if (this.props.teacherRefLetters == null) {
        this.props.getTeacherRefLetters(this.props.user.uid);
      }
      if (this.props.teacherResumes == null) {
        this.props.getTeacherResumes(this.props.user.uid);
      }
      if (this.props.teacherTranscripts == null) {
        this.props.getTeacherTranscripts(this.props.user.uid);
      }
    }
  }

  onSearchTextChange = e => {
    this.setState({ stringSearch: e.target.value }, this.updateSearchResults);
  };

  onMilesSearchTextChange = e => {
    // get rid of all letters
    const numericInput = e.target.value.replace(/\D/g, '');
    this.setState({ milesSearch: numericInput }, this.updateSearchResults);
  };

  updateSearchResults = () => {
    const { filteredJobs } = this.state;

    let currentResults;
    // AB 8/21/22: There is little point in checking isLoggedIn if the user has no selected
    // licensures.
    if (!this.state.isLoggedIn || !this.state.showMatchingJobs) {
      currentResults = this.props.jobs
    } else {
      currentResults = filteredJobs
    }
    // TODO: description matching is wonky, can we fix this?
    // TODO: Search in more fields (district name, etc)
    // IDK wtf i did to the options.  I still do not quite understand them, but it kinda works now
    if (this.state.stringSearch !== '') {
      const options = {
        shouldSort: true,
        threshold: 0.5,
        location: 0,
        distance: 1000,
        maxPatternLength: 100,
        minMatchCharLength: 1,
        keys: ['title', 'description'],
      };
      const fuse = new Fuse(currentResults, options);
      currentResults = fuse.search(this.state.stringSearch);
    }

    if (this.state.milesSearch !== '') {
      // remove from currentResults jobs that have distance > maxMiles
      currentResults = currentResults.filter(
        job => parseInt(this.state.jobDistances[job.id]) <= parseInt(this.state.milesSearch)
      );
    }

    // update the final search results
    this.setState({ searchResults: currentResults });
  };

  // need three array(maybe four)s: filteredJobs, seeableJobs
  checkboxToggleListings = (event) => {
    // Rather than using a callback, we rely on componentDidUpdate being called once
    // state is successfully changed.
    this.setState({ showMatchingJobs: event.target.checked });
  };

  // TODO: fix the implementation of the show all job listings
  renderCheckbox = () => {
    // AB: 8/21/22 Checking for the presence of userData shouldn't determine if we show the
    // checkbox or not. The checkbox is useless if the user has no selected licensures.
    //if (this.props.userData) {
    if (this.props.userData?.selectedLicensure.length > 0) {
      // const msg = this.props.userData.selectedLicensure.length !== 0 ? '' : 'You currently have no licensures for your profile.';
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          verticalAlign: 'middle'
        }}
        >
          <CustomSwitch
            label={this.state.showMatchingJobs ? 'Show All Jobs that match my credentials'
              : 'Show All Jobs - even if they don’t match my credentials'}
            onChange={(event) => this.checkboxToggleListings(event)}
            checked={this.state.showMatchingJobs}
          />
        </div>
      );
    }
    return null;
  }

  renderWelcome = () => {
    const firstName = this.props.userData
      ? this.props.userData.firstName
      : 'teachers';
    return (
      <div>
        <div style={{ marginLeft: 10 }}>
          <h2 style={{ fontWeight: 600, display: 'inline', }}>
            A FREE resource designed to help{' '}
            <span
              style={{
                color: Theme.ThemePrimary,
                fontWeight: 600,
                display: 'inline',
              }}
            >
              {firstName}
            </span>{' '}
            find great jobs.
          </h2>
        </div>
        <p style={{ visibility: this.props.userData ? 'visible' : 'hidden' }}>
          Check out the jobs below that match your credentials
        </p>
      </div>
    );
  };

  renderSearchCard = () => (
    <CustomCard
      customStyle={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <form style={{ display: 'flex', flexWrap: 'wrap' }}>
        <TextField
          id="standard-name"
          label="Search"
          margin="normal"
          helperText="Search for the job description or title"
          value={this.state.stringSearch}
          onChange={this.onSearchTextChange}
          style={{ marginLeft: 10, marginRight: 10 }}
        />
        <TextField
          id="standard-name"
          label="Distance"
          margin="normal"
          helperText="Enter the miles"
          value={this.state.milesSearch}
          onChange={this.onMilesSearchTextChange}
          style={{ marginLeft: 10, marginRight: 10 }}
        />
      </form>
    </CustomCard>
  );

  renderJob = job => {
    // Filter out jobs with negative distance
    // We set distance to -1 if a job is missing latitude/longitude
    if (this.state.jobDistances[job.id] >= 0 || this.state.jobDistances.length == 0) {
      return (
        <JobListCard
          key={job.id}
          job={job}
          hasApplied={includes(this.props.teacher.teacherJobsAppliedFor, job.id)}
          isApplying={includes(this.props.teacher.teacherJobsApplyingFor, job.id)}
          appsOpen={includes(this.props.appsOpen, job.id)}
          resumes={this.props.teacherResumes}
          transcripts={this.props.teacherTranscripts}
          referenceLetters={this.props.teacherRefLetters}
          begunApplication={includes(this.props.begunApplication, job.id)}
          selectedResume={this.props.selectedResumes[job.id]}
          milesAway={this.state.jobDistances[job.id]}
          user={this.props.user}
          userData={this.props.userData}
        />
      )
    }
  };

  // needs fixing filteredJobs.length will always be 0 when starting page
  renderJobResults = () => {
    if (this.state.searchResults.length === 0 && this.state.stringSearch == "" && this.state.milesSearch == "") {
      // AB 8/21/22: Checking to see if the user is logged in isn't really useful here
      // If they have no selected licensures then there is no point in showing the
      // checkbox.
      if (!this.state.isLoggedIn || !this.state.showMatchingJobs) {
        // The user is not logged in
        return this.props.jobs.map(this.renderJob);
      }
      if (this.state.filteredJobs.length !== 0) {
        this.state.filteredJobs.sort((a, b) => (a.datePosted > b.datePosted ? 1 : -1));
        this.state.filteredJobs.sort((a, b) => (a.isClosed > b.isClosed ? 1 : -1));
        return this.state.filteredJobs.map(this.renderJob);
      } else {
        return <div>No search results found!</div>;
      }
    } else if (this.state.searchResults.length === 0 && (this.state.stringSearch !== "" || this.state.milesSearch !== "")) {
      return <div>No search results found!</div>;
    } else {
      return this.state.searchResults.map(this.renderJob);
    }
  };

  renderCards = () => {
    const { jobs } = this.props;
    if (jobs === null) {
      return (
        <div>
          <SkeletonLoadingCard />
          <SkeletonLoadingCard />
          <SkeletonLoadingCard />
          <SkeletonLoadingCard />
        </div>
      );
    }
    return (
      <div>
        {this.renderSearchCard()}
        {this.renderCheckbox()}
        {this.renderJobResults()}
      </div>
    );
  };

  render() {
    return (
      <PageLayout>
        <LandingComponent welcomeMessage={this.renderWelcome()} />
        <TeacherAlertCard />
        {this.renderCards()}
      </PageLayout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  isLoggingIn: state.auth.isLoggingIn,
  userIsEmployer: state.auth.userIsEmployer,
  jobs: state.jobs.jobs,
  appsOpen: state.jobs.applicationsOpen,
  begunApplication: state.apply.begunApplication,
  selectedResumes: state.apply.selectedResumes,
  userData: state.auth.userData,
  geo: state.geo,
  teacherRefLetters: state.teacherRefLetters.teacherRefLetters,
  teacherResumes: state.teacherResumes.teacherResumes,
  teacherTranscripts: state.teacherTranscripts.teacherTranscripts,
  teacher: state.teacher
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getJobsList,
      updateUserLocation,
      logoutUser,
      getTeacherRefLetters,
      getTeacherResumes,
      getTeacherTranscripts,
      getTeacherJobsAppliedFor
    }
  )(JobList)
);
