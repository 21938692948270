import * as types from '../actions/types';

const INITIAL_STATE = {
  jobs: null,
  applicationsOpen: [],
  gettingJobs: false,
};

// Data associated with the following keys are all JavaScript objects.
// They have to be JSON.stringify()'d before being sent to the backend.
// When they come back from the backend, they have to be JSON.parse()'d
// back into JavaScript objects.
const JOB_JSON_KEYS = [
  'selectedQuestions',
  'selectedLicensure',
  'selectedGradeLevels',
  'selectedPartOrFullTime'
]
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_JOBS_LIST:
      return { ...state, gettingJobs: true };
    case types.GET_JOBS_LIST_SUCCESS:
      // Loop through the jobs in the payload
      // For every key in the object whose value can be a JavaScript object,
      // modify the key's value to be the result of a JSON.parse on that value
      return { ...state, gettingJobs: "false", jobs: action.payload.map( job => {
        JOB_JSON_KEYS.forEach(key => {
          if (key in job) {
            try {
              job[key] = JSON.parse(job[key])
            } catch (error) {}
          }
        })
        return job
      })}
    case types.GET_JOBS_LIST_FAIL:
      return { ...state, gettingJobs: false };
    case types.OPEN_JOB_FOR_APPLICATIONS: {
      const openedCopy = state.applicationsOpen.slice();
      openedCopy.push(action.payload);
      return { ...state, applicationsOpen: openedCopy };
    }
    case types.UPDATE_JOBS:
      return { ...state, jobs: action.payload };
    case types.ADD_JOB_TO_STATE: {
        const jobsCopy = state.jobs.slice();
        // We need to loop over the keys in action.payload
        // if the key matches one in JOB_JSON_KEYS, then we need to parse it
        // because it's been stringified, but we want it as a JSON object in state
        // We make a shallow copy of action.payload, because
        // from newJobForm we dispatch ADD_POSTING_TO_STATE and
        // ADD_JOB_TO_STATE one will modify the contents of
        // action.payload before the other can get to it, which
        // causes errors.
        var payloadCopy = Object.assign({}, action.payload)
        Object.keys(payloadCopy).forEach(key => {
          if (JOB_JSON_KEYS.includes(key)) {
            payloadCopy[key] = JSON.parse(payloadCopy[key])
          }
        })
        jobsCopy.unshift(payloadCopy);
        return { ...state, jobs: jobsCopy };
      }
    default:
      return state;
  }
};
