import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

// old test settings
// const config = {
//   apiKey: 'AIzaSyAboqqE0v_wuKOadABIsi2wUYQ_sW28uzM',
//   authDomain: 'teach-dev-2eecc.firebaseapp.com',
//   databaseURL: 'https://teach-dev-2eecc.firebaseio.com',
//   projectId: 'teach-dev-2eecc',
//   storageBucket: 'teach-dev-2eecc.appspot.com',
//   messagingSenderId: '765953992369',
// };

// example environment variables
// REACT_APP_FIREBASE_APIKEY=AIzaSyAboqqE0v_wuKOadABIsi2wUYQ_sW28uzM
// REACT_APP_FIREBASE_AUTHDOMAIN=teach-dev-2eecc.firebaseapp.com
// REACT_APP_FIREBASE_DATABASEURL=https://teach-dev-2eecc.firebaseio.com
// REACT_APP_FIREBASE_PROJECTID=teach-dev-2eecc
// REACT_APP_FIREBASE_STORAGEBUCKET=teach-dev-2eecc.appspot.com
// REACT_APP_FIREBASE_MESSAGINGSENDERID=765953992369

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
};

firebase.initializeApp(config);

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const getGeoPoint = (latitude, longitude) => new firebase
  .firestore
  .GeoPoint(latitude, longitude);

export default firebase;
