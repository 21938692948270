import React, { Component } from 'react';
import { MDBContainer, MDBFooter } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { withRouter } from 'react-router-dom';
import Theme from '../../theme/Theme';
import uarkLogo from '../../assets/uofa-horizontal.png';
import CustomModal from '../CustomModal/CustomModal';

class Footer extends Component {
    render() {
        return (
          <MDBFooter style={{ backgroundColor: Theme.ThemeDanger }} className="font-small pt-2 mt-4">
            <div className="footer-copyright text-center py-3">
              <MDBContainer fluid>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={uarkLogo} alt="University of Arkansas" style={{ height: 50, alignSelf: 'center', margin: 25 }} />
                </div>
                <a href="/terms">Terms</a>
                <a style={{ marginLeft: 20 }} href="/privacy">Privacy</a>
                {this.modal}
                <div>
                  <a href="/contactus">Contact Us</a><br />
                  All Rights Reserved&copy; {(new Date().getFullYear())}
                </div>
              </MDBContainer>
            </div>

          </MDBFooter>
        );
    }
}
// const Footer = ({ children }) => (

// );

export default withRouter(Footer);
