import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createTeacher } from '../../state/actions/teacherSignUpForm';
import CustomButton from '../CustomButton/CustomButton';
import PageLayout from '../../pages/PageLayout/PageLayout';

const TeacherSignUpSchema = Yup.object({
  firstName: Yup.string().required('Please enter your first name'),
  lastName: Yup.string().required('Please enter your last name'),
  email: Yup
    .string()
    .required('Please enter your email address')
    .email('Invalid email'),
  confirmEmail: Yup
    .string()
    .oneOf([Yup.ref('email'), null], 'Emails must match')
    .required('Please enter your email address again'),
  password: Yup
    .string()
    .required('Please enter a password')
    .min(6, 'Password must be at least 6 characters'),
  confirmPassword: Yup
    .string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Please enter the password again')
});

function TeacherSignUpForm(props) {
  return (
    <PageLayout>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
        <h2>Teacher signup</h2>
        <Formik
            validationSchema={TeacherSignUpSchema}
            onSubmit={async (values, actions) => {
              props.createTeacher(values);
              actions.resetForm();
              actions.setSubmitting(false);
            }}
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              confirmEmail: '',
              password: '',
              confirmPassword: ''
            }}
          >
            {formik => (
            <Form noValidate onSubmit={formik.handleSubmit}>
              <Row>
                <Form.Group
                  controlId="firstName"
                  style={{ margin: 10 }}
                >
                  <Form.Label className={!formik.touched.firstName ? "" : (formik.errors.firstName ? "text-danger" : "")}>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    isInvalid={formik.errors.firstName && formik.touched.firstName}
                    isValid={!formik.errors.firstName && formik.touched.firstName}
                    style={{ width: 250 }}
                    {...formik.getFieldProps('firstName')}
                  />
                  <ErrorMessage component="div" className="text-danger" name="firstName" />
                </Form.Group>
                <Form.Group
                  controlId="lastName"
                  style={{ margin: 10 }}
                >
                  <Form.Label className={!formik.touched.lastName ? "" : (formik.errors.lastName ? "text-danger" : "")}>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter last name"
                    isInvalid={formik.errors.lastName && formik.touched.lastName}
                    isValid={!formik.errors.lastName && formik.touched.lastName}
                    style={{ width: 250 }}
                    {...formik.getFieldProps('lastName')}
                  />
                  <ErrorMessage component="div" className="text-danger" name="lastName" />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  controlId="email"
                  style={{ margin: 10 }}
                >
                  <Form.Label className={!formik.touched.email ? "" : (formik.errors.email ? "text-danger" : "")}>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    isInvalid={formik.errors.email && formik.touched.email}
                    isValid={!formik.errors.email && formik.touched.email}
                    style={{ width: 250 }}
                    {...formik.getFieldProps('email')}
                  />
                  <ErrorMessage component="div" className="text-danger" name="email" />
                </Form.Group>
                <Form.Group
                  controlId="confirmEmail"
                  style={{ margin: 10 }}
                >
                  <Form.Label className={!formik.touched.confirmEmail ? "" : (formik.errors.confirmEmail ? "text-danger" : "")}>Confirm Email</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter email again"
                    isInvalid={formik.errors.confirmEmail && formik.touched.confirmEmail}
                    isValid={!formik.errors.confirmEmail && formik.touched.confirmEmail}
                    style={{ width: 250 }}
                    {...formik.getFieldProps('confirmEmail')}
                  />
                  <ErrorMessage component="div" className="text-danger" name="confirmEmail" />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group
                  controlId="password"
                  style={{ margin: 10 }}
                >
                  <Form.Label className={!formik.touched.password ? "" : (formik.errors.password ? "text-danger" : "")}>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Create a password"
                    isInvalid={formik.errors.password && formik.touched.password}
                    isValid={!formik.errors.password && formik.touched.password}
                    style={{ width: 250 }}
                    {...formik.getFieldProps('password')}
                  />
                  <ErrorMessage component="div" className="text-danger" name="password" />
                </Form.Group>
                <Form.Group
                  controlId="confirmPassword"
                  style={{ margin: 10 }}
                >
                  <Form.Label className={!formik.touched.confirmPassword ? "" : (formik.errors.confirmPassword ? "text-danger" : "")}>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter password again"
                    isInvalid={formik.errors.confirmPassword && formik.touched.confirmPassword}
                    isValid={!formik.errors.confirmPassword && formik.touched.confirmPassword}
                    style={{ width: 250 }}
                    {...formik.getFieldProps('confirmPassword')}
                  />
                  <ErrorMessage component="div" className="text-danger" name="confirmPassword" />
                </Form.Group>
              </Row>
              <CustomButton
                  type="submit"
                  isDisabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
                >
                  {formik.isSubmitting ? 'Signing up...' : 'Sign up'}
              </CustomButton>
            </Form>
            )}
        </Formik>
      </div>
    </PageLayout>
  );
}

const mapStateToProps = state => ({
  teacherSignup: state.teacherSignup,
});

export default connect(
  mapStateToProps,
  { createTeacher }
)(TeacherSignUpForm);
