import * as types from '../actions/types';

const INITIAL_STATE = {
    teacherTranscripts: null,
    gettingTeacherTrans: false,
    uploadError: null,
};

// 0: id
// 1: docid (firebase)
// 2: filename
// 3: downloadURL
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_TEACHER_TRANCRIPT:
            return { ...state, gettingTeacherTrans: true };
        case types.GET_TEACHER_TRANCRIPT_SUCCESS:
            return { ...state, teacherTranscripts: action.payload, gettingTeacherTrans: false };
        case types.GET_TEACHER_TRANCRIPT_FAIL:
            return { ...state, gettingTeacherTrans: false };
        case types.UPLOAD_TEACHER_TRANSCRIPT_SUCCESS: {
            const transcripts = state.teacherTranscripts.slice();
            transcripts.push(action.payload)
            return { ...state, teacherTranscripts: transcripts, uploadError: false };
        }
        case types.UPLOAD_TEACHER_TRANSCRIPT_FAIL: {
            return { ...state, uploadError: true };
        }
        case types.RENAME_TEACHER_TRANSCRIPT: {
            // payload here is: {docId, newName}
            const transcripts = state.teacherTranscripts.slice();
            return { ...state, teacherTranscripts: transcripts.map( doc => {
                // payload.docId is the uuid making up the filename on Firebase
                // that value is stored in state in index 0 of the array
                // array index 2 in the array in state is the friendly name of the file
                if (doc[1] == action.payload.docId) {
                    doc[2] = action.payload.newName
                }
                return doc
            })}
        }
        default:
            return state;
    }
};
