const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%',
    textAlign: 'justify',
    padding: 'auto',
  },
  pageDiv: {
    height: '100%', /* Magic here */
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'column',
    width: '50%',
    margin: 'auto',
  },
  image: {
    width: 700,
    height: 400,
  },
  imageDis: {
    width: 500,
    height: 300,
  },
  ques: {
    fontWeight: 50,
    fontStyle: 'bold',
  },
  quesHead: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 20
  },
  faqsLayout: {
    display: 'flex',
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    align: 'center',
    flexDirection: 'row',
  },
};

export default styles;
