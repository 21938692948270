import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Iframe from 'react-iframe';
import { Page, Text, View, Document, PDFViewer, Link } from '@react-pdf/renderer';
import { getQuestions } from '../../state/actions/postings';
import { auth } from '../../firebase/Firebase';
import { getTeacherInfo } from '../../state/actions/getTeachers';
import CustomButton from '../../components/CustomButton/CustomButton';
import styles from './TeacherView.styles';
import { sendDistrictEmail } from '../../state/actions/createDistrictEmail';

// // TODO: MAKE THIS WORK WITH NO RESUME SELECTED
class ApplicationView extends Component {
    componentDidMount() {
      const { teacherId } = this.props.match.params; // working
      if (this.props.isLoggingIn == false) {
        if (this.props.user !== null && this.props.userIsEmployer == true) {
          this.props.getTeacherInfo(teacherId);
        } else {
          this.props.history.push('/');
        }
      }
    }

    componentDidUpdate(prevProps, prevState) {
      const { teacherId } = this.props.match.params;
      if (prevProps.isLoggingIn !== this.props.isLoggingIn) {
        if (this.props.isLoggingIn == false) {
          if (this.props.user !== null && this.props.userIsEmployer == true) {
            this.props.getTeacherInfo(teacherId);
          } else {
            this.props.history.push('/');
          }
        }
      }
    }

    // Create Document Component
    MyDocument = () => {
       const { teacher } = this.props.teacher;
        const { firstName,
            lastName,
            email,
            streetAddress,
            expectedLicenseDate,
            licensureAndState,
            willToWorkTowardsLicense,
            towardsTeachingLicense,
            hasArTeachingLicense,
            hasTeachingLicense,
            microcredentials,
            phoneNumber,
            city,
            state,
            zipCode,
            teachingLicenseType,
            selectedLicensure,
            institution,
            degreeLevel,
            extracurricularActivities,
            educationalPhilosophy,
            contribution,
            legallyAbleToWork,
            veteranOrSpouse,
            violationOfLaw,
            revokedCertificate,
            sexuallyAbusedChild,
            substantiatedChildAbuseCharge,
            hasBeenFired
        } = this.props.teacher;
        const fullName = `${this.props.teacher.firstName} ${this.props.teacher.lastName}`;
        const designations = this.props.teacher.designations ? this.props.teacher.designations : null;
        const date = expectedLicenseDate !== '' ? expectedLicenseDate : '[No Date Provided]';
        const willing = willToWorkTowardsLicense === true ? `${fullName} is willing to work toward a licensure` : `${fullName} is not willing to work toward a licensure`;

        const workingToward = towardsTeachingLicense === true ? `${fullName} does not have a licensure but is working toward one and is expected to recieve it on ${date}` :
            `${fullName} is not working toward a licensure. \n ${willing}`;

        const Arlicense = hasArTeachingLicense === true ? `${fullName} is licensed to teach in Arkansas.` : `
            ${fullName} is licensed to teach in ${licensureAndState}.`; // add willing to gain a teaching liscene in arkansas

        const licenseString = hasTeachingLicense === true // whether can teach in Arkansas or not
            ? `${fullName} has a teaching license. \n ${Arlicense} \n Type of license: ${teachingLicenseType}`
            : `${workingToward}`;

        const { goodStyle, badStyle } = styles;
        return (
          <Document title={`Teacher_${firstName}_${lastName}_Info`}>
            <Page style={styles.page}>
              <View style={styles.section}>
                <Text style={{ textAlign: 'center' }}>{`Information for ${fullName}`}</Text>
                <Text style={styles.heading}>Contact Information</Text>
                <View>
                  <Text style={styles.text}>
                    Email:
                    <Link> {` ${email}`} </Link>
                  </Text>
                  <Text style={styles.text}>{`Phone: ${phoneNumber}`}</Text>
                  <Text style={styles.text}>
                    {`Address: ${streetAddress}, ${city}, ${
                                    state
                                    }, ${zipCode}`}
                  </Text>
                </View>

                <Text style={styles.heading}>Applicant Credentials</Text>
                <View>
                  <View>
                    <Text style={styles.subheading}>Teaching License:</Text>
                  </View>
                  <View>
                    <Text style={styles.text}>{licenseString}</Text>
                    {/* <Text style={styles.text}>{`Type of license: ${teachingLicenseType}.`}</Text> */}
                  </View>
                  <View>
                    <Text style={styles.subheading}>Licensure Areas:</Text>
                  </View>
                  <View>
                    {selectedLicensure.map(licensure => (
                      <View style={styles.item}>
                        <Text style={styles.bulletPoint}>•</Text>
                        <Text style={styles.itemContent}>{licensure.label}</Text>
                      </View>
                                ))}
                  </View>
                  <Text style={styles.subheading}>Designations:</Text>
                  <View>
                    <Text style={styles.text}>{designations}</Text>
                    {/* {designations.map(designation => (
                    <Text key={designation}>{designation}</Text>
                  ))} */}
                  </View>
                  <Text style={styles.subheading}>Degree Information:</Text>
                  <View>
                    <Text style={styles.text}> {`Received degree from: ${institution}`}</Text>
                    <Text style={styles.text}>{`Degree Level: ${degreeLevel}`}</Text>
                  </View>
                  <Text style={styles.subheading}>Micro-credentials:</Text>
                  <View>
                    <Text style={styles.text}>{microcredentials}</Text>
                  </View>
                </View>
              </View>
            </Page>
            <Page style={styles.page}>
              <View style={styles.section}>
                <Text style={styles.heading}>Essays and Cover Letter</Text>
                <View>
                  <Text style={styles.text}>
                    Extracurricular activities willing to sponsor:
                  </Text>
                  <Text style={styles.paragraph}>{extracurricularActivities}</Text>{/* // userData.extracurricularActivities */}

                  {/* Render essay questions and responses */}

                  {this.renderEssayPDF(
                                'Describe your philospohy of education:',
                                educationalPhilosophy
                            )}

                  {this.renderEssayPDF(
                                'What do you consider to be your most important contribution to the field of education and/or most significant accomplishment as a school employee?',
                                contribution
                            )}
                </View>
              </View>
            </Page>
            <Page style={styles.page}>
              <View style={styles.section}>
                <Text style={styles.heading}>Legal Information</Text>
                <Text style={styles.subheading}>{`${fullName}:`}</Text>
                <View>

                  <View style={styles.item}>
                    <Text style={styles.bulletPoint}>•</Text>
                    <Text style={[legallyAbleToWork ? goodStyle : badStyle, styles.itemContent]}>
                      {`is ${legallyAbleToWork ? '' : 'NOT'} legally able to work in the United States`}
                    </Text>
                  </View>

                  <View style={styles.item}>
                    <Text style={styles.bulletPoint}>•</Text>
                    <Text style={styles.itemContent}>
                      {`is ${veteranOrSpouse ? '' : 'NOT'
                                        } a veteran or a deceased veteran's spouse`}
                    </Text>
                  </View>

                  <View style={styles.item}>
                    <Text style={styles.bulletPoint}>•</Text>
                    <Text style={[violationOfLaw ? badStyle : goodStyle, styles.itemContent]}>
                      {`has ${violationOfLaw ? '' : 'NOT'} been convicted of a violation of law other than a minor traffic violation before`}
                    </Text>
                  </View>

                  <View style={styles.item}>
                    <Text style={styles.bulletPoint}>•</Text>
                    <Text style={[revokedCertificate ? badStyle : goodStyle, styles.itemContent]}>
                      {`has ${revokedCertificate ? '' : 'NOT'} had a professional certificate revoked or suspended before`}
                    </Text>
                  </View>

                  <View style={styles.item}>
                    <Text style={styles.bulletPoint}>•</Text>
                    <Text style={[sexuallyAbusedChild ? badStyle : goodStyle, styles.itemContent]}>
                      {`has ${sexuallyAbusedChild ? '' : 'NOT'} been convicted of an offense for physical or sexual abuse of a child before`}
                    </Text>
                  </View>

                  <View style={styles.item}>
                    <Text style={styles.bulletPoint}>•</Text>
                    <Text style={[substantiatedChildAbuseCharge ? badStyle : goodStyle, styles.itemContent]}>
                      {`has ${substantiatedChildAbuseCharge ? '' : 'NOT'} had a charge of child abuse substantiated against before`}
                    </Text>
                  </View>

                  <View style={styles.item}>
                    <Text style={styles.bulletPoint}>•</Text>
                    <Text style={[hasBeenFired ? badStyle : goodStyle, styles.itemContent]}>
                      {`has ${hasBeenFired ? '' : 'NOT'} been involuntarily terminated or asked to resign, or 
                      resigned in lieu of termination from the employment of another school district before`}
                    </Text>
                  </View>

                </View>
              </View>
            </Page>
          </Document>
        );
    };

    App = () => (
      <div>
        <h3>Teacher Information </h3>
        <PDFViewer style={styles.iframe}>
          {this.MyDocument()}
        </PDFViewer>
      </div>
    );

    onGoBackClick = () => {
      this.props.history.goBack()
    };

    // send email to database
    // check if data is in database
    // wait
    // shut button down
    // if succcess then close modal and make successModal appear
    // if fail ...
    // Have to make sure recipient knows whom the email is from.
    // clear inputtext box after succesfully sending email
    // TODO: make sure change who emails are sent too
    onSendEmailClick = () => {
        const { email } = this.props.teacher;
        const employerEmail = this.returnEmployerEmail();
        const { body } = this.state;
        const { subject } = this.state;
        if (!this.state.body === '' && !this.state.subject === '') {
            sendDistrictEmail(email, subject, body, employerEmail);
            this.setState({ isModalOpen: false, body: '', subject: '' });
            this.openEmailSuccessModal();
        } else {
            this.openEmailErrorModal();
        }
    }

    onCancelEmailClick = () => {
        this.setState({ isModalOpen: false });
        this.setState({ body: '' });
        this.setState({ subject: '' });
    }

    formUpdateBody = (event) => {
        this.setState({ body: event.target.value });
    }

    formUpdateSubject = (event) => {
        this.setState({ subject: event.target.value });
    }

    // TODO: get employer email
    returnEmployerEmail = () => {
        if (this.props.employerData) {
            const { email } = this.props.employerData;
            return email;
        }
    };

    renderTitle = () => {
        const { firstName, lastName, email } = this.props.teacher;
        // const { title } = this.props.teacher;
        // const { proceedStatusSending } = this.props.appInfo;
        // const canProceed = proceedStatus === null || proceedStatus === 'red';
        // const canReject = proceedStatus === null || proceedStatus === 'green';

        return (
          <div style={styles.padLeftLightStyle}>
            <h2>
              <b>{firstName} {lastName}</b>
            </h2>
            <span>
              <CustomButton
                href={`mailto:`+email}
                target="_blank"
              >
                Send Email
              </CustomButton>
              {/* <CustomButton
                            onClick={this.onProceedClick}
                            isDisabled={!canProceed || proceedStatusSending}
                        >
                            {proceedStatusSending && canProceed
                                ? 'Updating...'
                                : 'Proceed with applicant'}
                        </CustomButton>
                        <CustomButton
                            onClick={this.onRejectClick}
                            isDisabled={!canReject || proceedStatusSending}
                            customStyle={{ width: 300 }}
                            variant="danger-contained"
                        >
                            {proceedStatusSending && canReject
                                ? 'Updating...'
                                : 'Do not proceed with applicant'}
                        </CustomButton> */}
              <CustomButton
                onClick={this.onGoBackClick}
              >
                Go Back
              </CustomButton>
            </span>
            <p>Below are documents for {firstName} {lastName}. You can download or print each document by clicking the respective download or print buttons in the upper right corner of each window.</p>
          </div>
        );
    };

    renderEssayPDF = (title, answer, prepend) => {
        const pretext = prepend === undefined ? '' : prepend;
        const answerText = !answer ? 'n/a' : answer;
        return (
          <View key={title}>
            <View>
              <Text style={styles.text}>{`${pretext} ${title}`}</Text>
            </View>
            {/* TODO: Well replacement */}
            <View>
              <Text style={styles.paragraph}>{answerText}</Text>
            </View>
          </View>
        );
    };

    renderLicensureItem = (licensure, index) => (
      <li
        key={index}
      >
        {licensure.label}
      </li>
    );

    renderDocumentIframe = (url, name) => (
      <div key={url}>
        <h3>{name}</h3>
        <Iframe
          url={url}
          width="90%"
          height="900px"
          id={url}
          display="initial"
          position="relative"
          allowFullScreen
        />
      </div>
    );

    renderDocs = () =>
        // const {
        //     resumeLink,
        //     transcriptLink,
        //     referenceLetterLinks,
        // } = this.props.appInfo;
         (
           <div style={styles.padLeftStyle}>
             {this.App()}
             {/* {this.renderDocumentIframe(resumeLink, 'Resume')}
                {this.renderDocumentIframe(transcriptLink, 'Transcript')}
                {referenceLetterLinks.map((refLink, index) =>
                    this.renderDocumentIframe(refLink, `Reference Letter ${index + 1}`) )} */}
           </div>
        )
    ;

    render() {
        const { teacher } = this.props;
        if (!teacher) {
            return <h2>Loading applicant information...</h2>;
        }
        return (
          <div>
            {this.renderTitle()}
            {this.renderDocs()}
          </div>
        );
    }
}

const mapStateToProps = state => ({
    employerData: state.employer.employerData,
    email: state.auth.email,
    teacher: state.teacher.teacher,
    user: state.auth.user,
    isLoggingIn: state.auth.isLoggingIn,
    userIsEmployer: state.auth.userIsEmployer,
});

export default withRouter(
    connect(mapStateToProps,
        {
            getTeacherInfo,
            getQuestions,
        }
    )(ApplicationView)
);
