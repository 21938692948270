import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function CustomSnackBar({ handleClose = () => {}, message = '', isOpen = false }) {
  return (
    <Snackbar
      anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      ContentProps={{
      'aria-describedby': 'message-id',
    }}
      message={<span id="message-id">{message}</span>}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
          size="large">
          <CloseIcon />
        </IconButton>,
    ]}
    />
  );
}

export default CustomSnackBar;
