import django from '../../apis/django';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

// this function does not need to be in this folder nor file
export const sendSupportEmail = async (sender_, subject_, body_) => {
    const contactFormSubmission = {
        sender: sender_,
        subject: subject_,
        body: body_,
    };
    const config = await getRequestConfig();
    django.post('/contact/', contactFormSubmission, config)
    .catch(error => {
        console.error('There was a problem trying to send an email to support.');
        console.error(error);
    });
};
