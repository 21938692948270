import React, { Component, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

export default function Graph(props) {
const [checkArray, setArray] = React.useState(false);
const [listArray, setListArray] = React.useState(false);
const [mastersNum, setMasters] = React.useState(0);
const [bachelorsNum, setBachelors] = React.useState(0);
const [doctoralNum, setDoctorates] = React.useState(0);
const [specialistsNum, setSpecialists] = React.useState(0);
const [noDegreeNum, setNoDegree] = React.useState(0);

    useEffect(() => {
        if (props.list.length > 0) {
            setArray(true);

        if (bachelorsNum === 0) {
            let mastersCount = 0;
            let bachelorsCount = 0;
            let doctoralCount = 0;
            let specialityCount = 0;
            let noneCount = 0;

            props.list.forEach((product) => {
        if (product.degreeLevel === 'master') {
            mastersCount += 1;
            setMasters(mastersCount);
        } else if (product.degreeLevel === 'bachelor') {
            bachelorsCount += 1;
            setBachelors(bachelorsCount);
        } else if (product.degreeLevel === 'doctoral') {
            doctoralCount += 1;
            setDoctorates(doctoralCount);
        } else if (product.degreeLevel === 'specialist') {
            specialityCount += 1;
            setSpecialists(specialityCount);
        } else {
            noneCount += 1;
            setNoDegree(noneCount);
        }
    });
}
        }
});

    const data = {
        labels: ['Bachelors', 'Masters', 'Doctoral', 'Specialist', 'None'],
        datasets: [
          {
            label: 'Degree vs Teachers',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: [bachelorsNum, mastersNum, doctoralNum, specialistsNum, noDegreeNum]
          }
        ]
      };
    return (
      <div>
        { checkArray ?
      (
        <div style={{ width: 400, minHeight: 500 }}>
          <h2>Teachers</h2>
          <Bar
            data={data}
            width={100}
            height={100}
            options={{
            maintainAspectRatio: false
          }}
          />
        </div>
) : (<div>... Loading</div>) }
      </div>
    );
}
