import * as types from '../actions/types';

const INITIAL_STATE = {
  city: '',
  contribution: '',
  degreeLevel: '',
  educationalPhilosophy: '',
  extracurricularActivities: '',
  hasBeenFired: false,
  hasTeachingLicense: false,
  institution: '',
  nameOfDegree: '',
  optionalDegreeHours: '',
  phoneNumber: '',
  revokedCertificate: false,
  sexuallyAbusedChild: false,
  state: '',
  streetAddress: '',
  substantiatedChildAbuseCharge: false,
  teachingLicenseType: '',
  veteranOrSpouse: false,
  violationOfLaw: false,
  yearsOfExperience: '',
  zipCode: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SETUP_TEACHER_SUBMIT:
      return action.payload;
    default:
      return state;
  }
};
