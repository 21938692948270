import React, { useState, useEffect } from 'react';
import CurrencyFormat from 'react-currency-format';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import TimeAgo from 'react-timeago';
import ApplyModal from '../ApplyModal/ApplyModal';
import CustomCard from '../CustomCard/CustomCard';
import { getSalary } from '../../state/actions';

function JobListCard({
     job,
     hasApplied,
     isApplying,
     appsOpen,
     resumes,
     transcripts,
     referenceLetters,
     milesAway,
     user,
     userData,
 }) {
    const [teacherSalary, setTeacherSalary] = useState(0);

    useEffect(() => {
        getSalary(job).then(salary => setTeacherSalary(salary));
    }, [userData]);

    const removedLinkText = job.description.replace(/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/gm, '*********');
    // console.log("the jobs real" + job.externalLink);

    const { district } = job;
    let stRatio = '';
    if (district && district.studentTeacherRatio) {
        stRatio = `Student-teacher ratio: ${
            district.studentTeacherRatio
            }:1`;
    }
    let coordinatorEmail = '';
    if (district && district.coordinatorEmail) {
      coordinatorEmail = district.coordinatorEmail
    }
    let date;

    if (job.isClosed && 'dateClosed' in job) {
      date = job.dateClosed;
    } else {
      try {
        date = new Date(job.datePosted);
      } catch (err) {
        date = null
      }
    }
    let hasFinishedSignUp = false;
    if (userData) {
      hasFinishedSignUp = userData.hasCompletedSignup;
    }

    let uploadedAllDocuments = false;
    if (Array.isArray(resumes) &&
        Array.isArray(transcripts) &&
        Array.isArray(referenceLetters) &&
        resumes.length > 0 &&
        transcripts.length > 0 &&
        referenceLetters.length > 0
    ) {
      uploadedAllDocuments = true;
    }

    // For debugging, to show licensures associated with the job in the JobCard
    // let licensure=""
    // job.selectedLicensure?.forEach(element => licensure = licensure + element.label);
    return (
      <CustomCard>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">{job.title}</Typography>
          <Typography color="textSecondary">
            {job.isClosed && date ? 'Closed' : date ? 'Posted' : ''} {date ? <TimeAgo date={date} /> : ''}
          </Typography>
        </div>
        <Typography color="textSecondary">{job.district.description}</Typography>
        { job.district.city && job.district.state ? <Typography color="textSecondary">{job.district.city}, {job.district.state}</Typography> : '' }
        <Typography
          color="textSecondary"
          style={{ display: milesAway ? 'block' : 'none' }}
        >
          {milesAway} miles away
        </Typography>
        <Typography component="p">{removedLinkText}</Typography>
        { user && hasFinishedSignUp && uploadedAllDocuments ?
          <CardActions>
            <ApplyModal
              resumes={resumes}
              transcripts={transcripts}
              referenceLetters={referenceLetters}
              hasApplied={hasApplied}
              isApplying={isApplying}
              appsOpen={appsOpen}
              job={job}
            />
          </CardActions>
          :
          ''
        }
        <Typography color="textSecondary">{stRatio}</Typography>
        <div style={{ display: user && hasFinishedSignUp ? 'block' : 'none' }}>
          <Typography color="textSecondary">
            Your Estimated Salary, based on experience and education:
            <span style={{ marginLeft: 5 }}>
              <CurrencyFormat
                color="textSecondary"
                value={teacherSalary}

                displayType="text"
                thousandSeparator
                prefix="$"
              />
            </span>
          </Typography>
          { coordinatorEmail != '' ? <Typography color="textSecondary">District Contact: {coordinatorEmail}</Typography> : '' }
        </div>
      </CustomCard>
    );
}

export default JobListCard;
