import * as types from '../actions/types';

const INITIAL_STATE = {
  begunApplication: [],
  selectedResumes: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.BEGIN_JOB_APPLICATION: {
      const begunCopy = state.begunApplication.slice();
      begunCopy.push(action.payload);
      return { ...state, begunApplication: begunCopy };
    }
    case types.END_JOB_APPLICATION: {
      const filteredBegun = state.begunApplication.filter(
        jobId => jobId !== action.payload
      );
      return { ...state, begunApplication: filteredBegun };
    }
    case types.SELECT_RESUME: {
      const newSelectedResumes = {
        ...state.selectedResumes,
        ...action.payload,
      };
      return { ...state, selectedResumes: newSelectedResumes };
    }
    default:
      return state;
  }
};
