const styles = {
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100%'

  },
  settingsHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 100,
    padding: 20,
  },
  fileUploadButtonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  settingsPageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '90%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  settingLayout: {
    display: 'flex',
    width: '100%',
    flex: 1,
    justifyContent: 'center',
    padding: 20,
    align: 'center',
    flexDirection: 'row',
  },
  leftMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '10%',
    alignItems: 'center',
  },
  loadingScreenLayout: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default styles;
