import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CSVReader } from 'react-papaparse';
import {
    changeTitle,
    changeLink,
    changeDescription,
    createNewJob,
    cancelNewJob,
    getDistrictData,
  } from '../../state/actions/newJobForm';

class UpCsv extends Component {
  handleOnDrop = (data) => {
    for (let i = 1; i <= data.length - 1; i++) {
        const lea = data[i].data[0];
        const title = data[i].data[1];
        const description = data[i].data[2];
        const selectedLicensure = data[i].data[3];
        const selectedGradeLevels = data[i].data[4];
        const externalLink = data[i].data[5];

        this.props.getDistrictData(
          lea,
          title,
          description,
          selectedLicensure,
          selectedGradeLevels,
          externalLink
        );
    }
  }

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  }

  handleOnRemoveFile = (data) => {
  }

  render() {
    return (
      <CSVReader
        onDrop={this.handleOnDrop}
        onError={this.handleOnError}
        addRemoveButton
        removeButtonColor="#659cef"
        onRemoveFile={this.handleOnRemoveFile}
      >
        <span>Drop CSV file here or click to upload.</span>
      </CSVReader>
    );
  }
}

const mapStateToProps = state => ({
  });

export default connect(
    mapStateToProps,
    {
      changeTitle,
      changeLink,
      changeDescription,
      createNewJob,
      cancelNewJob,
      getDistrictData,
    }
  )(UpCsv);
