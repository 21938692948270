import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './UserValidationSuccess.styles';
import PageLayout from '../PageLayout/PageLayout';

class UserValidationSuccess extends Component {
  render() {
    return (
      <PageLayout>
        <div style={styles.pageContainer}>
          <h1 style={{ display: 'inline-block' }}>Thank You</h1>
          <div style={{ textAlign: 'left' }}>
            <p>
              Thank you. You have successfully validated the employer account.
            </p>
          </div>
        </div>
      </PageLayout>
    );
  }
}

export default withRouter(UserValidationSuccess);
