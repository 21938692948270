import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './AboutUs.styles';
import PageLayout from '../PageLayout/PageLayout';

class AboutUs extends Component {
  render() {
    return (
      <PageLayout>
        <div style={styles.pageContainer}>
          <h1 style={{ display: 'inline-block' }}>About Us</h1>
          <div style={{ textAlign: 'left' }}>
            <p>
              ARteachers.org is a free resource designed to make it easier for teachers to find great jobs,
              and for school districts to find great teachers. The site was developed by the <a href="http://www.officeforeducationpolicy.org/arkansas-schools-data-recognition-data-and-letter-grades/">Office for Education Policy</a> at the University of Arkansas,
              Fayetteville, to remove barriers in the teacher pipeline.
            </p>

            <p>
              While researching Arkansas’ teacher shortage, we learned that many school districts were having
              difficulty in attracting qualified teachers. We also discovered that job postings could be difficult
              for applicants to locate, and that the application process could be complicated and time consuming.
              So we decided to try to make the process easier for teachers and districts.
            </p>

            <p>
              <li>ARteachers.org makes the job-application process easier for teachers by automatically
                identifying jobs that match his/her qualifications, and streamlining the application process with a common
                application format.
              </li> <br />

              <li> ARteachers.org provides a platform for districts to easily advertise their open positions,
                and facilitates an easy applicant review process.
              </li> <br />

              <li>In addition to connecting teachers and districts, the site will generate information about
                how many teaching positions need to be filled and the types of applicants that districts are looking for.
                This information will allow Arkansas’ policy
                makers and teacher-preparation programs to better understand the need for educators around the state.
              </li> <br />
            </p>

            <p>
              ARteachers.org was designed specifically for Arkansas to meet the needs of Arkansas teachers and school districts.
              We know that getting a great teacher in every classroom is a critical step in ensuring a great
              education for Arkansas students, and ARteachers.org makes it easy and free.
            </p>
          </div>
        </div>
      </PageLayout>
    );
  }
}

export default withRouter(AboutUs);
