import django from '../../apis/django';
import { auth } from '../../firebase/Firebase';
// eslint-disable-next-line
import * as types from './types';
import { getEmployer } from './employers';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';


const addQuestionSuccess = (dispatch, response) => {
  // AB: It would be nice if the backend could return the new object in the format
  // the frontend is expecting, but I can't get that to work.
  const question = {
    "label": response.data.text,
    "value": response.data.id
  }
  dispatch({ type: types.ADD_EMPLOYER_QUESTION, payload: question })
  dispatch({ type: types.ADD_EMPLOYER_QUESTION_SUCCESS_SHOW_ALERT });
  dispatch({ type: types.ADD_NEW_EMPLOYER_QUESTION_SUCCESS });
};

const addQuestionToPoolFromEmployer = async (
  dispatch,
  userId,
  questionText
) => {
  const config = await getRequestConfig();
  // Look up employer ID by the provided userId
  getEmployer(userId)
    .then(({ id }) => {
      const question = {
        text: questionText,
        employer: id
      };
      django.post('/essayquestions/', question, config)
        .then(response => addQuestionSuccess(dispatch, response))
        .catch(error => console.log(error));

      // TODO: Add question to local state now
    });
};

export const addQuestionToPool = questionText => dispatch => {
  // get the employer doc where userId == userId
  //  then add a doc to the questions collection with field text equal to questionText
  dispatch({ type: types.ADDING_NEW_EMPLOYER_QUESTION });

  const userId = auth.currentUser.uid;
  addQuestionToPoolFromEmployer(dispatch, userId, questionText);
};

// const deleteQuestionsFromEmployer = (
//   dispatch,
//   querySnapshot,
//   selectedQuestions
// ) => {
//   console.log('Got the employer.');
//   if (querySnapshot.size !== 1) {
//     console.log('Error, found too many users with the same userId');
//   }

//   querySnapshot.forEach(doc => {
//     const employerId = doc.id;

//     // Loop through selectedQuestion and delete each doc with ID == object.value
//     // TODO: consider just setting an isDeleted flag to true in the future
//     selectedQuestions.forEach(question => {
//       firestore
//         .collection('employers')
//         .doc(employerId)
//         .collection('questions')
//         .doc(question.value)
//         .delete()
//         .catch(error => console.log(error));
//     });
//   });
//   dispatch({ type: types.DELETE_QUESTIONS_SUCCESS });
//   dispatch({ type: types.DELETE_QUESTIONS_SUCCESS_SHOW_ALERT });
//   // TODO: We want to remove these questions from local state now.
// };

const removeQuestionFromState = (dispatch, questionId) => {
  dispatch({ type: types.REMOVE_EMPLOYER_QUESTION, payload: questionId })
}

export const removeQuestionsFromPool = selectedQuestions => async dispatch => {
  // selectedQuestions is an array of objects, where object.value is the id of the doc in
  // employers/employerId/questions that needs to be removed
  dispatch({ type: types.DELETING_QUESTIONS });

  // TODO: There could be some checking here to make sure the user isn't
  // deleting somebody else's questions. But that really needs to be done
  // from the API (django).
  const userId = auth.currentUser.uid;
  const config = await getRequestConfig;
  getEmployer(userId)
    .then(({ id }) => {
      // delete each question in selectedQuestions
      selectedQuestions.forEach(question => {
        django.delete(`/essayquestions/${question.value}/`, config)
        .then(removeQuestionFromState(dispatch, question.value))
        .catch(error => console.log(error));
      });
    });

  dispatch({ type: types.DELETE_QUESTIONS_SUCCESS });
  dispatch({ type: types.DELETE_QUESTIONS_SUCCESS_SHOW_ALERT });

  // firestore
  //   .collection('employers')
  //   .doc(userId)
  //   .collection('questions')
  //   .
  //   .then(querySnapshot => deleteQuestionsFromEmployer(dispatch, querySnapshot, selectedQuestions)
  //   )
  //   .catch(error => console.log(error));
};
