// Use Firebase SDK to attach token to each request to the
// Django backend. Switching to this approach since the 
// Axios interceptor cannot access state to get the current
// accessToken. Using the Firebase SDK and the getIdToken() 
// method also automatically handles token refresh.

import { auth } from '../firebase/Firebase'

export const getRequestConfig = async () => {
    const user = auth.currentUser;
    const firebaseToken = await user.getIdToken();
    
    const config = {
        headers: {
            'Authorization': `Bearer ${firebaseToken}`,
            'Content-Type': 'application/json',
        }
    }

    return config
}