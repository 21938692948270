import django from '../../apis/django';
import store from '..';
import * as types from './types';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

export const getEmployer = async uid => {
    try {
      const config = await getRequestConfig();
      const response = await django.get(`/employers/${uid}/`, config);
      return response.data;
    } catch (error) {
      // Don't log an error if we got a 404 back - that's a normal result
      if (error.response && error.response.status == "404") {
        return null
      }
      console.error('There was a problem looking up an employer by the provided UID');
      console.log(error.message);
      return null;
    }
};

export const getEmployers = () => async () => {
    let employers = [];
    try {
      const config = await getRequestConfig();
      const response = await django.get('/employers/', config);
      employers = response.data;
      store.dispatch({ type: types.GET_EMPLOYERS_LIST_SUCCESS, payload: employers });
    } catch (err) {
      console.error('There was a problem getting the employers data');
    }

    return employers;
};
