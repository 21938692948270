import * as types from '../actions/types';

const INITIAL_STATE = {
  signingUp: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CREATE_TEACHER:
      return { ...state, signingUp: true };
    default:
      return state;
  }
};
