import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Fuse from 'fuse.js';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import { getTeachers } from '../../state/actions/getTeachers';
import TeacherListItem from '../../components/TeacherListItem/TeacherListItem';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomCard from '../../components/CustomCard/CustomCard';
import PageLayout from '../PageLayout/PageLayout';
import Theme from '../../theme/Theme';
import SkeletonLoadingCard from '../../components/SkeletonLoadingCard/SkeletonLoadingCard';
import LandingComponent from '../../components/LandingComponent/LandingComponent';
import { logoutUser } from '../../state/actions/auth';

// TODO: Will be working closely with DistrictPosting.js and Posting.js and ApplicantListItem

class TeacherList extends Component {
    state = {
        filteredTeachers: null,
        stringSearch: '',
        // milesSearch: '',
        searchResults: [],
        hasDoneFirstSearch: false, // show all jobs until we search
        teacherDistances: null, // jobId -> distance in miles
        checked: false,
        expanded: false,
    };

    componentDidMount() {
      const { user, history, userIsEmployer, isLoggingIn } = this.props;
      if ((user == null && isLoggingIn == false) || userIsEmployer == false) {
        history.push('/');
      // To get here, userIsEmployer has to be not false, but it could be null
      } else if (user !== null && isLoggingIn == false && userIsEmployer == true) {
        this.props.getTeachers();
      }
    }

    componentDidUpdate() {
      const { user, history, userIsEmployer, isLoggingIn, gettingTeachers } = this.props;
      if ((user == null && isLoggingIn == false) || userIsEmployer == false) {
        history.push('/');
      } else if (user !== null && isLoggingIn == false && userIsEmployer == true) {
        if (gettingTeachers == null) {
          this.props.getTeachers();
        }
      }
    }

    onSearchTextChange = e => {
        this.setState({ stringSearch: e.target.value });
        this.updateSearchResults(e.target.value);
    };

    // TODO: need to send retrieve teacher object properly in order to do search
    updateSearchResults = searchString => {
        this.setState({ hasDoneFirstSearch: true });
        // const { filteredTeachers } = this.state;

        // If no inputs are good, searchResults contains all applicants.
        // TODO: Gotta make this work more efficiently
        // TODO: AB: I agree, there is no point in calling this at every keystroke!
        let currentResults = this.props.teachers.map(teacher => this.editTeachers(teacher));

        // TODO: description matching is wonky, can we fix this?
        if (searchString !== '') {
            const options = {
                shouldSort: true,
                threshold: 0.1,
                location: 0,
                distance: 1000,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: ['firstName', 'lastName', 'Licensures', 'degreeLevel'],
            };
            const fuse = new Fuse(currentResults, options);
            currentResults = fuse.search(searchString);
        }
        // update the final search results
        this.setState({ searchResults: currentResults });
    };

    editTeachers = (teacher) => {
      let i;
      let str = '';
      const person = teacher;
      if (teacher !== undefined) {
        if (teacher) {
          if (teacher.selectedLicensure !== undefined && teacher.selectedLicensure !== '') {
            var licensures = []
            try {
              licensures = JSON.parse(teacher.selectedLicensure)
            } catch (err) {
              console.log(`Warning: Licensures for teacher are not valid JSON`);
            }
            for (i = 0; i < licensures.length; i += 1) {
                if (i === 0 && licensures.length === 1) {
                    str += `${licensures[i].label}`;
                } else if (i === (licensures.length - 1)) {
                    str += `${licensures[i].label}`;
                } else {
                    str += `${licensures[i].label},`;
                }
            }
          }
          person.Licensures = str;
          return person;
        }
      }
    }

    renderTeacherResults = () => {
        const { teachers } = this.props;
        if (!this.state.hasDoneFirstSearch) {
            if (teachers) {
                // if (this.state.filteredTeachers.length !== 0) {
                //     return teachers.map(this.renderTeacher);
                // }
                return teachers.map(this.renderTeacher);
            }
        }
        if (this.state.searchResults.length === 0) {
            return <div>No search results found!</div>;
        }
        return this.state.searchResults.map(teacher => this.renderTeacher(teacher));
    };

    renderTeacher = teacher => {
        const { firstName, lastName, id, selectedLicensure } = teacher; // find how implement , interestStatus
        const nameString = `${firstName} ${lastName}`;
        const Degree = `Degree Level: ${teacher.degreeLevel}`;
        const YearsOfExp = `Years of Experience: ${teacher.yearsOfExperience}`;
        const teacherId = id;
        const linkString = `/teacher/${teacherId}`; // will have change teacher.applicationId
        const lic = this.getLicensure(selectedLicensure);
        const Licensures = `Licensure Areas: ${lic}`;
        // Don't try to render a TeacherListItem if we have no good value for
        // its key.
        if (teacherId) {
          return (
            <TeacherListItem
              key={teacherId}
              city={teacher.city}
              state={teacher.state}
              nameString={nameString}
              linkTo={linkString}
              interestStatus="green"
              Licensure={Licensures}
              Degree={Degree}
              YearsOfExp={YearsOfExp}
            />
          );
        } else {
          return null
        }
    }

    getLicensure = (Licensure) => {
        let str = '';
        let i;
        var parsedLicensure;
        // console.log("the licensure" + Licensure.length);
        if (Licensure !== undefined) { // check to see if licensure is defined
          if (Licensure.length > 0) {
              parsedLicensure = JSON.parse(Licensure);
              for (i = 0; i < parsedLicensure.length; i += 1) {
                  if (i === 0 && parsedLicensure.length === 1) {
                      str += `${parsedLicensure[i].label}`;
                  } else if (i === (parsedLicensure.length - 1)) {
                      str += `${parsedLicensure[i].label}`;
                  } else {
                      str += `${parsedLicensure[i].label}, `;
                  }
              }
          }
        }
        if (str == '') {
          str = 'None Provided by Teacher';
        }
        return str;
    }

    renderSearch = () => (
      <div>
        <form style={{ display: 'flex', flexWrap: 'wrap' }}>
          <TextField
            id="standard-name"
            label="Search"
            margin="normal"
            helperText="Search for teachers by name, degree level or individual licensure."
            value={this.state.stringSearch}
            onChange={this.onSearchTextChange}
            style={{ marginLeft: 10, marginRight: 10 }}
            onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                            ev.preventDefault();
                        }
                    }}
          />
        </form>
      </div>
    );

    renderPostHeader = () => (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {this.renderTitle()}
      </div>
    );

    renderTitle = () => {
        const title = 'Search Pool of Teachers';
        return (
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
          >
            {title}
          </Typography>
        );
    };

    renderDescription = () => {
        // const { isEditing } = this.state; // won't be allowed to edit
        const description = 'You can search a pool of teachers that currently allow their profile information to be viewed by employers.';
        return (
          <Typography>
            {description}
          </Typography>
        );
    };

    renderWelcome = () => {
        const name = this.props.employerData
            ? this.props.employerData.name
            : 'teachers';
        return (
          <div>
            <h2 style={{ fontWeight: 600, display: 'inline' }}>
              A FREE resource designed to help{' '}
              <span
                style={{
                            color: Theme.ThemePrimary,
                            fontWeight: 600,
                            display: 'inline',
                        }}
              >
                {name}
              </span>{' '}
              find great teachers.
            </h2>
          </div>
        );
    };

    renderCards = () => {
        const teachers = [1, 2]; // using this because of main error
        const { expanded } = this.state;
        let length;
        let canExpand;
        if (this.props.teachers) {
            // console.log("the props is true");
            length = this.props.teachers.length;
            canExpand = length > 0;
        }
        let btnText = canExpand
            ? `View applicants (${length})`
            : 'No applicants';
        if (expanded) {
            btnText = 'Hide applicants';
        }
        const btnColor = canExpand ? 'primary' : 'secondary';

        if (teachers === null) {
            return (
              <div>
                <SkeletonLoadingCard />
              </div>
            );
        }
        return (
          <div>
            <CustomCard customStyle={{ width: 735 }}>
              {this.renderPostHeader()}
              {this.renderDescription()}
              <CardActions>
                <CustomButton
                  onClick={() => this.setState({ expanded: !expanded })}
                  isDisabled={!canExpand}
                  variant={btnColor}
                >
                  {btnText}
                </CustomButton>
              </CardActions>
              <Collapse in={this.state.expanded}>
                {this.renderSearch()}
                <CardContent>
                  <List>{this.renderTeacherResults()}</List>
                </CardContent>
              </Collapse>
            </CustomCard>
          </div>
        );
    };

    // TODO: make sure districts that finsihed signup can only acces this page
    render() {
        return (
          <PageLayout>
            <LandingComponent welcomeMessage={this.renderWelcome()} />
            {this.renderCards()}
          </PageLayout>

        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    isLoggingIn: state.auth.isLoggingIn,
    userIsEmployer: state.auth.userIsEmployer,
    postings: state.employer.postings,
    applicants: state.employer.applicants,
    resumeLinks: state.employer.resumeLinks,
    hasStartedNewPosting: state.newJobForm.hasStartedNewPosting,
    employerData: state.employer.employerData,
    externalEmployerData: state.employer.externalEmployerData,
    userData: state.auth.userData,
    teachers: state.teachers.teachers,
    gettingTeachers: state.teachers.gettingTeachers,
});

export default withRouter(
    connect(
        mapStateToProps,
        {
            getTeachers,
            logoutUser,
        }
    )(TeacherList)
);
