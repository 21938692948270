import React from 'react';
import styles from './LandingComponent.styles';

function LandingComponent({ welcomeMessage }) {
  return (
    <div style={styles.landingPageContainer}>
      {welcomeMessage}
    </div>
);
}

export default LandingComponent;
