import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import { FormCheck } from 'react-bootstrap';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomModal from '../../components/CustomModal/CustomModal';
import TeacherUploads from '../../components/TeacherUploads/TeacherUploads';
import { setupTeacher } from '../../state/actions/newTeacherSetupForm';
import { logoutUser } from '../../state/actions/auth';
import { renameDocument } from '../../state/actions/teacherSettings';
import { initialValues, schema } from '../TeacherSignUpPage/TeacherSignUpPageFormikValues';
import PageLayout from '../PageLayout/PageLayout';
import TermsAndConditions from '../../components/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy';
import TeacherInformationForm from '../../components/TeacherInformationForm/TeacherInformationForm';

class NewTeacherSetup extends Component {
  state = {
    isModalOpen: false,
    modalContent: '',
    documentNames: {},
  };

  componentDidUpdate() {
    const { teacherSetupStatus } = this.props;
    if ((teacherSetupStatus && teacherSetupStatus.success === true) || (!this.props.user && this.props.isLoggingIn == false)) {
      this.props.history.push('/');
    }
  }

  handleSubmit = (teacher) => {
    this.props.setupTeacher(
      teacher
    );
  };

  renderPrivacyPolicy = () => (
    <label
      style={{ cursor: 'pointer', fontWeight: 'bold', color: 'rgb(0, 122, 255)' }}
      onClick={() => this.setState({ isModalOpen: true, modalContent: 'privacyPolicy' })}
    >
      Privacy Policy
    </label>
  );

  renderTerms = () => (
    <label
      style={{ cursor: 'pointer', fontWeight: 'bold', color: 'rgb(0, 122, 255)' }}
      onClick={() => this.setState({ isModalOpen: true, modalContent: 'terms' })}
    >
      Terms and Conditions
    </label>
  );

  renderModalContent = () => {
    const { modalContent } = this.state;
    if (modalContent === 'privacyPolicy') {
      return <PrivacyPolicy />;
    }
    return <TermsAndConditions />;
  };

  renderDocumentNames = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 10
      }}
    >
      <label style={{ fontWeight: 'bold' }}>Please upload any applicable PDF files</label>
      {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        {Object.values(this.state.documentNames).map(name => (
          <div key={name}>
            <label style={{ fontWeight: 'bold', marginRight: 5, color: 'rgb(52, 199, 89)' }}>{name}</label>
            <label>has been successfully uploaded</label>
          </div>
        ))}
      </div> */}
    </div>
  );

  renderCheckBox = (props, name, label, useIsValid=true) => (
    <div style={{ marginBottom: 20, width: 500 }}>
      <FormCheck>
        <FormCheck.Input
          name={name}
          {...props.getFieldProps(name)}
          isInvalid={!!props.errors[name] && props.touched[name]}
          isValid={useIsValid && !props.errors[name] && props.touched[name]}
          value={props.values[name]}
          defaultChecked={props.values[name]}
        />
        <FormCheck.Label>{label}</FormCheck.Label>
      </FormCheck>
    </div>
  );

  render() {
    const { userData } = this.props;
    return (
      <PageLayout>
        <Formik
          onSubmit={this.handleSubmit}
          validationSchema={schema}
          initialValues={{...initialValues, ...userData}}
        >
          {props => (
            <form onSubmit={props.handleSubmit}>
              <CustomModal
                isOpen={this.state.isModalOpen}
                onClose={() => this.setState({
                  isModalOpen: false,
                  modalContent: '',
                })}
                modalContent={this.renderModalContent()}
              />
              <TeacherInformationForm props={props} />
              {this.renderCheckBox(props, 'hasReadPolicy', 'Yes!  I have read the privacy policy.')}
              {this.renderCheckBox(props, 'hasAgreedToTerms', 'Yes!  I agree to the terms and conditions.')}
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 25, marginBottom: 10 }}>
                <label style={{ fontWeight: 'bold' }}>ARteachers.org wants to match great teachers with great schools.</label>
                <label style={{ fontWeight: 'bold' }}>Check the boxes below to let schools find you.</label>
              </div>
              {this.renderCheckBox(props, 'shouldShareData', 'Yes! Please share my information with other districts looking for great teachers like me.')}
              {this.renderCheckBox(props, 'longTermSubstitute', 'Yes!  I am interested in long-term substitute opportunities.')}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
              </div>
              {this.renderDocumentNames()}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <TeacherUploads/>
                <CustomButton
                  type="submit"
                >
                  Submit
                </CustomButton>
                {this.renderPrivacyPolicy()}
                {this.renderTerms()}
              </div>
            </form>
          )}
        </Formik>
      </PageLayout>
    );
  }
}

const mapStateToProps = state => ({
  userData: state.auth.userData,
  user: state.auth.user,
  isLoggingIn: state.auth.isLoggingIn,
  teacherSettings: state.teacherSettings,
  teacherSetupStatus: state.teacherSetupStatus,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      logoutUser,
      renameDocument,
      setupTeacher,
    }
  )(NewTeacherSetup)
);
