import { storage } from '../../firebase/Firebase';
import * as types from './types';
import django from '../../apis/django';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

export const getResumeUrlSuccess = (dispatch, url) => {
  dispatch({
    type: types.GET_APPLICATION_INFO_DOCUMENT_LINKS_SUCCESS,
    payload: url,
  });
};

const getTranscriptUrlSuccess = (dispatch, url) => {
  dispatch({
    type: types.GET_APPLICATION_INFO_TRANSCRIPT_LINK_SUCCESS,
    payload: url,
  });
};

const getResumeUrlFail = error => console.log(error);

const getReferenceUrlSuccess = (dispatch, url) => {
  dispatch({
    type: types.GET_APPLICATION_INFO_REFERENCE_LINK_SUCCESS,
    payload: url,
  });
};

export const getApplicationInfo = (jobId, applicationId) => async dispatch => {
  // Blank out any previous state for application info
  dispatch({ type: types.RESET_APPLICATION_INFO })
  try {
    const config = await getRequestConfig();
    const applicationResponse = await django.get(`/jobs/${jobId}/applications/${applicationId}/`, config)
    // AB 10/28/22 Would prefer not to use [0] but can't remove many=True from the view
    // named JobApplicationByJobIdViewSet
    const attachedApplicationInfo = applicationResponse.data[0];
    dispatch({
      type: types.GET_APPLICATION_ATTACHED_INFO_SUCCESS,
      payload: attachedApplicationInfo
    });
    const application = attachedApplicationInfo
    const teacherFirebaseAuthId = application.applicant.firebaseAuthId;
    const employerId = application.job.owner.id;
    const employerSnapshot = await django.get(`/employers/id/${employerId}/`, config);
    const employerData = employerSnapshot.data;
    // AB 9/28/22: TODO - We should really have ONE place where we bring back teacher data
    // Or it should returned by the backend in a form that can be used without needing
    // manipulation.
    if (employerData?.selectedLicensure) {
      employerData.selectedLicensure = JSON.parse(employerData.selectedLicensure)
    }

    dispatch({ type: types.GET_APPLICATION_INFO_USER_DATA_SUCCESS, payload: employerData });
    dispatch({ type: types.GET_APPLICATION_INFO_JOB_INFO_SUCCESS, payload: application.job });
    if (application.resumeId && 'docId' in application.resumeId) {
      storage
        .ref(`resumes/${teacherFirebaseAuthId}/${application.resumeId.docId}.pdf`)
        .getDownloadURL()
        .then(url => getResumeUrlSuccess(dispatch, url))
        .catch(error => getResumeUrlFail(error));
    }
    // get the transcript too
    if (application.transcriptId && 'docId' in application.transcriptId) {
    storage
      .ref(`transcripts/${teacherFirebaseAuthId}/${application.transcriptId.docId}.pdf`)
      .getDownloadURL()
      .then(url => getTranscriptUrlSuccess(dispatch, url))
      .catch(error => console.log(error));
    }
    // Get each one of the reference letters
    // TODO: Find some way to reduce the number of lookups here
    if (application.letterIds && application.letterIds.length !== 0) {
        // Clear out any reference URLs currently in state
        dispatch({type: types.SET_APPLICATION_INFO_CLEAR_REFERENCE_LINKS});
        application.letterIds.forEach(refLetter => {
          storage
            .ref(`references/${teacherFirebaseAuthId}/${refLetter.docId}.pdf`)
            .getDownloadURL()
            .then(url => getReferenceUrlSuccess(dispatch, url))
            .catch(error => console.log(error));
      });
    }
    dispatch({
      type: types.GET_APPLICATION_INFO_COMPLETE,
    });
  } catch (err) {
    console.error(err);
    console.log('There was a problem getting the application info.');
    dispatch({
      type: types.GET_APPLICATION_INFO_FAILED,
    });
  }
};

const setProceedStatusSuccess = (dispatch, proceedStatus) => {
  dispatch({
    type: types.SET_APPLICANT_PROCEED_STATUS_SUCCESS,
    payload: proceedStatus,
  });
};

export const setApplicantProceedStatus = (
  jobId,
  applicationId,
  proceedStatus
) => async dispatch => {
  const applicationUpdate = {
    proceedStatus
  };
  dispatch({ type: types.SET_APPLICANT_PROCEED_STATUS });
  const config = await getRequestConfig();
  django.patch(`/jobs/${jobId}/applications/${applicationId}/`, applicationUpdate, config)
    .then(() => setProceedStatusSuccess(dispatch, proceedStatus))
    .catch(error => console.log(error));

  // make sure to set the local proceedStatus at the end of this (via dispatch to reducer)
};
