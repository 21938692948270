const Theme = {
  ThemePrimary: '#a21d22',
  ThemePrimaryHovered: '#a21d22',
  ThemeDanger: '#c62828',
  ThemeDangerHovered: '#e53935',
  ThemeSuccess: '#4caf50',
  ThemeSuccessHovered: '#81c784',
  ThemeDisabled: '#cfd8dc',
  ThemeDisabledHovered: '#eceff1',
  ThemeFooter: '#ce484c',
};

export default Theme;
