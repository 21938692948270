import django from '../../apis/django';
import * as types from './types';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

export const updateTeacherInfo = (id, firstName, lastName) => async dispatch => {
  const teacherUpdate = {
    firstName,
    lastName
  };
  try {
    const config = await getRequestConfig();
    await django.patch(`/teachers/id/${id}/`, teacherUpdate, config);
    dispatch({ type: types.ADMIN_UPDATE_TEACHER });
  } catch (error) {
    console.dir(error, {depth:null});
    console.error('There was a problem updating the teacher info.');
  }
};
