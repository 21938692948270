import React from 'react';
import { makeStyles } from '@mui/styles';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    CardHeader
} from '@mui/material/';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(5)
    }
}));

export default function DashCard(props) {
    const classes = useStyles();
    const data = [
        { number: props.teachers, who: 'Teachers' },
        { number: props.jobs, who: 'Jobs' },
        { number: props.employers, who: 'Employers' },
    ];
    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={10}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {data.map(elem => (
            <Grid item xs={12} sm={6} md={3} key={data.indexOf(elem)} style={{ marginRight: 50 }}>
              <Card style={{ minWidth: 250 }}>
                <CardHeader
                  title={`${ elem.who} : `}
                //   subheader={'Number of ' + elem.who + ':' +  ${elem.earnings}`}
                />
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {elem.number}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
                ))}
        </Grid>
      </div>
    );
}
