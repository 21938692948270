// import { applyMiddleware } from 'redux';
// import { legacy_createStore as createStore} from 'redux'
// import ReduxThunk from 'redux-thunk';
// import reducers from './reducers';

// /* eslint-disable no-underscore-dangle */
// const store = createStore(
//     reducers,
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
//     applyMiddleware(ReduxThunk));
// /* eslint-enable */

// export default store;

import { configureStore } from '@reduxjs/toolkit';
import ReduxThunk from 'redux-thunk';
import rootReducer from './reducers';

const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        // Per https://stackoverflow.com/a/72026535/5605817,
        // added these two lines to see if it helps with long
        // delays when starting the development server.
        // Could be from having large data sets in state.
        serializableCheck: false,
        immutableCheck: false,
      }),
});

export default store;
