import React, { Component } from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import PageLayout from '../PageLayout/PageLayout';
import CustomButton from '../../components/CustomButton/CustomButton';

class SignUpSelect extends Component {
  onTeacherClick = () => {
    this.props.history.push('/signup/teacher');
  };

  onDistrictClick = () => {
    this.props.history.push('/signup/district/');
  };

  render() {
    return (
      <PageLayout>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
          <ButtonToolbar>
            <CustomButton onClick={this.onTeacherClick}>Teacher</CustomButton>
            <CustomButton onClick={this.onDistrictClick}>District</CustomButton>
          </ButtonToolbar>
        </div>
      </PageLayout>
    );
  }
}

export default withRouter(SignUpSelect);
