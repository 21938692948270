import * as yup from 'yup';

export const schema = yup.object().shape({
  streetAddress: yup
    .string()
    .required(),
  city: yup
    .string()
    .required(),
  state: yup
    .string()
    .required(),
  zipCode: yup
    .number()
    .required(),
  phoneNumber: yup
    .number()
    .required(),
  yearsOfExperience: yup
    .number()
    .required(),
  hasTeachingLicense: yup
    .boolean()
    .required(),
  institution: yup
    .string()
    .required(),
  // AB 8/6/22: This can't be required, it's only visible if institution is Other
  // institutionOther: yup
  //   .string()
  //   .required(),
  legallyAbleToWork: yup
    .string()
    .required(),
  veteranOrSpouse: yup
    .string()
    .required(),
  violationOfLaw: yup
    .boolean()
    .required(),
  revokedCertificate: yup
    .boolean()
    .required(),
  sexuallyAbusedChild: yup
    .boolean()
    .required(),
  substantiatedChildAbuseCharge: yup
    .boolean()
    .required(),
  hasBeenFired: yup
    .boolean()
    .required(),
  degreeLevel: yup
    .string()
    .required(),
  optionalDegreeHours: yup
    .number()
    .required(),
  // AB 8/6/22: Copy/paste error from NewTeacherSetup, does not apply here
  // hasReadPolicy: yup
  //   .string()
  //   .required(),
  // hasAgreedToTerms: yup
  //   .string()
  //   .required(),
  teachingLicenseType: yup
    .array()
    .when("hasTeachingLicense", {
      is: true,
      then: yup.array().min(1).required()
    }),
  hasArTeachingLicense: yup
    .boolean().nullable()
    .when("hasTeachingLicense", {
      is: true,
      then: yup.boolean().required()
    }),
  // https://codeboosh.com/how-to-do-conditional-validation-with-formik-and-yup/
  licensureAndState: yup
    .string().nullable()
    .when(['hasTeachingLicense', 'hasArTeachingLicense'], {
      is: (hasTeachingLicense, hasArTeachingLicense) => hasTeachingLicense && !hasArTeachingLicense,
      then: yup.string().required()
    }),
  teachingLicenseType: yup
    .array()
    .when("hasTeachingLicense", {
      is: true,
      then: yup.array().min(1).required()
    }),
  selectedLicensure: yup
    .array()
    .when("hasTeachingLicense", {
      is: true,
      then: yup.array().min(1).required()
    }),
  towardsTeachingLicense: yup
    .boolean().nullable()
    .when("hasTeachingLicense", {
      is: false,
      then: yup.boolean().required()
    }),
  // In most cases (unless the user loads the page at 00:00), the minimum date
  // here will be tomorrow. For example, 08/31/2022 (implied: 00:00:00) could be less
  // than "now", e.g., 08/31/2022 15:12:05.
  // This doesn't seem like it's worth it to worry about.
  expectedLicenseDate: yup
    .date()
    .min(new Date()).nullable()
    .when(['towardsTeachingLicense', 'hasTeachingLicense'], {
      is: (towardsTeachingLicense, hasTeachingLicense) => towardsTeachingLicense && !hasTeachingLicense,
      then: yup.date().required()
    }),
  willToWorkTowardsLicense: yup
    .boolean().nullable()
    .when(['towardsTeachingLicense', 'hasTeachingLicense'], {
      is: (towardsTeachingLicense, hasTeachingLicense) => !towardsTeachingLicense && !hasTeachingLicense,
      then: yup.boolean().required()
    }),
  teachingLicenseWorkingTowards: yup
    .array().nullable()
    .when(['towardsTeachingLicense', 'hasTeachingLicense'], {
      is: (towardsTeachingLicense, hasTeachingLicense) => towardsTeachingLicense && !hasTeachingLicense,
      then: yup.array().min(1).required()
    }),
  violationOfLawExplain: yup
    .string().nullable()
    .when("violationOfLaw", {
      is: true,
      then: yup.string().required()
    }),
  revokedCertificateExplain: yup
    .string().nullable()
    .when("revokedCertificate", {
      is: true,
      then: yup.string().required()
    }),
  sexuallyAbusedChildExplain: yup
    .string().nullable()
    .when("sexuallyAbusedChild", {
      is: true,
      then: yup.string().required()
    }),
  substantiatedChildAbuseChargeExplain: yup
    .string().nullable()
    .when("substantiatedChildAbuseCharge", {
      is: true,
      then: yup.string().required()
    }),
  hasBeenFiredExplain: yup
    .string().nullable()
    .when("hasBeenFired", {
      is: true,
      then: yup.string().required()
    }),
  hasReadPolicy: yup
      .boolean().oneOf([true]),
  hasAgreedToTerms: yup
      .boolean().oneOf([true]),
});

export const initialValues = {
  streetAddress: '',
  apartmentUnitNumber: '',
  city: '',
  state: 'AR',
  zipCode: '',
  phoneNumber: '',
  hasTeachingLicense: false,
  hasArTeachingLicense: false,
  towardsTeachingLicense: false,
  willToWorkTowardsLicense: false,
  licensureAndState: '',
  selectedLicensure: [],
  expectedLicenseDate: '',
  teachingLicenseType: '',
  teachingLicenseWorkingTowards: [],
  degreeLevel: '',
  institution: '',
  institutionOther: '',
  optionalDegreeHours: '0',
  yearsOfExperience: '0',
  extracurricularActivities: '',
  educationalPhilosophy: '',
  contribution: '',
  designations: '',
  microCredentials: '',
  legallyAbleToWork: true,
  veteranOrSpouse: false,
  violationOfLaw: false,
  violationOfLawExplain: '',
  revokedCertificate: false,
  revokedCertificateExplain: '',
  sexuallyAbusedChild: false,
  sexuallyAbusedChildExplain: '',
  substantiatedChildAbuseCharge: false,
  substantiatedChildAbuseChargeExplain: '',
  hasBeenFired: false,
  hasBeenFiredExplain: '',
  shouldShareData: false,
  longTermSubstitute: false,
  hasReadPolicy: false,
  hasAgreedToTerms: false,
};
