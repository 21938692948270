import * as types from '../actions/types';

const INITIAL_STATE = {
  folderForRename: null,
  docIdForRename: null,
  isRenaming: false,
  uploadDone: false,
  renameDone: false,
  renameFailed: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.TRACK_NEW_DOC_RESET:
      return { ...INITIAL_STATE };
    case types.TRACK_NEW_DOC_SETUP_RENAME:
      return {
        ...state,
        folderForRename: action.payload.folder,
        docIdForRename: action.payload.docId,
        uploadDone: true,
        renameDone: false,
      };
    case types.START_DOC_RENAME:
      return { ...state, isRenaming: true, renameDone: false, renameFailed: false };
    case types.DOC_RENAME_SUCCESS:
      return { ...state, isRenaming: false, renameDone: true, renameFailed: false, uploadDone: false };
    case types.DOC_RENAME_FAILURE:
      return { ...state, isRenaming: false, renameDone: false, renameFailed: true };
    default:
      return state;
  }
};
