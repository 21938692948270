export const LOGIN_USER = 'login_user';
export const LOGIN_USER_SUCCESS = 'login_user_success';
export const LOGIN_USER_FAIL = 'login_user_fail';
export const LOGOUT_USER = 'logout_user';
export const LOGOUT_USER_SUCCESS = 'logout_user_success';
export const LOGOUT_USER_FAILURE = 'logout_user_failure';

export const SET_USER_IS_EMPLOYER = 'set_user_is_employer';
export const SET_USER_IS_ADMIN = 'set_user_is_admin';
export const GET_USER_DATA = 'get_user_data';

export const GET_JOBS_LIST = 'get_jobs_list';
export const GET_JOBS_LIST_SUCCESS = 'get_jobs_list_success';
export const GET_JOBS_LIST_FAIL = 'get_jobs_list_fail';

export const GET_EMPLOYERS_LIST_SUCCESS = 'get_employers_list_success';

export const OPEN_JOB_FOR_APPLICATIONS = 'open_job_for_applications';

export const BEGIN_JOB_APPLICATION = 'begin_job_application';
export const END_JOB_APPLICATION = 'end_job_application';
export const SELECT_RESUME = 'select_resume';

export const TEACHER_APPLY_JOB = 'teacher_apply_job';
export const TEACHER_APPLY_JOB_SUCCESS = 'teacher_apply_job_success';
export const TEACHER_APPLY_JOB_FAIL = 'teacher_apply_job_fail';

export const GET_POSTINGS = 'get_postings';
export const GET_POSTINGS_SUCCESS = 'get_postings_success';
export const GET_POSTINGS_FAIL = 'get_postings_fail';
export const UPDATE_JOBS = 'update_jobs';

export const GET_TEACHERS = 'get_teachers';
export const GET_TEACHERS_SUCCESS = 'get_teachers_success';
export const GET_TEACHERS_FAIL = 'get_teachers_fail';
export const GET_TEACHER_JOBS_APPLIED_FOR = 'get_teacher_jobs_applied_for';
export const GET_TEACHER_JOBS_APPLIED_FOR_SUCCESS = 'get_teacher_jobs_applied_for_success';
export const GET_TEACHER_JOBS_APPLIED_FOR_FAIL = 'get_teacher_jobs_applied_for_fail';
export const GET_TEACHER_JOBS_APPLIED_FOR_DETAILED = 'get_teacher_jobs_applied_detailed_for';
export const GET_TEACHER_JOBS_APPLIED_FOR_DETAILED_SUCCESS = 'get_teacher_jobs_applied_for_detailed_success';
export const GET_TEACHER_JOBS_APPLIED_FOR_DETAILED_FAIL = 'get_teacher_jobs_applied_for_detailed_fail';
export const UPDATE_TEACHERS = 'update_teachers';

export const GET_DISTRICTS = 'get_districts';
export const GET_DISTRICTS_SUCCESS = 'get_districts_success';
export const GET_DISTRICTS_FAIL = 'get_districts_fail';
export const UPDATE_DISTRICTS = 'update_districts';

export const GET_TEACHER_TRANCRIPT = 'get_teacher_transcript';
export const GET_TEACHER_TRANCRIPT_SUCCESS = 'get_teacher_transcript_success';
export const GET_TEACHER_TRANCRIPT_FAIL = 'get_teacher_transcript_fail';
export const UPLOAD_TEACHER_TRANSCRIPT_SUCCESS = 'upload_teacher_transcript_success';
export const UPLOAD_TEACHER_TRANSCRIPT_FAIL = 'upload_teacher_transcript_fail';
export const RENAME_TEACHER_TRANSCRIPT = 'rename_teacher_transcript';

export const GET_TEACHER_RESUME = 'get_teacher_resume';
export const GET_TEACHER_RESUME_SUCCESS = 'get_teacher_resume_success';
export const GET_TEACHER_RESUME_FAIL = 'get_teacher_resume_fail';
export const UPLOAD_TEACHER_RESUME_SUCCESS = 'upload_teacher_resume_success';
export const UPLOAD_TEACHER_RESUME_FAIL = 'upload_teacher_resume_fail';
export const RENAME_TEACHER_RESUME = 'rename_teacher_resume';

export const GET_TEACHER_REFERENCE_LETTER = 'get_teacher_reference_letter';
export const GET_TEACHER_REFERENCE_LETTER_SUCCESS = 'get_teacher_reference_letter_success';
export const GET_TEACHER_REFERENCE_LETTER_FAIL = 'get_teacher_reference_letter_fail';
export const UPLOAD_TEACHER_REFERENCE_LETTER_SUCCESS = 'upload_teacher_reference_letter_success';
export const UPLOAD_TEACHER_REFERENCE_LETTER_FAIL = 'upload_teacher_reference_letter_fail';
export const RENAME_TEACHER_REFERENCE_LETTER = 'rename_teacher_reference_letter';

export const GET_TEACHER = 'get_teacher';
export const GET_TEACHER_SUCCESS = 'get_teacher_success';
export const GET_TEACHER_FAIL = 'get_teacher_fail';

export const GET_APPLICANTS_SUCCESS = 'get_applicants_success';
export const GET_APPLICANT_DATA_SUCCESS = 'get_applicant_data_success';
export const GET_APPLICANT_RESUME_URL_SUCCESS =
  'get_applicant_resume_url_success';

export const START_NEW_JOB_POSTING = 'start_new_job_posting';
export const START_ADMIN_JOB_POSTING = 'start_ADMIN_job_posting';
export const CHANGE_NEW_JOB_TITLE = 'change_new_job_title';
export const CHANGE_NEW_JOB_DESCRIPTION = 'change_new_job_description';
export const CHANGE_NEW_JOB_LINK = 'change_new_job_link';
export const CANCEL_NEW_JOB_POSTING = 'cancel_new_job_posting';
export const CANCEL_ADMIN_JOB_POSTING = 'cancel_admin_job_posting';

export const POST_NEW_JOB = 'post_new_job';
export const POST_NEW_JOB_SUCCESS = 'post_new_job_success';
export const POST_NEW_JOB_FAIL = 'post_new_job_fail';

export const ADD_POSTING_TO_STATE = 'add_posting_to_state';
export const ADD_JOB_TO_STATE = 'add_job_to_state';

export const SHOW_NEW_JOB_POSTED_ALERT = 'show_new_job_posted_alert';
export const HIDE_NEW_JOB_POSTED_ALERT = 'hide_new_job_posted_alert';

export const CREATE_TEACHER = 'create_teacher';

export const SETUP_TEACHER_SUBMIT = 'setup_teacher_submit';
export const SETUP_TEACHER = 'setup_teacher';
export const SETUP_TEACHER_SUCCESS = 'setup_teacher_success';
export const UPDATE_CACHED_TEACHER_DATA = 'update_cached_teacher_data';

export const VALIDATE_TEACHERSIGNUP_EMAIL = 'validate_teachersignup_email';

export const GET_APPLICATION_INFO = 'get_application_info';
export const GET_APPLICATION_ATTACHED_INFO_SUCCESS =
  'get_application_attached_info_success';
export const GET_APPLICATION_INFO_USER_DATA_SUCCESS =
  'get_application_info_user_data_success';
export const GET_APPLICATION_INFO_JOB_INFO_SUCCESS =
  'get_application_info_job_info_success';
export const GET_APPLICATION_INFO_DOCUMENT_LINKS_SUCCESS =
  'get_application_info_document_links_success';
export const GET_APPLICATION_INFO_TRANSCRIPT_LINK_SUCCESS =
  'get_application_info_transcript_link_success';
export const SET_APPLICATION_INFO_CLEAR_REFERENCE_LINKS = 'set_application_info_clear_reference_links';
export const GET_APPLICATION_INFO_REFERENCE_LINK_SUCCESS =
  'get_application_info_reference_link_success';
export const GET_APPLICATION_INFO_COMPLETE = 'get_application_info_complete';
export const GET_APPLICATION_INFO_FAILED = 'get_application_info_failed';
export const RESET_APPLICATION_INFO = 'reset_application_info';

export const ADD_EMPLOYER_QUESTION = 'add_employer_question';
export const REMOVE_EMPLOYER_QUESTION = 'remove_employer_question';
export const GET_EMPLOYER_QUESTIONS = 'get_employer_questions';
export const GET_EMPLOYER_QUESTIONS_SUCCESS = 'get_employer_questions_success';
export const GET_EMPLOYER_QUESTIONS_FAIL = 'get_employer_questions_fail';

export const ADDING_NEW_EMPLOYER_QUESTION = 'adding_new_employer_question';
export const ADD_NEW_EMPLOYER_QUESTION_SUCCESS =
  'add_new_employer_question_success';
export const ADD_EMPLOYER_QUESTION_SUCCESS_SHOW_ALERT =
  'add_employer_question_succcess_show_alert';
export const HIDE_NEW_QUESTION_ALERT = 'hide_new_question_alert';

export const DELETING_QUESTIONS = 'deleting_questions';
export const DELETE_QUESTIONS_SUCCESS = 'delete_questions_success';
export const DELETE_QUESTIONS_SUCCESS_SHOW_ALERT =
  'delete_questions_success_show_alert';
export const HIDE_DELETE_QUESTIONS_ALERT = 'hide_delete_questions_alert';

export const UPDATE_CURRENT_GEO = 'update_current_geo';
export const UPDATE_CURRENT_GEO_FROM_ZIPCODE = 'update_current_geo_from_zipcode';
export const UPDATE_CURRENT_GEO_FAILED = 'update_current_geo_failed';
export const LOOKUP_CURRENT_GEO = 'lookup_current_geo';

export const GET_EMPLOYER_DATA = 'get_employer_data';
export const GET_TEACHER_DATA = 'get_teacher_data';
export const GET_DISTRICT_DATA = 'get_district_data';
export const GET_EXTERNAL_EMPLOYER_DATA = 'get_external_employer_data';
export const UPDATE_EMPLOYER_POSTINGS = 'update_employer_postings';

export const CREATE_DISTRICT = 'create_district';
export const CREATE_DISTRICT_SUCCESS = 'create_district_success';
export const CREATE_DISTRICT_FAIL = 'create_district_fail';

export const SET_APPLICANT_PROCEED_STATUS = 'set_applicant_proceed_status';
export const SET_APPLICANT_PROCEED_STATUS_SUCCESS =
  'set_applicant_proceed_status_success';

export const ADD_DOCUMENT_START = 'add_document_start';
export const ADD_DOCUMENT_SUCCESS = 'add_document_success';

export const TRACK_NEW_DOC_SETUP_RENAME = 'track_new_doc_setup_rename';
export const TRACK_NEW_DOC_RESET = 'track_new_doc_reset';
export const START_DOC_RENAME = 'start_doc_rename';
export const DOC_RENAME_SUCCESS = 'doc_rename_success';
export const DOC_RENAME_FAILURE = 'doc_rename_failure';

export const UPDATE_EMPLOYER_VERIFICATION = 'update_employer_verification';

export const ADMIN_UPDATE_TEACHER = 'admin_update_teacher';
export const ADMIN_DISTRICT_DATA_SUCCESS = 'admin_district_data_success';
export const GET_ADMIN_POSTINGS_SUCCESS = 'get_admin_postings_success';
export const GET_ADMIN_POSTINGS_FAIL = 'get_admin_postings_fail';
export const GET_ADMIN_POSTINGS = 'get_admin_postings';
