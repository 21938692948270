import { auth } from '../../firebase/Firebase';
import * as types from './types';
import store from '..';
// import { getQuestionsByEmployer } from './questions';
import django from '../../apis/django';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

const getApplicants = (jobId, applicants) => {
  const payload = { [jobId]: applicants };
  store.dispatch({ type: types.GET_APPLICANTS_SUCCESS, payload });
};

// AB 5/21/22: This function is duplicated in src/state/actions/auth.js
// where it is called by the automaticLogin() function.
// AB 10/31/22: I think we should JSON.parse() the fields we know are JSON
// before we save them in state. Or maybe we should do that WHEN we 
// save them in state, in GET_POSTINGS_SUCCESS.
export const getPostings = () => async dispatch => {
  if (auth.currentUser) {
    try {
      dispatch({ type: types.GET_POSTINGS });
      const { uid } = auth.currentUser;
      // Bring back jobs for the logged-in employer along with any applications
      const config = await getRequestConfig();
      const postingResponse = await django.get(`/jobs/employer/${uid}/applications/`, config);
      const postings = postingResponse.data
      // While it may seem odd to take part of posting and put it elsewhere in state,
      // it actually makes things much easier in Postings.
      var applicants = {}
      postings.forEach(posting => {
        applicants[posting.id]=posting.applications;
      });
      dispatch({ type: types.GET_APPLICANTS_SUCCESS, payload: applicants });
      dispatch({ type: types.GET_POSTINGS_SUCCESS, payload: postings });
    } catch (err) {
      console.error(err);
      console.error('There was an error getting postings.');
      dispatch({ type: types.GET_POSTINGS_FAIL });
    }
  }
};

export const getAdminPostings = () => async dispatch => {
  if (auth.currentUser) {
    try {
      dispatch({ type: types.GET_ADMIN_POSTINGS });
      const { uid } = auth.currentUser;
      const config = await getRequestConfig();
      const response = await django.get(`/scrapedjobs/employer/${uid}/`, config);
      dispatch({ type: types.GET_ADMIN_POSTINGS_SUCCESS, payload: response.data });
    } catch (err) {
      console.error(err);
      console.error('There was a problem getting job postings.');
      dispatch({ type: types.GET_ADMIN_POSTINGS_FAIL });
    }
  }
};

export const getQuestions = () => async dispatch => {
  if (auth.currentUser) {
    try {
      dispatch({ type: types.GET_EMPLOYER_QUESTIONS });
      const { uid } = auth.currentUser;
      const config = await getRequestConfig();
      const questionResponse = await django.get(`/essayquestions/employer/${uid}/`, config);
      const questions = questionResponse.data;
      dispatch({ type: types.GET_EMPLOYER_QUESTIONS_SUCCESS, payload: questions });
    } catch (err) {
      console.error(err);
      console.error('There was a problem trying to get questions');
      dispatch({ type: types.GET_EMPLOYER_QUESTIONS_FAIL });
    }
  }
};

export const updatePostingDescription = (posting, newDescription) => async dispatch => {
  try {
    const updatePayload = {
      description: newDescription
    }
    const config = await getRequestConfig();
    await django.patch(`/jobs/id/${posting.id}/`, updatePayload, config)
    const employerPostingsCopy = [...(store.getState()).employer.postings];
    let foundIndex = employerPostingsCopy.findIndex(postingToUpdate => postingToUpdate.id === posting.id);
    employerPostingsCopy[foundIndex].description = newDescription;
    dispatch({ type: types.UPDATE_EMPLOYER_POSTINGS, payload: employerPostingsCopy });
  } catch (err) {
    console.error(err);
    console.error('There was a problem updating the posting description.');
  }
};

export const updatePostingTitle = (posting, newTitle) => async dispatch => {
  try {
    const updatePayload = {
      title: newTitle
    }
    const config = await getRequestConfig();
    await django.patch(`/jobs/id/${posting.id}/`, updatePayload, config)
    const employerPostingsCopy = [...(store.getState()).employer.postings];
    let foundIndex = employerPostingsCopy.findIndex(postingToUpdate => postingToUpdate.id === posting.id);
    employerPostingsCopy[foundIndex].title = newTitle;
    dispatch({ type: types.UPDATE_EMPLOYER_POSTINGS, payload: employerPostingsCopy });
  } catch (err) {
    console.error(err);
    console.error('There was a problem updating the posting title');
  }
};

// send [] when you don't make changes to licensure
export const updatePostingLicensure = (posting, newLicensure) => async dispatch => {
  try {
    var selectedLicensure = JSON.stringify([])
    if (Array.isArray(newLicensure) && newLicensure.length > 0) {
      selectedLicensure = JSON.stringify(newLicensure)
    }
    const updatePayload = {
      selectedLicensure
    }
    const config = await getRequestConfig();
    await django.patch(`/jobs/id/${posting.id}/`, updatePayload, config)
    const employerPostingsCopy = [...(store.getState()).employer.postings];
    let foundIndex = employerPostingsCopy.findIndex(postingToUpdate => postingToUpdate.id === posting.id);
    employerPostingsCopy[foundIndex].selectedLicensure = newLicensure;
    dispatch({ type: types.UPDATE_EMPLOYER_POSTINGS, payload: employerPostingsCopy });
  } catch (err) {
    console.dir(err, {depth:null});
    console.error('There was a problem updating the posting licensure');
  }
};

export const updatePostingGradeLevel = (posting, newGradeLevel) => async dispatch => {
  try {
    var selectedGradeLevels = ""
    if (newGradeLevel && newGradeLevel.length > 0 && newGradeLevel[0] != '') {
      selectedGradeLevels = JSON.stringify(newGradeLevel)
    }
    const updatePayload = {
      selectedGradeLevels
    }
    const config = await getRequestConfig();
    await django.patch(`/jobs/id/${posting.id}/`, updatePayload, config)
    const employerPostingsCopy = [...(store.getState()).employer.postings];
    let foundIndex = employerPostingsCopy.findIndex(postingToUpdate => postingToUpdate.id === posting.id);
    employerPostingsCopy[foundIndex].selectedGradeLevels = newGradeLevel;
    dispatch({ type: types.UPDATE_EMPLOYER_POSTINGS, payload: employerPostingsCopy });
  } catch (err) {
    console.error(err);
    console.error('There was a problem updating the posting grade level');
  }
};

export const updatePostingPartOrFullTime = (posting, newPartOrFullTime) => async dispatch => {
  try {
    var selectedPartOrFullTime = ""
    if (typeof newPartOrFullTime === 'object' && newPartOrFullTime != 'null' && Object.keys(newPartOrFullTime).length > 0) {
      selectedPartOrFullTime = JSON.stringify(newPartOrFullTime)
    }
    const updatePayload = {
      selectedPartOrFullTime
    }
    const config = await getRequestConfig();
    await django.patch(`/jobs/id/${posting.id}/`, updatePayload, config)
    const employerPostingsCopy = [...(store.getState()).employer.postings];
    let foundIndex = employerPostingsCopy.findIndex(postingToUpdate => postingToUpdate.id === posting.id);
    employerPostingsCopy[foundIndex].selectedPartOrFullTime = newPartOrFullTime;
    dispatch({ type: types.UPDATE_EMPLOYER_POSTINGS, payload: employerPostingsCopy });
  } catch (err) {
    console.dir(err, {depth:null});
    console.error('There was a problem updating the posting part time/full time');
  }
};

export const updatePostingQuestions = (posting, newQuestions) => async dispatch => {
  try {
    var selectedQuestions = ""
    if (newQuestions && newQuestions.length > 0 && newQuestions[0] != '') {
      selectedQuestions = JSON.stringify(newQuestions)
    }
    const updatePayload = {
      selectedQuestions
    }
    const config = await getRequestConfig();
    await django.patch(`/jobs/id/${posting.id}/`, updatePayload, config)
    const employerPostingsCopy = [...(store.getState()).employer.postings];
    let foundIndex = employerPostingsCopy.findIndex(postingToUpdate => postingToUpdate.id === posting.id);
    employerPostingsCopy[foundIndex].selectedQuestions = newQuestions;
    dispatch({ type: types.UPDATE_EMPLOYER_POSTINGS, payload: employerPostingsCopy });
  } catch (err) {
    console.dir(err, {depth:null});
    console.error('There was a problem updating the posting questions');
  }
};

export const updateJobPostingStatus = (posting, isClosed) => async (dispatch) => {
  try {
    const updatePayload = {
      isClosed, dateClosed: Date.now()
    }
    const config = await getRequestConfig();
    await django.patch(`/jobs/id/${posting.id}/`, updatePayload), config
    const employerPostingsCopy = [...(store.getState()).employer.postings];
    let foundIndex = employerPostingsCopy.findIndex(postingToUpdate => postingToUpdate.id === posting.id);
    employerPostingsCopy[foundIndex].isClosed = isClosed;
    employerPostingsCopy[foundIndex].dateClosed = Date.now();
    dispatch({ type: types.UPDATE_EMPLOYER_POSTINGS, payload: employerPostingsCopy });
  } catch (err) {
    console.error(err);
    console.error('There was a problem trying to close the job');
  }
};

export const updatePostingExpirationDate = (posting, expirationDate) => async (dispatch) => {
  try {
    const updatePayload = {
      expirationDate
    }
    // Note that here we are actually paying attention to the response from the backend
    // That's because the backend can choose a expirationDate for us (if we send nothing)
    // and we want to show the chosen expirationDate in the modal
    const config = await getRequestConfig();
    var response = await django.patch(`/jobs/id/${posting.id}/`, updatePayload, config)
    var returnedExpirationDate = response.data['expirationDate']
    const employerPostingsCopy = [...(store.getState()).employer.postings];
    let foundIndex = employerPostingsCopy.findIndex(postingToUpdate => postingToUpdate.id === posting.id);
    employerPostingsCopy[foundIndex].expirationDate = returnedExpirationDate;
    dispatch({ type: types.UPDATE_EMPLOYER_POSTINGS, payload: employerPostingsCopy });
  } catch (err) {
    console.error(err);
    console.error('There was a problem trying to update the expiration date');
  }
};