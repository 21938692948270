import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { trackUploadedDocument, handleDocumentUploadError } from '../../state/actions/newTeacherSetupForm';
import { renameDocument } from '../../state/actions/teacherSettings';
import { getTeacherRefLetters, getTeacherResumes, getTeacherTranscripts } from '../../state/actions/getTeachers';
import FileUploadButton from '../FileUploadButton/FileUploadButton';
import CustomButton from '../CustomButton/CustomButton';

class TeacherUploads extends Component {
  state = {
    newDocName: '',
  }

  fetchUserDocs() {
    const {
      user,
      teacherRefLetters,
      gettingTeacherRL,
      teacherResumes,
      gettingTeacherRes,
      teacherTranscripts,
      gettingTeacherTrans
    } = this.props;
    if (user != null) {
      if (teacherRefLetters == null && !gettingTeacherRL) {
        this.props.getTeacherRefLetters(user.uid);
      }
      if (teacherResumes == null && !gettingTeacherRes) {
        this.props.getTeacherResumes(user.uid);
      }
      if (teacherTranscripts == null && !gettingTeacherTrans) {
        this.props.getTeacherTranscripts(user.uid);
      }
    }
  }

  componentDidMount() {
    this.fetchUserDocs()
  }

  componentDidUpdate() {
    this.fetchUserDocs()
  }

  renderFileUploadButton = (labelText, type) => {
    const { user } = this.props;
    if (user) {
      return (
        <FileUploadButton
          accept=".pdf"
          labelText={labelText}
          folder={`${type}/${user.uid}`}
          onStart={() => console.log(`started ${type} upload`)}
          onError={error => this.props.handleDocumentUploadError(error, type)}
          onProgress={null}
          onSuccess={(filename) =>
            this.props.trackUploadedDocument(
              type,
              filename,
              `${type}/${user.uid}`,
              `Unnamed ${type} from ${new Date().toDateString()}`,
            )}
        />
      );
    }
    return null;
  };

  render() {
    const { teacherRefLetters, teacherResumes, teacherTranscripts } = this.props;
    if (teacherRefLetters && teacherResumes && teacherTranscripts) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {this.renderUploadErrorArea()}
          {this.renderRenameArea()}
          {this.renderFileUploadButton('Upload a resume (PDF only)', 'resumes')}
          {this.renderFileUploadButton('Upload a reference letter (PDF only)', 'references')}
          {this.renderFileUploadButton('Upload a transcript (PDF only)', 'transcripts')}
        </div>
      )
    } else {
      return (
        <div/>
      )
    }
  }

  onRenameClick = () => {
    const { teacherSettings } = this.props;
    if (this.props.renameDocument(
      teacherSettings.folderForRename,
      teacherSettings.docIdForRename,
      this.state.newDocName.substr(0, 255),
    )) {
      this.setState({ newDocName: '' })
    };
  };

  renderUploadErrorArea = () => {
    const labelValues = {
      resumeUploadFailed: 'Resume',
      transcriptUploadFailed: 'Transcript',
      referenceLetterUploadFailed: 'Reference Letter',
    };

    const failedUploads = Object.keys(labelValues).filter(key => this.props[key] == true);
    let failedUploadsText = '';

    failedUploads.forEach(key => {
      failedUploadsText = failedUploadsText.concat(`, ${labelValues[key]}`);
      console.log(failedUploadsText);
    });
    failedUploadsText = failedUploadsText.slice(2);

    if (failedUploadsText !== '') {
      return (
        <div>
          <h3>Error with file upload(s): {failedUploadsText}</h3>
        </div>
      )
    }
  }

  renderRenameArea = () => {
    const { uploadDone, isRenaming, renameDone, renameFailed } = this.props.teacherSettings;
    if (renameDone) {
      return <h3>Successfully uploaded and named document.</h3>;
    } else if (renameFailed) {
      return (
        <div>
          <h3>Error - Rename failed. Please try again.</h3>
          {this.renderRenameFormControl(isRenaming)}
        </div>
      );
    } else if (uploadDone && !isRenaming) {
      return (
        <div>
          <h3>Success! Please give your new document a name.</h3>
          {this.renderRenameFormControl(isRenaming)}
        </div>
      );
    } else {
      return <div />;
    }
  };

  renderRenameFormControl = (isRenaming) => {
    return (
      <div>
        <Form.Group controlId="formDocumentRename">
          <Form.Control
            placeholder="Name your document..."
            value={this.state.newDocName}
            onChange={e => this.setState({ newDocName: e.target.value })}
          />
        </Form.Group>
        <CustomButton onClick={this.onRenameClick}>
          {isRenaming ? 'Naming...' : 'Name'}
        </CustomButton>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  user: state.auth.user, // user.uid is user id
  gettingTeacherRL: state.teacherRefLetters.gettingTeacherRL,
  teacherRefLetters: state.teacherRefLetters.teacherRefLetters,
  teacherResumes: state.teacherResumes.teacherResumes,
  gettingTeacherRes: state.teacherResumes.gettingTeacherRes,
  teacherTranscripts: state.teacherTranscripts.teacherTranscripts,
  gettingTeacherTrans: state.teacherTranscripts.gettingTeacherTrans,
  userData: state.auth.userData,
  teacherSettings: state.teacherSettings,
  resumeUploadFailed: state.teacherResumes.uploadError,
  transcriptUploadFailed: state.teacherTranscripts.uploadError,
  referenceLetterUploadFailed: state.teacherRefLetters.uploadError,
});
  
export default connect(
  mapStateToProps,
  {
    trackUploadedDocument,
    handleDocumentUploadError,
    getTeacherRefLetters,
    getTeacherResumes,
    getTeacherTranscripts,
    renameDocument
  }
)(TeacherUploads);