import * as types from '../actions/types';

const INITIAL_STATE = {
  // [posting1, posting2, ...]
  postings: null,
  // {jobId: [applicant1, applicant2]}
  applicants: null,
  // {resumeId: url}
  resumeLinks: {},
  // this is [] because null will break react-select
  questions: [],
  employerData: null,
  externalEmployerData: null,
  gettingPostings: null,
  gettingAdminPostings: null,
  gettingEmployerQuestions: null,
};

const POSTING_JSON_KEYS = [
  'selectedQuestions',
  'selectedLicensure',
  'selectedGradeLevels',
  'selectedPartOrFullTime'
]

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_POSTINGS:
      return { ...state, gettingPostings: true };
    case types.GET_POSTINGS_SUCCESS:
      // Loop through the postings in the payload
      // For every key in the object whose value can be a JavaScript object,
      // modify the key's value to be the result of a JSON.parse on that value
      return { ...state, gettingPostings: false, postings: action.payload.map( posting => {
        POSTING_JSON_KEYS.forEach(key => {
          if (key in posting) {
            try {
              posting[key] = JSON.parse(posting[key])
            } catch (error) {}
          }
        })
        return posting
      })}
    case types.GET_POSTINGS_FAIL:
      return { ...state, gettingPostings: false };
    case types.ADD_POSTING_TO_STATE: {
      const postingsCopy = state.postings.slice();
      // We make a shallow copy of action.payload, because
      // from newJobForm we dispatch ADD_POSTING_TO_STATE and
      // ADD_JOB_TO_STATE one will modify the contents of
      // action.payload before the other can get to it, which
      // causes errors.
      var payloadCopy = Object.assign({}, action.payload)
      // We need to loop over the keys in action.payload
      // if the key matches one in POSTING_JSON_KEYS, then we need to parse it
      // because it's been stringified, but we want it as a JSON object in state
      Object.keys(payloadCopy).forEach(key => {
        if (POSTING_JSON_KEYS.includes(key)) {
          payloadCopy[key] = JSON.parse(payloadCopy[key])
        }
      })
      postingsCopy.unshift(payloadCopy);
      return { ...state, postings: postingsCopy };
    }
    case types.GET_APPLICANTS_SUCCESS: {
      return { ...state, applicants: action.payload };
    }
    case types.GET_APPLICANT_RESUME_URL_SUCCESS: {
      const newResumeLinks = { ...state.resumeLinks, ...action.payload };
      return { ...state, resumeLinks: newResumeLinks };
    }
    case types.ADD_EMPLOYER_QUESTION:
      const questionsCopy = state.questions.slice();
      questionsCopy.push(action.payload);
      return { ...state, questions: questionsCopy };
    case types.REMOVE_EMPLOYER_QUESTION:
      // We just got an ID of a question in state
      // Make a new copy of the array of questions in state without the question with that ID (value)
      const questionsRemaining = state.questions.filter(question => question.value != action.payload)
      return { ...state, questions: questionsRemaining };
    case types.GET_EMPLOYER_QUESTIONS:
      return { ...state, gettingEmployerQuestions: true };
    case types.GET_EMPLOYER_QUESTIONS_SUCCESS:
      return { ...state, questions: action.payload };
    case types.GET_EMPLOYER_QUESTIONS_FAIL:
      return { ...state, gettingEmployerQuestions: false };
    case types.GET_EMPLOYER_DATA:
      return { ...state, employerData: action.payload };
    case types.GET_EXTERNAL_EMPLOYER_DATA:
      return { ...state, externalEmployerData: action.payload };
    case types.UPDATE_EMPLOYER_POSTINGS:
      return { ...state, postings: action.payload };
    case types.GET_ADMIN_POSTINGS:
      return { ...state, gettingAdminPostings: true };
    case types.GET_ADMIN_POSTINGS_SUCCESS:
      return { ...state, adminPostings: action.payload, gettingAdminPostings: false };
    case types.GET_ADMIN_POSTINGS_FAIL:
      return { ...state, gettingAdminPostings: false };
    default:
      return state;
  }
};
