import React from 'react';
import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';
import styles from './CustomButton.styles';

const getCustomStyle = (classes, variant) => {
  switch (variant) {
    case 'default-contained':
      return classes.containedButton;
    case 'default-outlined':
      return classes.outlinedButton;
    case 'danger-outlined':
      return classes.dangerOutlinedButton;
    case 'danger-contained':
      return classes.dangerContainedButton;
    case 'success-outlined':
      return classes.successOutlinedButton;
    case 'success-contained':
      return classes.successContainedButton;
    case 'no-outline':
      return classes.noOutlineButton;
    default:
      return classes.containedButton;
  }
};

function CustomButton({
  onClick = () => { },
  variant = 'default-outlined',
  customStyle = {},
  children,
  isDisabled = false,
  isVisible = true,
  classes,
  type,
  href = '',
  target = '',
}) {
  const customButtonStyle = getCustomStyle(classes, variant);
  return (
    <Button
      onClick={onClick}
      style={{
        ...customStyle,
        display: isVisible ? '' : 'none'
      }}
      disabled={isDisabled}
      className={customButtonStyle}
      type={type}
      href={href}
      target={target}
    >
      {children}
    </Button>
  );
}

export default withStyles(styles)(CustomButton);
