/* eslint-disable react/no-access-state-in-setstate */
import zipObject from 'lodash/zipObject';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  Form,
} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { applyJob } from '../../state/actions/applyJob';
import { endJobApplication } from '../../state/actions/apply';
import CustomButton from '../CustomButton/CustomButton';
import CustomSelect from '../CustomSelect/CustomSelect';

const resumeFieldName="resume"
const transcriptFieldName="transcript"
const referenceLettersFieldName="referenceLetters"
const coverLetterFieldName="coverLetter"

// TODO: MAKE THIS WORK WITH NO RESUMES AVAILABLE
class ApplyModal extends Component {
  newRefLetters = [];

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  handleClose = () => {
    this.setState({ show: false });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleApply = async (values, actions) => {
    // package up the questions and answers

    const { job, resumes, transcripts } = this.props;
    // values is an object, where the keys are form field IDs and the values are corresponding form field values
    // We want to bring out the key/value pairs that are for answers to essay questions
    // Ultimately, we want to make an object where the keys are the question labels, and 
    // the values are themselves objects, like this: { answer: 'user answer', value: 'the ID of the essay question'}
    // I think it's better to look up the ID than let it be user supplied by parsing it out of the field ID
    // So we have to look up the ID by looking through job.selectedQuestions for a matching label
    // console.dir(job.selectedQuestions, {depth:null});
    // NOTE: This is assuming that the order of "values" is deterministic!
    // If it's not, then we'll have to work backwards from the field name, and embed the 
    // essay question ID in the field name.
    const essayQuestionFieldNames = Object.keys(values).filter(fieldName => fieldName.match(/question.*/))
    // console.dir(essayQuestionFieldNames, {depth:null});
    // Build an array of objects like { answer: 'user answer', value: 'the ID of the essay question'}
    const answers = essayQuestionFieldNames.map((fieldName, index) => {
      return { 'answer': values[fieldName], 'value': job.selectedQuestions[index].value}
    })

    let extraEssays = null;
    if (answers.length !== 0) {
      const extraQuestions = job.selectedQuestions.map(
        question => question.label
      );
      // This creates a new object, where the labels are the text of essay questions
      // and the values the corresponding objects created above
      extraEssays = zipObject(extraQuestions, answers);
    }

    let resumeId = values[resumeFieldName];
    let transcriptId = values[transcriptFieldName];

    const selectedLetterIds = [];
    if (referenceLettersFieldName in values && values[referenceLettersFieldName].length !== 0) {
      values[referenceLettersFieldName].forEach(letter => {
        selectedLetterIds.push(letter.value);
      });
    }

    this.props.endJobApplication(this.props.job.id);
    await this.props.applyJob(
      job,
      resumeId,
      transcriptId,
      JSON.stringify(selectedLetterIds),
      JSON.stringify(extraEssays),
      values[coverLetterFieldName]
    );
    actions.setSubmitting(false);

    const extLink = this.props.job.externalLink;
    if (extLink === '') {
      this.handleClose();
    } else if (extLink.includes('https') || extLink.includes('http')) {
      window.location.assign(extLink);
    } else {
      const navLink = 'http://'.concat(extLink);
      window.location.assign(navLink);
    }
  };

  renderExtraQuestion = (question, index, formik) => {
    const key = index;
    const name = `question${index}`

    return (
      // add the jobId or something to the index for the key
      <div key={key}>
        <Form.Group>
          <Form.Label>{question.label}</Form.Label>
          <Form.Control
              as="textarea"
              id={name}
              placeholder="Enter your answer here..."
              isInvalid={formik.errors[name] && formik.touched[name]}
              isValid={!formik.errors[name] && formik.touched[name]}
              {...formik.getFieldProps(`question${index}`)}
          />
        </Form.Group>
      </div>
    );
  };

  renderExtraQuestionsIfExist = (formik) => {
    // AB: While this would be easier to do with map, we need to pass formik in
    // to renderExtraQuestions. You can't do that using map and a callback function.
    // You can use thisArgs, but then renderExtraQuestions can't access this.state
    // (although, now it doesn't need to, so maybe now we can use map)
    // So instead we loop over the questions and create an array with the HTML
    // and then that can be rendered as JSX (evidently)
    const questionsHTML = [];
    for (var key in this.props.job.selectedQuestions) {
      var result = this.renderExtraQuestion(this.props.job.selectedQuestions[key], key, formik)
      questionsHTML.push(result)
    }
    if (
      this.props.job.selectedQuestions &&
      Array.isArray(this.props.job.selectedQuestions) &&
      this.props.job.selectedQuestions.length > 0
    ) {
      return (
        <div>
          <p>
            This job posting requires additional essay questions, please answer
            below...
          </p>
          {questionsHTML}
        </div>
      );
    }
    return (
      <div>
        <p>This job posting does not require any additional essay questions.</p>
      </div>
    );
  };

  renderCoverLetter(formik) {
    return (
      <div>
        <Form.Group>
          <Form.Label>(Optional) Cover Letter</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Cover letter"
            isInvalid={formik.errors[coverLetterFieldName] && formik.touched[coverLetterFieldName]}
            isValid={!formik.errors[coverLetterFieldName] && formik.touched[coverLetterFieldName]}
            {...formik.getFieldProps(coverLetterFieldName)}
          />
        </Form.Group>
      </div>
    );
  }

  renderTeacherDocumentItem = (item) => {
    return (
    {
      value: item[0],
      label: item[2]
    }
    )
  };

  renderDocumentSelect = (formik) => {
    // NOTE: The CustomSelects below NEED onChange and onBlur, do NOT try using formik.getFieldProps
    // See the gist here https://gist.github.com/hubgit/e394e9be07d95cd5e774989178139ae8
    // and docs here https://formik.org/docs/api/formik#setfieldvalue-field-string-value-any-shouldvalidate-boolean--void
    //const { resumes, transcripts, referenceLetters } = this.props;

    var resumes, transcripts, referenceLetters;
    if (this.props.resumes == null) {
      resumes = []
    } else {
      resumes = this.props.resumes
    }
    if (this.props.transcripts == null) {
      transcripts = []
    } else {
      transcripts = this.props.transcripts
    }
    if (this.props.referenceLetters == null) {
      referenceLetters = []
    } else {
      referenceLetters = this.props.referenceLetters
    }

    return (
      <div>
        <Form.Label>Select resume and transcript</Form.Label>
        <Form.Label>
          <i>
            To add resumes, transcripts, and letters of reference go to your
            user settings page.
          </i>
        </Form.Label>
        {/* Resume select */}
        <CustomSelect
            name={resumeFieldName}
            defaultValue={{ label: "Select resume", value: ""}}
            options={resumes.map(this.renderTeacherDocumentItem)}
            isInvalid={formik.errors[resumeFieldName] && formik.touched[resumeFieldName]}
            onChange={formik.setFieldValue}
            onBlur={formik.setFieldTouched}
          />
        <br />
        {/* Transcript select */}
        <CustomSelect
            name={transcriptFieldName}
            defaultValue={{ label: "Select transcript", value: ""}}
            options={transcripts.map(this.renderTeacherDocumentItem)}
            isInvalid={formik.errors[transcriptFieldName] && formik.touched[transcriptFieldName]}
            onChange={formik.setFieldValue}
            onBlur={formik.setFieldTouched}
          />
        <br />
        <Form.Label>Select letters of reference</Form.Label>
        <CustomSelect
            name={referenceLettersFieldName}
            defaultValue={null}
            options={referenceLetters.map(this.renderTeacherDocumentItem)}
            isMulti={true}
            isInvalid={formik.errors[referenceLettersFieldName] && formik.touched[referenceLettersFieldName]}
            onChange={formik.setFieldValue}
            onBlur={formik.setFieldTouched}
          />
      </div>
    );
  };

  render() {
    // AB: 6/20/22 Removed appsOpen from the line below because it's not used
    const { hasApplied, isApplying, job } = this.props;
    const btnColor = hasApplied ? 'success-contained' : '';
    let btnText = 'Apply';

    if (hasApplied) {
      btnText = 'Applied!';
    } else if (isApplying) {
      btnText = 'Applying...';
    } else if (job.isClosed) {
      btnText = 'Closed';
    }

    // AB: These are the fields whose names we already know
    const staticSchema = yup.object().shape({
      coverLetter: yup
        .string(),
      [resumeFieldName]: yup
        .string(),
      [transcriptFieldName]: yup
        .string(),
      [referenceLettersFieldName]: yup
        .array(),
    })

    // AB: These are the fields whose names are dynamic - the "essay questions"
    // There can be 0 to N of them, so we can't use static names
    // Thus the yup object has to be made dynamically.
    var dynamicSchema = []
    if(this.props.job.selectedQuestions && Array.isArray(this.props.job.selectedQuestions)) {
      this.props.job.selectedQuestions.forEach((question, index) => {
        dynamicSchema[`question${index}`] = yup.string().required()
      })
    }
    const schema = staticSchema.concat(yup.object().shape(dynamicSchema))

    // TODO: As of now, a teacher can only apply if they have a reference, a resume, and a transcript
    // AB: 6/20/22 Commented-out because not currently used
    // const areAllDocumentsUploaded = (resumes.length !== 0 && referenceLetters.length !== 0 && transcripts.length !== 0);

    // AB: Just to document the woe for future reference:
    // There's an issue with using Bootstrap's Modal and Formik at the same time: onSubmit just doesn't work.
    // https://github.com/jaredpalmer/formik/issues/2468
    const initialValues = {
      coverLetter: '',
      [resumeFieldName]: '',
      [transcriptFieldName]: [],
      [referenceLettersFieldName]: [],
    }
    return (
      <div>
        <CustomButton
          variant={btnColor}
          onClick={this.handleShow}
          isDisabled={
            hasApplied ||
            isApplying
          }
        >
          {btnText}
        </CustomButton>
        <Formik
          onSubmit={this.handleApply}
          validationSchema={schema}
          initialValues={initialValues}
        >
          {formik => (
            <Modal show={this.state.show} onHide={this.handleClose}>
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    Apply to {this.props.job.title}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>{this.props.job.description}</p>
                  <hr />
                  {this.renderExtraQuestionsIfExist(formik)}
                  {this.renderCoverLetter(formik)}
                  {this.renderDocumentSelect(formik)}
                </Modal.Body>
                <Modal.Footer>
                  <CustomButton
                    onClick={this.handleClose}
                    variant="default-outlined"
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    isDisabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
                  >
                    Apply
                  </CustomButton>
                </Modal.Footer>
              </Form>
            </Modal>
          )}
        </Formik>
      </div>
    );
  }
}
export default connect(
  null,
  { applyJob, endJobApplication }
)(ApplyModal);
