import React from 'react';

function TermsAndConditions() {
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h5>TERMS OF USE</h5>
        <p>
          PLEASE READ THIS TERMS OF USE AGREEMENT CAREFULLY BEFORE USING THIS
          SITE.
        </p>
      </div>
      <ol>
        <li>
          <b>
            Binding Agreement.
          </b>
          <span style={{ marginLeft: 10 }}>
            This Terms of Use agreement (“Terms”) provides the terms and
            conditions under which you may use the Arkansas Teachers web site
            maintained by theUniversity of Arkansas (the “university”), as well
            as all of the services provided by the web site(such site and services
            collectively the “Site”). By using the Site, you agree to be bound by
            theseTerms.
          </span>
        </li>
        <li>
          <b>
            Privacy Policy.
          </b>
          <span style={{ marginLeft: 10 }}>
            Please carefully read the Privacy Policy linked on this Site. These
            Terms include the Privacy Policy, which is incorporated into these Terms
            by reference. Your use of the Site indicates that you have read and agree
            with the Privacy Policy.
          </span>
        </li>
        <li>
          <b>
            Modifications.
          </b>
          <span style={{ marginLeft: 10 }}>
            The university reserves the right, at its sole discretion, to change theseTerms
            at any time by posting an updated version to this web page. The changes are
            effective when posted. Your continued use of the Site following such posting will
            be deemed acceptanc eof those changes. You should visit this page periodically
            to review the most current Terms.Additionally, the university reserves the right
            to make changes to the Site at any time, including but not limited to adding or
            eliminating features and content at the university’s sole discretion.
          </span>
        </li>
        <li>
          <b>
            No Warranties.
          </b>
          <span style={{ marginLeft: 10 }}>
            Neither the university nor its content or service providers guarantee or
            warrant the Site against errors, defects, delays, omissions, interruptions or losses,
            including losses of data, nor are any files downloaded from the Site warranted or
            guaranteed to be free of viruses, bugs, worms or other such destructive properties.
          </span>
        </li>
        <li>
          <b>
            Submissions.
          </b>
          <span style={{ marginLeft: 10 }}>
            Regarding any suggestions, comments, notes, information, or other materials
            that you submit to the Site or to the university in relation to the Site,
            you grant to the university the irrevocable, perpetual, transferable,
            nonexclusive, royalty-free worldwide license(with the right to sublicense)
            and right (i) to reproduce, publish, distribute and display such submissions,
            (ii) to create derivative works from such submissions, (iii) to edit, modify or delete
            such submissions, and (iv) to use such submissions for any purpose without compensation
            or attribution.
          </span>
        </li>
        <li>
          <b>
            Conduct.
          </b>
          <span style={{ marginLeft: 10 }}>
            You are responsible for the content that you submit to the Site. You may not
            submit any material or content that infringes any patent, trademark, copyright, or other
            proprietary rights of any party. You agree that you will not violate any local, state,
            federal, or international laws or regulations, including those related to copyright
            or other intellectual property rights. You further agree that you will not submit
            any information that (i) is disruptive, threatening, abusive, profane, harassing,
            embarrassing, defamatory, false, misleading, libelous, obscene, hateful, racially
            or ethnically offensive, or otherwise objectionable as solely determined by the
            university, (ii) contains any product or service advertisements or endorsements,
            or (iii) can be construed as political lobbying. You agree that you will not use
            the Site to engage in fraud, misrepresentation, unethical behavior. You agree that you
            will not engage in spamming/flooding or attempt to frame or mirror any part of the Site.
            You shall not express or imply that anystatements you make are endorsed by the university.
          </span>
        </li>
        <li>
          <b>
            Further Prohibited Conduct.
          </b>
          <span style={{ marginLeft: 10 }}>
            You may not make any commercial use of the Site or any
            content, code, data, or materials on or available through the Site. You may not download, post,
            display, publish, copy, reproduce, distribute, transmit, perform, broadcast, create derivative
            works, sell or otherwise exploit any content, code, data, or materials on or available through the
            Site. You also agree not to alter, edit, delete, remove or otherwise modify the meaning,
            appearance, or purpose of any content, code, data or materials on or available through Site,
            including, but not limited to, altering, obscuring or removing any trademarks, trade names, logos,
            or other proprietary rights notices.
          </span>
        </li>
        <li>
          <b>
            Accounts.
          </b>
          <span style={{ marginLeft: 10 }}>
            Some areas of the Site may require you to be or become a registered user. If
            you register, you agree to provide accurate and current information. The university has the right
            to refuse or rescind your requested username for any reason, including but not limited to
            situations in which the username violates another’s rights, is illegal or misleading, is vulgar or
            otherwise offensive, impersonates another person or company, or creates confusion. It is your
            responsibility to protect your username and password from disclosure to others. Additionally,
            you are responsible for all activities that are conducted through your account.
          </span>
        </li>
        <li>
          <b>
            Termination and Account Deletion.
          </b>
          <span style={{ marginLeft: 10 }}>
            The university reserves the right to remove or
            delete any content or other information or materials submitted by users of the Site. The
            university also reserves the right to delete your account and/or monitor, restrict, block, suspend
            or discontinue your access to the Site, at any time, with or without advance notice, and for any
            reason or no reason at all. Without limiting the foregoing, reasons the university might do so
            include, but are not limited to, the following: (i) your breach of these Terms; (ii) the inability of
            the university to verify or authenticate information about you; (iii) the university believes that
            your actions could cause financial loss or legal liability to the university or others; (iv) network
            maintenance; (v) your use of the Site in a manner that violates the law or university policy; or
            (vi) your inactivity on the Site for a period of time.
          </span>
        </li>
        <li>
          <b>
            Disclosures.
          </b>
          <span style={{ marginLeft: 10 }}>
            The university may, without any permission obtained from you, disclose
            your communications and activities on the Site in response to lawful requests by governmental
            authorities, judicial orders, warrants or subpoenas, or for the protection of the university’s rights.
          </span>
        </li>
        <li>
          <b>
            Indemnification.
          </b>
          <span style={{ marginLeft: 10 }}>
            You agree to defend, indemnify and hold harmless the university and
            its content providers and service providers from any and all claims, liabilities, losses, damages,
            settlements, judgments, costs and expenses, including attorneys&#39; fees, arising in any way from
            your use of or access to the Site. The university reserves the right, at its sole expense, to assume
            the exclusive defense and control of any matter otherwise subject to indemnification by you, and
            in such case, you agree to cooperate with the university in defense of such claim.
          </span>
        </li>
        <li>
          <b>
            Disclaimer.
          </b>
          <span style={{ marginLeft: 10 }}>
            THE SITE, INCLUDING, WITHOUT LIMITATION, ALL SERVICES,
            CONTENT, FUNCTIONS, MATERIALS AND INFORMATION PROVIDED THROUGH
            THE SITE, ARE PROVIDED “AS IS,” “AS AVAILABLE,” WITHOUT WARRANTY OF
            ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,

            ANY WARRANTY FOR INFORMATION, DATA, DATA PROCESSING, UPTIME OR
            UNINTERRUPTED ACCESS, ANY WARRANTIES CONCERNING THE AVAILABILITY,
            DISPLAYABILITY, ACCURACY, PRECISION, CORRECTNESS, THOROUGHNESS,
            COMPLETENESS, USEFULNESS OR CONTENT OF INFORMATION, AND ANY
            WARRANTIES OF TITLE, NONINFRINGEMENT, MERCHANTABILITY OR FITNESS
            FOR A PARTICULAR PURPOSE, AND THE UNIVERSITY HEREBY DISCLAIMS ANY
            AND ALL SUCH WARRANTIES, EXPRESS AND IMPLIED.
          </span>
        </li>
        <li>
          <b>
            Limitation of Liability.
          </b>
          <span style={{ marginLeft: 10 }}>
            IN NO EVENT, INCLUDING BUT NOT LIMITED TO
            NEGLIGENCE, SHALL THE UNIVERSITY OF ARKANSAS, ITS BOARD OF TRUSTEES,
            OR ANY OF THE UNIVERSITY’S DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, OR
            CONTENT OR SERVICE PROVIDERS BE LIABLE FOR ANY DAMAGES WHATSOEVER
            (INCLUDING BUT NOT LIMITED TO INDIRECT, SPECIAL, INCIDENTAL,
            CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES) ARISING FROM, OR
            DIRECTLY OR INDIRECTLY RELATED TO, THE USE OF, OR THE INABILITY TO USE,
            THE SITE OR THE CONTENT, MATERIALS, OR INFORMATION RELATED THERETO,
            YOUR PROVISION OF INFORMATION VIA THE SITE, LOST BUSINESS, OR LOST
            SALES, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE.
          </span>
        </li>
        <li>
          <b>
            Governing Law, Jurisdiction, and Sovereign Immunity.
          </b>
          <span style={{ marginLeft: 10 }}>
            Any dispute, claim, or action
            related to your use, viewing, submission, or access to the Site shall be construed in accordance
            with the laws of the State of Arkansas, without regard to its conflicts of laws principles. The
            State of Arkansas shall have exclusive jurisdiction over any such dispute, claim, or action.
            Nothing in these Terms shall be deemed or construed as a waiver of the sovereign immunity of
            the State of Arkansas, or any other immunities available to the university or its trustees, officers,
            agents, and employees.
          </span>
        </li>
        <li>
          <b>
            Severability.
          </b>
          <span style={{ marginLeft: 10 }}>
            If any term, condition or provision of these Terms is found to be invalid or
            unenforceable,such invalidity or unenforceability shall not affect the remainder of the
            Terms.
          </span>
        </li>
        <li>
          <b>
            Claims of Copyright or Trademark Infringement.
          </b>
          <span style={{ marginLeft: 10 }}>
            If you believe that your
            copyrighted work or trademark has been uploaded, posted, or copied to the Site and is
            accessible on the Site in a way that constitutes copyright or trademark infringement,
            please contact the university’s Digital Millennium Copyright Act designated agent,
            currently:
            <br />
            Harold J. Evans
            <br />
            Associate Vice President, Legal and Research
            <br />
            University of Arkansas System
            <br />
            2404 North University Avenue
            <br />
            Little Rock, Arkansas 72207
            <br />
            501-686-2513
            <br />
            hevans@uasys.edu
          </span>
        </li>
        <li>
          <b>
            Contact Information.
          </b>
          <span style={{ marginLeft: 10 }}>
            For any other questions, please contact the Arkansas Teacher
            Match at arteachermatch@gmail.com
          </span>
        </li>
      </ol>
    </div>
);
}

export default TermsAndConditions;
