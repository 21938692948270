import React from 'react';
import Dialog from '@mui/material/Dialog';

function CustomModal({ isOpen, onClose, modalContent }) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <div style={{ padding: 10 }}>
        {modalContent}
      </div>
    </Dialog>
);
}

export default CustomModal;
