import React from 'react';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Skeleton from 'react-loading-skeleton';
import CustomCard from '../CustomCard/CustomCard';

function SkeletonLoadingCard() {
  return (
    <CustomCard>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5" component="h2">
          <Skeleton width={200} />
        </Typography>
        <Typography color="textSecondary">
          <Skeleton width={100} />
        </Typography>
      </div>
      <Typography color="textSecondary">
        <Skeleton width={100} />
      </Typography>
      <Typography component="p">
        <Skeleton count={4} />
      </Typography>
      <CardActions>
        <Skeleton width={200} />
      </CardActions>
    </CustomCard>
);
}

export default SkeletonLoadingCard;
