import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
    Grid,
    Card,
    CardContent,
} from '@mui/material/';
import { connect } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Avatar from 'react-avatar';
import Drawer from '@mui/material/Drawer';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Typography from '@mui/material/Typography';
import { NavDropdown } from 'react-bootstrap';
import { updateDistrictVerification } from '../../state/actions/districtSignUp';
import Theme from '../../theme/Theme';
import EnhancedTable from '../EnhancedTable/EnhancedTable';
import DashCard from '../DashCard/DashCard';
import Graph from '../Graph/Graph';
import UploadCsv from './UploadCsv';
import UpCsv from './UpCsv';

const drawerWidth = 200;

const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    table: {
    marginTop: 100,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
  }));

function ReactTable(props) {
  const resendEmail = (id) => {
    props.updateDistrictVerification(id);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Degree Level',
        accessor: 'degreeLevel',
      },
      {
        Header: 'Institution',
        accessor: 'institution',
      },
      {
        Header: 'Long Term-Sub',
        accessor: 'longTermSubstitute',
      },
    ],
    []
  );

  const jobColumns = React.useMemo(
    () => [
      {
        Header: 'District Name',
        accessor: 'districtName',
      },
      {
        Header: 'Job Title',
        accessor: 'title',
      },
      {
        Header: 'District Address',
        accessor: 'districtAddress',
      },
    ],
    []
  );

  const districtColumns = React.useMemo(
    () => [
      {
        Header: 'District Description',
        accessor: 'description',
      },
      {
        Header: 'Coordinator Name',
        accessor: 'coordinatorName',
      },
      {
        Header: 'Coordinator Email',
        accessor: 'coordinatorEmail',
      },
      {
        Header: 'Student/Teacher Ratio',
        accessor: 'studentTeacherRatio',
      },
      {
        Header: 'City',
        accessor: 'city',
      },
      {
        Header: 'State',
        accessor: 'state',
      },
    ],
    []
  );

  const signupColumns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'fullName',
      },
      {
        Header: 'Username',
        accessor: 'userName',
      },
      // TODO: This isn't part of the data anymore, it has to come from distrct
      {
        Header: 'Supt. Email (fix me)',
        accessor: 'coordinatorEmail',
      },
      {
        Header: 'Verified',
        accessor: 'isVerified',
      },
      {
        Header: 'Send Email',
        id: 'edit',
        accessor: 'firebaseAuthId',
        Cell: ({ value }) => (<button style={{ backgroundColor: 'green', color: 'white', borderRadius: 10, borderColor: 'green' }} onClick={() => resendEmail(value)}>Resend</button>)
      },
    ],
    []
  );

  const employerColumns = React.useMemo(
    () => [
      {
        Header: 'User Name',
        accessor: 'userName',
      },
      {
        Header: 'Full Name',
        accessor: 'fullName',
      },
      {
        Header: 'Verified',
        accessor: 'isVerified',
      },
      {
        Header: 'Admin',
        accessor: 'isAdmin',
      },
    ],
    []
  );

const classes = useStyles();
const [skipPageReset, setSkipPageReset] = React.useState(false);
const [checkArray, setBoolean] = React.useState(false);
const [checkArrayEmp, setEmployerSet] = React.useState(false);
const [teachersList, setTeacher] = React.useState([]);
const [jobList, setJobs] = React.useState([]);
const [districtsList, setDistricts] = React.useState([]);
const [employersList, setEmployers] = React.useState([]);
const [secEmployersList, setSecEmployers] = React.useState([]);
const [teacherTrue, setTeacherTrue] = React.useState(false);
const [dashboardTrue, setDashboardTrue] = React.useState(true);
const [jobTrue, setJobTrue] = React.useState(false);
const [districtTrue, setDistrictTrue] = React.useState(false);
const [employerTrue, setEmployerTrue] = React.useState(false);
const [csvTrue, setCsvTrue] = React.useState(false);
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  useEffect(() => {
    if (Object.values(props.allTeachers).length > 0 && (teachersList.length !== Object.values(props.allTeachers).length)) {
      setBoolean(true);
      Object.keys(props.allTeachers).map((val, k) => {
        teachersList.push(props.allTeachers[val]);
        });
    }
    if (Object.values(props.jobs).length > 0 && (jobList.length !== Object.values(props.jobs).length)) {
        setBoolean(true);
        Object.keys(props.jobs).map((val, k) => {
          jobList.push(props.jobs[val]);
          });
    }
    // 4/10/22 AB: Confusing that a prop called "district" would be used to update employersList
    if (Object.values(props.employers).length > 0 && (employersList.length !== Object.values(props.employers).length)) {
        setBoolean(true);
        Object.keys(props.employers).map((val, k) => {
          employersList.push(props.employers[val]);
          });
    }
      // 4/10/22 AB: Now have "district" (existing) and "districts" (new), which adds to the confusion
      // Perhaps "district" should be renamed, or maybe we don't really need "districts"
    if (Object.values(props.districts).length > 0 && (districtsList.length !== Object.values(props.districts).length)) {
        setBoolean(true);
        Object.keys(props.districts).map((val, k) => {
          districtsList.push(props.districts[val]);
          });
    }
    if (Object.values(props.employers).length > 0 && (secEmployersList.length !== Object.values(props.employers).length)) {
        setEmployerSet(true);
        // console.log('The employers ' + props.employers);
        Object.keys(props.employers).map((val, k) => {
          secEmployersList.push(props.employers[val]);
          });
    }
  });

  const navBar = () => {
    const history = useHistory();
    const isRound = true;
    const userName = 'Human Resources';
    const isEmployer = true;
    return (

      <NavDropdown title={<Avatar color={Theme.ThemeDanger} name={userName} size="40" round={isRound} style={{ fontWieght: 'normal' }} />} id="basic-nav-dropdown">
        <NavDropdown.Item onClick={() => history.replace('/')}>Home</NavDropdown.Item>
        <NavDropdown.Item onClick={() => history.replace(isEmployer ? '/employersettings' : 'teachersettings')}>
          Settings
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => history.replace('/searchteachers')}>Search Teachers</NavDropdown.Item>
        <NavDropdown.Item onClick={() => history.replace('/aboutus')}>About Us</NavDropdown.Item>
        <NavDropdown.Item onClick={() => history.replace('/faqs')}>FAQs</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={() => history.replace('/login')}>Logout</NavDropdown.Item>
      </NavDropdown>
    );
  };

  const updateJobs = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setJobs(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const jobsTable = () => {
    if (jobTrue === true) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />

        {
          checkArray ? (
            <EnhancedTable
              columns={jobColumns}
              data={jobList}
              setData={setJobs}
              updateMyData={updateJobs}
              skipPageReset={skipPageReset}
              isWhat="Jobs"
            />

          ) : (
            <div> ... Loading </div>
          )
        }
      </main>
    );
      }
  };
  const updateDistricts = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setDistricts(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const districtsTable = () => {
    if (districtTrue === true) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />

        {
          checkArray ? (
            <EnhancedTable
              columns={districtColumns}
              data={districtsList}
              setData={setDistricts}
              updateMyData={updateDistricts}
              skipPageReset={skipPageReset}
              isWhat="Districts"
            />

          ) : (
            <div> ... Loading </div>
          )
        }
      </main>
    );
      }
  };
  const updateEmployers = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setEmployers(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const employerTable = () => {
    if (employerTrue === true) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />

        {
          checkArray ? (
            // console.log("the render " + Object.values(allTeachers))
            <EnhancedTable
              columns={employerColumns}
              data={employersList}
              setData={setEmployers}
              updateMyData={updateEmployers}
              skipPageReset={skipPageReset}
              isWhat="Employers"
            />

          ) : (
            <div> ... Loading </div>
          )
        }
      </main>
    );
      }
  };

  const updateTeachers = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setTeacher(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const teacherTable = () => {
    if (teacherTrue === true) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />

        {
          checkArray ? (
            // console.log("the render " + Object.values(allTeachers))
            <EnhancedTable
              columns={columns}
              data={teachersList}
              setData={setTeacher}
              updateMyData={updateTeachers}
              skipPageReset={skipPageReset}
              isWhat="Teachers"
            />

          ) : (
            <div> ... Loading </div>
          )
        }
      </main>
    );
      }
  };

  const updateSecEmployers = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setTeacher(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const mainTable = () => {
    if (dashboardTrue === true) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />

        {
          checkArray ? (
            <div>
              <DashCard
                teachers={Object.values(props.allTeachers).length}
                jobs={Object.values(props.jobs).length}
                employers={Object.values(props.employers).length}
              />
              <Grid
                container
                spacing={10}
                style={{ marginTop: 15 }}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                { checkArrayEmp ?
                (
                  <Card style={{ maxWidth: 500, marginRight: 10 }}>
                    <CardContent>
                      <EnhancedTable
                        columns={signupColumns}
                        data={secEmployersList}
                        setData={setSecEmployers}
                        updateMyData={updateSecEmployers}
                        skipPageReset={skipPageReset}
                        isWhat="District Signups"
                      />
                    </CardContent>
                  </Card>
                ) : (<div>...Loading Signups</div>) }
                <Card style={{ padding: 10, maxWidth: 550 }}>
                  <Graph
                    list={teachersList}
                  />
                </Card>
              </Grid>
            </div>
          ) : (
            <div> ... Loading </div>
          )
        }
      </main>
    );
      }
  };

  const uploadFile = () => {
    if (csvTrue === true) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {/* <UploadCsv /> */}
        <UpCsv />
      </main>
    );
      }
  };
  // checks to ensure it provides right table when a button is clicked
  const tableCheck = (text, props) => {
    if (text === 'Dashboard') {
        setDashboardTrue(true);
        setTeacherTrue(false);
        setEmployerTrue(false);
        setJobTrue(false);
        setCsvTrue(false);
        setDistrictTrue(false);
    } else if (text === 'Teachers') {
      setTeacherTrue(true);
      setEmployerTrue(false);
      setDashboardTrue(false);
      setJobTrue(false);
      setCsvTrue(false);
      setDistrictTrue(false);
    } else if (text === 'Employers') {
      setTeacherTrue(false);
      setEmployerTrue(true);
      setDashboardTrue(false);
      setJobTrue(false);
      setCsvTrue(false);
      setDistrictTrue(false);
    } else if (text === 'Jobs') {
      setJobTrue(true);
      setTeacherTrue(false);
      setEmployerTrue(false);
      setDashboardTrue(false);
      setCsvTrue(false);
      setDistrictTrue(false);
    } else if (text === 'Upload CSV') {
      setCsvTrue(true);
      setJobTrue(false);
      setTeacherTrue(false);
      setEmployerTrue(false);
      setDashboardTrue(false);
      setDistrictTrue(false);
    //   getCsv(props.allTeachers);
    } else if (text === 'Add New Data') {
      console.log('In add data');
    //   modal();
    } else if (text === 'Districts') {
      setTeacherTrue(false);
      setEmployerTrue(false);
      setDashboardTrue(false);
      setJobTrue(false);
      setCsvTrue(false);
      setDistrictTrue(true);
    }
  };
  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            Dashboard - ARteachers
          </Typography>
          {navBar()}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.toolbar} />
        <List>
          {['Dashboard', 'Teachers', 'Employers', 'Jobs', 'Districts', 'Upload CSV'].map((text, index) => (
            <ListItem button key={text} onClick={() => { tableCheck(text, props); }}>
              <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      {mainTable()}
      {teacherTable()}
      {employerTable()}
      {jobsTable()}
      {districtsTable()}
      {uploadFile()}
    </div>
 );
}

const mapStateToProps = state => ({
  districtSignup: state.districtSignup,
});

export default connect(
  mapStateToProps,
  { updateDistrictVerification }
)(ReactTable);
