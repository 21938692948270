import * as types from '../actions/types';

const INITIAL_STATE = {
    teacherResumes: null,
    gettingTeacherRes: false,
    uploadError: null,
};

// 0: id
// 1: docid (firebase)
// 2: filename
// 3: downloadURL
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_TEACHER_RESUME:
            return { ...state, gettingTeacherRes: true };
        case types.GET_TEACHER_RESUME_SUCCESS:
            return { ...state, teacherResumes: action.payload, gettingTeacherRes: false };
        case types.GET_TEACHER_RESUME_FAIL:
            return { ...state, gettingTeacherRes: false };
        case types.UPLOAD_TEACHER_RESUME_SUCCESS: {
            const resumes = state.teacherResumes.slice();
            resumes.push(action.payload)
            return { ...state, teacherResumes: resumes, uploadError: false };
        }
        case types.UPLOAD_TEACHER_RESUME_FAIL: {
            return { ...state, uploadError: true };
        }
        case types.RENAME_TEACHER_RESUME: {
            // payload here is: {docId, newName}
            const resumes = state.teacherResumes.slice();
            return { ...state, teacherResumes: resumes.map( doc => {
                // payload.docId is the uuid making up the filename on Firebase
                // that value is stored in state in index 0 of the array
                // array index 2 in the array in state is the friendly name of the file
                if (doc[1] == action.payload.docId) {
                    doc[2] = action.payload.newName
                }
                return doc
            })}
        }
        default:
            return state;
    }
};
