import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Desktop, Tablet, Mobile } from '../ResponsiveUtilities/BreakPoint';

function CustomCard({ children, customStyle = {}, onClick = () => { } }) {
  return (
    <div>
      <Desktop>
        <Card
          onClick={onClick}
          style={{ margin: 20, width: 700, ...customStyle }}
        >
          <CardContent>
            {children}
          </CardContent>
        </Card>
      </Desktop>

      <Tablet>
        <Card
          onClick={onClick}
          style={{ margin: 20, width: 550, ...customStyle }}
        >
          <CardContent>
            {children}
          </CardContent>
        </Card>
      </Tablet>
      <Mobile>
        <Card
          onClick={onClick}
          style={{ margin: 20, width: 300, ...customStyle }}
        >
          <CardContent>
            {children}
          </CardContent>
        </Card>
      </Mobile>
    </div>
  );
}

export default CustomCard;
