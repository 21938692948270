import * as types from '../actions/types';

const INITIAL_STATE = {
  showNewJobAlert: false,
  showNewQuestionAlert: false,
  showDeleteQuestionsAlert: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SHOW_NEW_JOB_POSTED_ALERT:
      return { ...state, showNewJobAlert: true };
    case types.HIDE_NEW_JOB_POSTED_ALERT:
      return { ...state, showNewJobAlert: false };
    case types.ADD_EMPLOYER_QUESTION_SUCCESS_SHOW_ALERT:
      return { ...state, showNewQuestionAlert: true };
    case types.HIDE_NEW_QUESTION_ALERT:
      return { ...state, showNewQuestionAlert: false };
    case types.DELETE_QUESTIONS_SUCCESS_SHOW_ALERT:
      return { ...state, showDeleteQuestionsAlert: true };
    case types.HIDE_DELETE_QUESTIONS_ALERT:
      return { ...state, showDeleteQuestionsAlert: false };
    default:
      return state;
  }
};
