import django from '../../apis/django';
import store from '..';
import * as types from './types';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

export const getDistrictByLEA = async lea => {
    try {
      // const config = await getRequestConfig();
      const response = await django.get(`/districts/lea/${lea}/`);
      return response.data;
    } catch (err) {
      console.error(err);
      console.error('There was a problem looking up a district by the provided LEA');
      return null;
    }
};

export const getDistrict = async id => {
  try {
    const config = await getRequestConfig();
    const response = await django.get(`/districts/id/${id}/`, config);
    return response.data;
  } catch (err) {
    console.error(err);
    console.error('There was a problem looking up a district by the provided ID');
    return null;
  }
};

export const getAndSaveDistrictByLEA = async lea => {
    const payload = await getDistrictByLEA(lea);
    store.dispatch({ type: types.GET_EXTERNAL_EMPLOYER_DATA, payload });
};

export const getAndSaveDistrict = async id => {
  const payload = await getDistrict(id);
  store.dispatch({ type: types.GET_EXTERNAL_EMPLOYER_DATA, payload });
};

export const getDistricts = () => async () => {
    let districts = [];
    try {
      const config = await getRequestConfig();
      const response = await django.get('/districts/id/', config);
      districts = response.data;
      store.dispatch({ type: types.GET_DISTRICTS_SUCCESS, payload: response.data });
    } catch (err) {
      console.error(err);
      console.error('There was a problem getting the districts');
    }

    return districts;
};

// AB 4/24/22 It seems that getDistrictData is just getDistricts without dispatching
// anything. I don't yet understand why you would want to do the latter. This
// function may just get replaced by getDistricts.
export const getDistrictData = () => async () => {
    let districts = [];
    try {
      const config = await getRequestConfig();
        const response = await django.get('/districts/id/', config);
        districts = response.data;

        // AB 4/24/22 This is pointless, it manually builds what is already returned
        // by the call to the API (previously Firebase)
        // userData.forEach(doc => {
        //     districts.push(doc.data());
        //     // console.log("the data is " + doc.data().city);
        //     // console.log("the data is tea " + teachers[doc.id].lastName);
        //   });
          // AB 4/24/22 dispatching GET_DISTRICT_DATA does nothing, there is no
          // reducer for it. Comment it out.
          // store.dispatch({ type: types.GET_DISTRICT_DATA, payload: districts });
    } catch (err) {
        console.error(err);
        console.error('There was a problem getting the district data');
    }

    return districts;
};
