import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { hideNewQuestionAddedAlert } from '../../state/actions/alerts';

const styles = {
  alertStyle: {
    margin: '5px',
  },
};

class NewQuestionAddedAlert extends Component {
  handleDismiss = () => {
    this.props.hideNewQuestionAddedAlert();
  };

  render() {
    if (!this.props.shown) {
      return <div />;
    }
    return (
      <Alert
        variant="success"
        onClose={this.handleDismiss}
        style={styles.alertStyle}
        dismissible
      >
        <strong>Question added succesfully</strong>
      </Alert>
    );
  }
}

const mapStateToProps = state => ({
  shown: state.alerts.showNewQuestionAlert,
});

export default connect(
  mapStateToProps,
  {
    hideNewQuestionAddedAlert,
  }
)(NewQuestionAddedAlert);
