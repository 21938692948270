import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography/Typography';
import CustomCard from '../../components/CustomCard/CustomCard';
import Theme from '../../theme/Theme';

class TeacherAlertCard extends Component {
  state = {}

  render() {
    const { userData, referenceLetters, transcripts, resumes, gettingTeacherRL, gettingTeacherRes, gettingTeacherTrans } = this.props;

    if (userData && !userData.hasCompletedSignup) {
      return (
        <CustomCard
          onClick={() => this.props.history.push('/newteachersetup')}
          customStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            backgroundColor: Theme.ThemePrimary
          }}
        >
          <div>
            <Typography
              variant="h6"
              style={{ color: 'white' }}
            >
              Click here to finish your setup
            </Typography>
          </div>
        </CustomCard>
      );
    }
    if ((referenceLetters == null || resumes == null || transcripts == null || referenceLetters?.length === 0 || resumes?.length === 0 || transcripts?.length === 0) && (!gettingTeacherRL && !gettingTeacherRes && !gettingTeacherTrans) && userData) {
      const labelValues = {
        resumes: 'Resume',
        transcripts: 'Transcript',
        referenceLetters: 'Reference Letter',
      };
      const documentsToUpload = Object.keys(labelValues).filter(key => this.props[key] == null || this.props[key].length === 0);
      let documentsToUploadText = '';

      documentsToUpload.forEach(key => {
        documentsToUploadText = documentsToUploadText.concat(`, ${labelValues[key]}`);
      });
      documentsToUploadText = documentsToUploadText.slice(2);

      return (
        <CustomCard
          onClick={() => this.props.history.push('/teachersettings')}
          customStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            backgroundColor: Theme.ThemePrimary
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography
              variant="h6"
              style={{ color: 'white' }}
            >
              Please upload your documents to apply to jobs
            </Typography>
            <Typography
              variant="h6"
              style={{ color: 'white' }}
            >
              Missing documents: {documentsToUploadText}
            </Typography>
          </div>
        </CustomCard>
      );
    }
    return null;
  };
}


const mapStateToProps = state => ({
  state,
  user: state.auth.user, // user.uid is user id
  userData: state.auth.userData,
  referenceLetters: state.teacherRefLetters.teacherRefLetters,
  resumes: state.teacherResumes.teacherResumes,
  transcripts: state.teacherTranscripts.teacherTranscripts,
  gettingTeacherRL: state.teacherRefLetters.gettingTeacherRL,
  gettingTeacherRes: state.teacherResumes.gettingTeacherRes,
  gettingTeacherTrans: state.teacherTranscripts.gettingTeacherTrans,
});
  
export default withRouter(connect(mapStateToProps)(TeacherAlertCard));