import React from 'react';
import Select from 'react-select';

function CustomSelect({
  onChange,
  onBlur,
  name,
  value,
  defaultValue,
  options,
  isMulti = false,
  isInvalid = false,
  isDisabled = false
}) {
  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: isInvalid ? 'red' : '#ddd',
      '&:hover': {
        borderColor: isInvalid ? 'red' : '#ddd',
      }
    })
  };

  return (
    <Select
      options={options}
      onChange={(val) => {
        if (isMulti) {
          onChange(name, val);
        } else {
          onChange(name, val.value);
        }
      }}
      onBlur={() => onBlur(name, true)}
      value={value}
      isMulti={isMulti}
      styles={customStyles}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
    />
  );
}

export default CustomSelect;
