import { auth } from '../../firebase/Firebase';
import django from '../../apis/django';
import * as types from './types';
import { getTeacher } from '../../state/actions/auth';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

// In the future, set up firstname/lastname
const createTeacherSuccess = async (dispatch, user, firstName, lastName, email) => {
  const teacher = {
    firstName,
    lastName,
    firebaseAuthId: user.uid,
    email,
    hasCompletedSignup: false,
  };

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    // AB 9/20/22 When done with testing, this should be arteachers.org
    url: 'https://localhost:3000',
    // This must be true.
    handleCodeInApp: true,
  };
  auth.sendSignInLinkToEmail(email, actionCodeSettings)
  // AB 9/20/22 Remove copy-paste useless sample code from
  // https://firebase.google.com/docs/auth/web/email-link-auth
  // .then(() => {
  //   // The link was successfully sent. Inform the user.
  //   // Save the email locally so you don't need to ask the user for it again
  //   // if they open the link on the same device.
  //   window.localStorage.setItem('emailForSignIn', email);
  // })
  .catch((error) => {
    // Some error occurred, you can inspect the code: error.code
    console.log(error);
  });

  const config = await getRequestConfig();

  django.post('/teachers/', teacher, config)
    .then(() => {
      getTeacher();
    })
    .then(() => {
      dispatch({ type: types.LOGIN_USER_SUCCESS, payload: user });
    })
    .catch(error => console.log(error));
};

export const createTeacher = formValues => dispatch => {
  dispatch({ type: types.CREATE_TEACHER });
  // TODO: Handle errors and reset the form
    // AB 9/20/22 This is the part that creates the user in Firebase
    // https://firebase.google.com/docs/auth/web/password-auth
    auth
    .createUserWithEmailAndPassword(formValues.email, formValues.password)
    // AB 9/20/22 Now that the user is created in Firebase, we can add
    // them to the backend.
    // See https://firebase.google.com/docs/reference/js/auth.usercredential.md#usercredential_interface
    // to learn about what is returned by createUserWithEmailAndPassword
    .then(userCredential =>
      createTeacherSuccess(
        dispatch,
        userCredential.user,
        formValues.firstName,
        formValues.lastName,
        formValues.email
      )
    )
    .catch(error => console.log(error));
};
