import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import Avatar from 'react-avatar';
import styles from './PageLayout.styles';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import CustomButton from '../../components/CustomButton/CustomButton';
import logo from '../../assets/logo_horiz.png';
import Theme from '../../theme/Theme';
import { logoutUser } from '../../state/actions/auth';
import { Desktop, Tablet, Mobile } from '../../components/ResponsiveUtilities/BreakPoint';

class PageLayout extends Component {
   constructor(props) {
     super(props);
     this.state = {
       show: false,
       admin: false,
     };
   }

   componentDidMount() {
    //  console.log(`admin ${ this.props.isAdmin}`);
     this.setState({ admin: this.props.isAdmin });
   }

   componentDidUpdate(previousProps) {
     if (previousProps.isAdmin !== this.props.isAdmin) {
     this.setState({ admin: this.props.isAdmin });
     }
   }

  handleUserLoginClick = () => {
    if (this.props.user) {
      this.props.logoutUser();
      this.props.history.replace('/');
    } else {
      this.props.history.replace('/login');
    }
  };

  reloadPage = () => {
    window.location.reload();
  };

  showDropdown = (userData, isEmployer, isAdmin, history) => {
    let userName;
    let privileges;
    const isRound = true;
    if (userData || isEmployer) {
      if (userData) {
        userName = `${userData.firstName}`;
      }
      if (isEmployer) {
        userName = 'Human Resource';
      }
      if (this.state.admin) {
        privileges = 'Admin';
      }
      // console.log("the admin is " + privileges);
      if (userName === 'Human Resource' && privileges === 'Admin') {
        return (
          <NavDropdown title={<Avatar color={Theme.ThemeDanger} name={userName} size="40" round={isRound} style={{ fontWeight: 'normal' }} />} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => history.replace(isEmployer ? '/employersettings' : 'teachersettings')}>
              Settings
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => history.replace('/searchteachers')}>Search Teachers</NavDropdown.Item>
            <NavDropdown.Item onClick={() => history.replace('/aboutus')}>About Us</NavDropdown.Item>
            <NavDropdown.Item onClick={() => history.replace('/faqs')}>FAQs</NavDropdown.Item>
            <NavDropdown.Divider />
            {/* disable for production */}
            <NavDropdown.Item onClick={() => history.replace('/dashboard')}>Dashboard</NavDropdown.Item>
            <NavDropdown.Item onClick={this.handleUserLoginClick}>Logout</NavDropdown.Item>
          </NavDropdown>
        );
      } if (userName === 'Human Resource') {
        return (
          <NavDropdown title={<Avatar color={Theme.ThemeDanger} name={userName} size="40" round={isRound} style={{ fontWeight: 'normal' }} />} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => history.replace(isEmployer ? '/employersettings' : 'teachersettings')}>
              Settings
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => history.replace('/searchteachers')}>Search Teachers</NavDropdown.Item>
            <NavDropdown.Item onClick={() => history.replace('/aboutus')}>About Us</NavDropdown.Item>
            <NavDropdown.Item onClick={() => history.replace('/faqs')}>FAQs</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={this.handleUserLoginClick}>Logout</NavDropdown.Item>
          </NavDropdown>
        );
      }

        return (
          <NavDropdown title={<Avatar color={Theme.ThemeDanger} name={userName} size="40" round={isRound} style={{ fontWeight: 'normal' }} />} id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => history.replace(isEmployer ? '/employersettings' : 'teachersettings')}>
              Settings
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => history.replace(isEmployer ? '' : '/applied')}>
              Jobs Applied For
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => history.replace('/aboutus')}>About Us</NavDropdown.Item>
            <NavDropdown.Item onClick={() => history.replace('/faqs')}>FAQs</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={this.handleUserLoginClick}>Logout</NavDropdown.Item>
          </NavDropdown>
        );
    }
  }

  showLogin = (userData, isEmployer) => {
    if (!userData && !isEmployer) {
      return (
        <CustomButton
          onClick={this.handleUserLoginClick}
          variant="danger-contained"
        >
          {this.props.user ? 'Logout' : 'Login'}
        </CustomButton>
      );
    }
  }

  showFaqs = (userData, history, isEmployer) => {
    if (!userData && !isEmployer) {
      return (
        <CustomButton
          onClick={() => history.replace('/faqs')}
          variant="no-outline"
        >
          FAQs
        </CustomButton>
      );
    }
  }

  showAboutUs = (userData, history, isEmployer) => {
    if (!userData && !isEmployer) {
      return (
        <CustomButton
          onClick={() => history.replace('/aboutus')}
          variant="no-outline"
        >
          About Us
        </CustomButton>
      );
    }
  }

  render() {
    const { isEmployer, history, isAdmin, userData, children } = this.props;
    const show = true;
    return (
      <div style={{ ...styles.container, ...this.props.customStyle }}>
        <Desktop>
          <Header
            onClick={() => history.replace(isEmployer ? '/postings' : '/')}
          >
            {this.showDropdown(userData, isEmployer, isAdmin, history)}

            <CustomButton
              onClick={() => history.replace(isEmployer ? '/postings' : '/')}
              variant="no-outline"
            >
              Home
            </CustomButton>
            {this.showAboutUs(userData, history, isEmployer)}
            {this.showFaqs(userData, history, isEmployer)}
            {this.showLogin(userData, isEmployer)}

          </Header>
        </Desktop>

        <Mobile>
          <Header
            onClick={() => history.replace(isEmployer ? '/postings' : '/')}
          >

            <img
              src={logo}
              alt="University of Arkansas"
              style={{
              height: 75,
              alignSelf: 'center',
              margin: 25,
              cursor: 'pointer',
            }}
            />
            {this.showDropdown(userData, isEmployer, isAdmin, history)}
          </Header>

          <CustomButton
            onClick={() => history.replace(isEmployer ? '/postings' : '/')}
            variant="no-outline"
          >
            Home
          </CustomButton>
          {this.showAboutUs(userData, history, isEmployer)}
          {this.showFaqs(userData, history, isEmployer)}
          {this.showLogin(userData, isEmployer)}
        </Mobile>

        <Tablet>
          <Header
            onClick={() => history.replace(isEmployer ? '/postings' : '/')}
          >
            {this.showDropdown(userData, isEmployer, isAdmin, history)}

            <CustomButton
              onClick={() => history.replace(isEmployer ? '/postings' : '/')}
              variant="no-outline"
            >
              Home
            </CustomButton>
            {this.showAboutUs(userData, history, isEmployer)}
            {this.showFaqs(userData, history, isEmployer)}
            {this.showLogin(userData, isEmployer)}

          </Header>
        </Tablet>

        <div style={styles.childrenContainer}>
          {children}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => (
  {
  user: auth.user,
  userData: auth.userData,
  isEmployer: auth.userIsEmployer,
  isAdmin: auth.userIsAdmin
});

export default withRouter(
  connect(
    mapStateToProps,
    { logoutUser }
  )(PageLayout)
);
