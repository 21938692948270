import * as types from '../actions/types';

const INITIAL_STATE = {
    districts: null,
    gettingDistricts: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_DISTRICTS:
            return { ...state, gettingDistricts: true };
        case types.GET_DISTRICTS_SUCCESS:
            return { ...state, districts: action.payload, gettingDistricts: false };
        case types.GET_DISTRICTS_FAIL:
            return { ...state, gettingDistricts: false };
        default:
            return state;
    }
};
