import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const options = [
  {
    label: 'Standard or Provisional Licensure Areas',
    options: [
      {
        value: 'adulted',
        label: 'ADULT EDUCATION - POST SECONDARY',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'agriculture',
        label: 'AGRICULTURE - 7th-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'art',
        label: 'ART - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'biolifesci',
        label: 'BIOLOGY/LIFE SCIENCE - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'bustech',
        label: 'BUSINESS TECHNOLOGY - 4-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'chem',
        label: 'CHEMISTRY - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'coaching',
        label: 'COACHING - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'compsci',
        label: 'COMPUTER SCIENCE - 4-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'contentspecialist',
        label:
          'CONTENT SPECIALIST - MATH, SCIENCE, AND LITERACY FOR TEACHERS HOLDING A K-6 LICENSE',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'dance',
        label: 'DANCE - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'drama',
        label: 'DRAMA - 7-12',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'dramaspeech',
        label: 'DRAMA / SPEECH - K-12',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'dyslexia',
        label: 'DYSLEXIA - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'earlychildhood',
        label: 'EARLY CHILDHOOD/SPECIAL EDUCATION INTEGRATED - B-K',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'earthsci',
        label: 'EARTH SCIENCE - 7-12h',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'edexaminer',
        label: 'EDUCATIONAL EXAMINER - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'elementary',
        label: 'ELEMENTARY - K-6th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'esol',
        label: 'ENGLISH FOR SPEAKERS OF OTHER LANGUAGES - ESOL',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'langartsmid',
        label: 'ENGLISH LANGUAGE ARTS - MIDDLE LEVEL - 4-8th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'langartssecondary',
        label: 'ENGLISH LANGUAGE ARTS - SECONDARY - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'famconsumersciences',
        label: 'FAMILY & CONSUMER SCIENCES - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'forlang',
        label: 'FOREIGN LANGUAGES - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'forlang',
        label: 'FOREIGN LANGUAGES - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'forlangasl',
        label: 'FOREIGN LANGUAGES - AMERICAN SIGN LANGUAGE - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'gats',
        label: 'GIFTED & TALENTED - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'guidance',
        label: 'GUIDANCE & SCHOOL COUNSELING - K-12h',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'industrial',
        label: 'INDUSTRIAL TECHNOLOGY - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'facilitator',
        label: 'INSTRUCTIONAL FACILITATOR - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'band',
        label: 'INSTRUMENTAL MUSIC - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'journalism',
        label: 'JOURNALISM - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'library',
        label: 'LIBRARY MEDIA SPECIALIST - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'marketing',
        label: 'MARKETING TECHNOLOGY - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'mathmid',
        label: 'MATHEMATICS - MIDDLE LEVEL - 4-8th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'mathsecondary',
        label: 'MATHEMATICS - SECONDARY - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'online',
        label: 'ONLINE TEACHING - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'healthpe',
        label: 'PE & HEALTH - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'physsci',
        label: 'PHYSICAL SCIENCE - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'physics',
        label: 'PHYSICS - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'physicsmath',
        label: 'PHYSICS/MATHEMATICS - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'reading',
        label: 'READING SPECIALIST - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'sciencemid',
        label: 'SCIENCE - MIDDLE LEVEL - 4-8th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'socialstudiesmid',
        label: 'SOCIAL STUDIES - MIDDLE LEVEL - 4-8th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'socialstudiessecondary',
        label: 'SOCIAL STUDIES - SECONDARY - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'specialed',
        label: 'SPECIAL EDUCATION - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'specialedhearing',
        label: 'SPECIAL EDUCATION - HEARING SPECIALIST - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'specialedvisual',
        label: 'SPECIAL EDUCATION - VISUAL SPECIALIST - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'specialedelementary',
        label: 'SPECIAL EDUCATION RESOUCE - ELEMENTARY - K-6th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'specialedlangarts',
        label: 'SPECIAL EDUCATION RESOURCE - ENGLISH LANGUAGE ARTS - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'specialedmath',
        label: 'SPECIAL EDUCATION RESOURCE - MATH - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'specialedscience',
        label: 'SPECIAL EDUCATION RESOURCE - SCIENCE - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'speech',
        label: 'SPEECH - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'choir',
        label: 'VOCAL MUSIC - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
    ],
  },
  {
    label: 'Leadership Licensure Areas',
    options: [
      {
        value: 'buildingadmin',
        label: 'BUILDING-LEVEL ADMINISTRATOR - P-12th',
        group: 'Leadership Licensure Areas',
      },
      {
        value: 'curriculumprogramadmin',
        label: 'CURRICULUM/PROGRAM ADMINISTRATOR - P-12th',
        group: 'Leadership Licensure Areas',
      },
      {
        value: 'curriculumprogramadminadulted',
        label: 'CURRICULUM/PROGRAM ADMINISTRATOR - ADULT EDUCATION - P-12th',
        group: 'Leadership Licensure Areas',
      },
      {
        value: 'curriculumprogramadmincareertech',
        label: 'CURRICULUM/PROGRAM ADMINISTRATOR - CAREER TECH ED - P-12th',
        group: 'Leadership Licensure Areas',
      },
      {
        value: 'curriculumprogramadmincontentspecialist',
        label: 'CURRICULUM/PROGRAM ADMINISTRATOR - CONTENT SPECIALIST - P-12th',
        group: 'Leadership Licensure Areas',
      },
      {
        value: 'curriculumprogramadmingats',
        label: 'CURRICULUM/PROGRAM ADMINISTRATOR - GIFTED & TALENTED - P-12th',
        group: 'Leadership Licensure Areas',
      },
      {
        value: 'curriculumprogramadminspecialed',
        label: 'CURRICULUM/PROGRAM ADMINISTRATOR - SPECIAL EDUCATION - P-12th',
        group: 'Leadership Licensure Areas',
      },
      {
        value: 'districtleveladmin',
        label: 'DISTRICT-LEVEL ADMINISTRATOR - P-12th',
        group: 'Leadership Licensure Areas',
      },
    ],
  },
  {
    label: 'Endorsement Areas',
    options: [
      {
        value: 'age34endorsement',
        label:
          'AGE 3-4 ENDORSEMENT - FOR TEACHERS HOLDING AN ELEMENTARY K-6 LICENSE',
        group: 'Endorsement Areas',
      },
      {
        value: 'age34specialedendorsement',
        label:
          'AGE 3-4 SPECIAL EDUCATION ENDORSEMENT - FOR TEACHERS HOLDING AN ELEMENTARY K-6 SPEACIAL EDUCATION LICENSE',
        group: 'Endorsement Areas',
      },
      {
        value: 'grade56endorsement',
        label:
          'GRADE 5-6 ENDORSEMENT - FOR 7-12 TEACHER LICENSED IN MATH, SCIENCE, LANG ARTS OR SOCIAL STUDIES',
        group: 'Endorsement Areas',
      },
    ],
  },
  {
    label: 'Ancilliary Licensure Areas',
    options: [
      {
        value: 'psychologyspecialist',
        label: 'SCHOOL PSYCHOLOGY SPECIALIST - K-12th',
        group: 'Ancilliary Licensure Areas',
      },
      {
        value: 'pathology',
        label: 'SPEECH LANGUAGE PATHOLOGY - K-12th',
        group: 'Ancilliary Licensure Areas',
      },
      {
        value: 'dyslexiaancilliary',
        label: 'DYSLEXIA - K-12th',
        group: 'Ancilliary Licensure Areas',
      },
      {
        value: 'esolancilliary',
        label: 'ENGLISH FOR SPEAKERS OF OTHER LANGUAGES - ESOL',
        group: 'Ancilliary Licensure Areas',
      },
    ],
  },
];

class LicensureSelect extends Component {
  render() {
    return (
      <Select
        defaultValue={this.props.defaultValue}
        components={makeAnimated()}
        onChange={this.props.onChange}
        options={options}
        isMulti
      />
    );
  }
}

export default LicensureSelect;
