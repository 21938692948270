export const trueFalseOptions = [
  {
    value: false,
    label: 'No',
  },
  {
    value: true,
    label: 'Yes',
  },
];

export const degreeOptions = [
  {
    value: 'bachelor',
    label: 'Bachelor',
  },
  {
    value: 'master',
    label: 'Master',
  },
  {
    value: 'specialist',
    label: 'Specialist',
  },
  {
    value: 'doctoral',
    label: 'Doctoral',
  },
];

export const hoursOptions = [
  {
    value: '0',
    label: '0',
  }, {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  }, {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  }, {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  }, {
    value: '7',
    label: '7',
  },
  {
    value: '8',
    label: '8',
  }, {
    value: '9',
    label: '9',
  },
  {
    value: '10',
    label: '10',
  }, {
    value: '11',
    label: '11',
  },
  {
    value: '12',
    label: '12',
  }, {
    value: '13',
    label: '13',
  },
  {
    value: '14',
    label: '14',
  }, {
    value: '15',
    label: '15',
  },
  {
    value: '16',
    label: '16',
  }, {
    value: '17',
    label: '17',
  },
  {
    value: '18',
    label: '18',
  }, {
    value: '19',
    label: '19',
  },
  {
    value: '20',
    label: '20',
  }, {
    value: '21',
    label: '21',
  },
  {
    value: '22',
    label: '22',
  }, {
    value: '23',
    label: '23',
  },
  {
    value: '24',
    label: '24',
  }, {
    value: '25',
    label: '25',
  },
  {
    value: '26',
    label: '26',
  }, {
    value: '27',
    label: '27',
  },
  {
    value: '28',
    label: '28',
  }, {
    value: '29',
    label: '29',
  },
  {
    value: '30',
    label: '30',
  }, {
    value: '31',
    label: '31',
  },
  {
    value: '32',
    label: '32',
  }, {
    value: '33',
    label: '33',
  },
  {
    value: '34',
    label: '34',
  }, {
    value: '35',
    label: '35',
  },
  {
    value: '36',
    label: '36',
  }, {
    value: '37',
    label: '37',
  },
  {
    value: '38',
    label: '38',
  }, {
    value: '39',
    label: '39',
  },
  {
    value: '40',
    label: '40',
  }, {
    value: '41',
    label: '41',
  },
  {
    value: '42',
    label: '42',
  }, {
    value: '43',
    label: '43',
  },
  {
    value: '44',
    label: '44',
  }, {
    value: '45',
    label: '45',
  },
  {
    value: '46',
    label: '46',
  }, {
    value: '47',
    label: '47',
  },
  {
    value: '48',
    label: '48',
  }, {
    value: '49',
    label: '49',
  },
  {
    value: '50',
    label: '50',
  }, {
    value: '51',
    label: '51',
  },
  {
    value: '52',
    label: '52',
  }, {
    value: '53',
    label: '53',
  },
  {
    value: '54',
    label: '54',
  }, {
    value: '55',
    label: '55',
  },
  {
    value: '56',
    label: '56',
  }, {
    value: '57',
    label: '57',
  },
  {
    value: '58',
    label: '58',
  }, {
    value: '59',
    label: '59',
  },
  {
    value: '60',
    label: '60',
  }, {
    value: '61',
    label: '61',
  },
  {
    value: '62',
    label: '62',
  }, {
    value: '63',
    label: '63',
  },
  {
    value: '64',
    label: '64',
  }, {
    value: '65',
    label: '65',
  },
  {
    value: '66',
    label: '66',
  }, {
    value: '67',
    label: '67',
  },
  {
    value: '68',
    label: '68',
  }, {
    value: '69',
    label: '69',
  },
  {
    value: '70',
    label: '70',
  }, {
    value: '71',
    label: '71',
  },
  {
    value: '72',
    label: '72',
  }, {
    value: '73',
    label: '73',
  },
  {
    value: '74',
    label: '74',
  }, {
    value: '75',
    label: '75',
  },
  {
    value: '76',
    label: '76',
  }, {
    value: '77',
    label: '77',
  },
  {
    value: '78',
    label: '78',
  }, {
    value: '79',
    label: '79',
  },
  {
    value: '80',
    label: '80',
  }, {
    value: '81',
    label: '81',
  },
  {
    value: '82',
    label: '82',
  }, {
    value: '83',
    label: '83',
  },
  {
    value: '84',
    label: '84',
  }, {
    value: '85',
    label: '85',
  },
  {
    value: '86',
    label: '86',
  }, {
    value: '87',
    label: '87',
  },
  {
    value: '88',
    label: '88',
  }, {
    value: '89',
    label: '89',
  },
  {
    value: '90',
    label: '90',
  }, {
    value: '91',
    label: '91',
  },
  {
    value: '92',
    label: '92',
  }, {
    value: '93',
    label: '93',
  },
  {
    value: '94',
    label: '94',
  }, {
    value: '95',
    label: '95',
  },
  {
    value: '96',
    label: '96',
  }, {
    value: '97',
    label: '97',
  },
  {
    value: '98',
    label: '98',
  }, {
    value: '99',
    label: '99',
  },
  {
    value: '100',
    label: '100',
  },
];

export const licenseTypeOptions = [
  {
    value: 'standard',
    label: 'Standard',
  },
  {
    value: 'lifeTime',
    label: 'Life Time',
  },
  {
    value: 'provisional',
    label: 'Provisional',
  },
];

export const timeOptions = [
  {
    value: 'fullTime',
    label: 'Full Time',
  },
  {
    value: 'partTime',
    label: 'Part Time',
  },
];

export const licensureOptions = [
  {
    label: 'Standard or Provisional Licensure Areas',
    options: [
      {
        value: 'adulted',
        label: 'ADULT EDUCATION - POST SECONDARY',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'agriculture',
        label: 'AGRICULTURE - 7th-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'art',
        label: 'ART - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'biolifesci',
        label: 'BIOLOGY/LIFE SCIENCE - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'bustech',
        label: 'BUSINESS TECHNOLOGY - 4-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'chem',
        label: 'CHEMISTRY - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'coaching',
        label: 'COACHING - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'compsci',
        label: 'COMPUTER SCIENCE - 4-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'contentspecialist',
        label:
          'CONTENT SPECIALIST - MATH, SCIENCE, AND LITERACY FOR TEACHERS HOLDING A K-6 LICENSE',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'dance',
        label: 'DANCE - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'drama',
        label: 'DRAMA - 7-12',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'dramaspeech',
        label: 'DRAMA / SPEECH - K-12',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'dyslexia',
        label: 'DYSLEXIA - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'earlychildhood',
        label: 'EARLY CHILDHOOD/SPECIAL EDUCATION INTEGRATED - B-K',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'earthsci',
        label: 'EARTH SCIENCE - 7-12h',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'edexaminer',
        label: 'EDUCATIONAL EXAMINER - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'elementary',
        label: 'ELEMENTARY - K-6th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'esol',
        label: 'ENGLISH FOR SPEAKERS OF OTHER LANGUAGES - ESOL',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'langartsmid',
        label: 'ENGLISH LANGUAGE ARTS - MIDDLE LEVEL - 4-8th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'langartssecondary',
        label: 'ENGLISH LANGUAGE ARTS - SECONDARY - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'famconsumersciences',
        label: 'FAMILY & CONSUMER SCIENCES - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'forlang',
        label: 'FOREIGN LANGUAGES - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'forlangasl',
        label: 'FOREIGN LANGUAGES - AMERICAN SIGN LANGUAGE - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'gats',
        label: 'GIFTED & TALENTED - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'guidance',
        label: 'GUIDANCE & SCHOOL COUNSELING - K-12h',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'industrial',
        label: 'INDUSTRIAL TECHNOLOGY - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'facilitator',
        label: 'INSTRUCTIONAL FACILITATOR - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'band',
        label: 'INSTRUMENTAL MUSIC - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'journalism',
        label: 'JOURNALISM - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'library',
        label: 'LIBRARY MEDIA SPECIALIST - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'marketing',
        label: 'MARKETING TECHNOLOGY - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'mathmid',
        label: 'MATHEMATICS - MIDDLE LEVEL - 4-8th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'mathsecondary',
        label: 'MATHEMATICS - SECONDARY - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'online',
        label: 'ONLINE TEACHING - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'healthpe',
        label: 'PE & HEALTH - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'physsci',
        label: 'PHYSICAL SCIENCE - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'physics',
        label: 'PHYSICS - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'physicsmath',
        label: 'PHYSICS/MATHEMATICS - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'reading',
        label: 'READING SPECIALIST - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'sciencemid',
        label: 'SCIENCE - MIDDLE LEVEL - 4-8th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'socialstudiesmid',
        label: 'SOCIAL STUDIES - MIDDLE LEVEL - 4-8th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'socialstudiessecondary',
        label: 'SOCIAL STUDIES - SECONDARY - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'specialed',
        label: 'SPECIAL EDUCATION - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'specialedhearing',
        label: 'SPECIAL EDUCATION - HEARING SPECIALIST - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'specialedvisual',
        label: 'SPECIAL EDUCATION - VISUAL SPECIALIST - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'specialedelementary',
        label: 'SPECIAL EDUCATION RESOUCE - ELEMENTARY - K-6th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'specialedlangarts',
        label: 'SPECIAL EDUCATION RESOURCE - ENGLISH LANGUAGE ARTS - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'specialedmath',
        label: 'SPECIAL EDUCATION RESOURCE - MATH - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'specialedscience',
        label: 'SPECIAL EDUCATION RESOURCE - SCIENCE - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'speech',
        label: 'SPEECH - 7-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
      {
        value: 'choir',
        label: 'VOCAL MUSIC - K-12th',
        group: 'Standard or Provisional Licensure Areas',
      },
    ],
  },
  {
    label: 'Leadership Licensure Areas',
    options: [
      {
        value: 'buildingadmin',
        label: 'BUILDING-LEVEL ADMINISTRATOR - P-12th',
        group: 'Leadership Licensure Areas',
      },
      {
        value: 'curriculumprogramadmin',
        label: 'CURRICULUM/PROGRAM ADMINISTRATOR - P-12th',
        group: 'Leadership Licensure Areas',
      },
      {
        value: 'curriculumprogramadminadulted',
        label: 'CURRICULUM/PROGRAM ADMINISTRATOR - ADULT EDUCATION - P-12th',
        group: 'Leadership Licensure Areas',
      },
      {
        value: 'curriculumprogramadmincareertech',
        label: 'CURRICULUM/PROGRAM ADMINISTRATOR - CAREER TECH ED - P-12th',
        group: 'Leadership Licensure Areas',
      },
      {
        value: 'curriculumprogramadmincontentspecialist',
        label: 'CURRICULUM/PROGRAM ADMINISTRATOR - CONTENT SPECIALIST - P-12th',
        group: 'Leadership Licensure Areas',
      },
      {
        value: 'curriculumprogramadmingats',
        label: 'CURRICULUM/PROGRAM ADMINISTRATOR - GIFTED & TALENTED - P-12th',
        group: 'Leadership Licensure Areas',
      },
      {
        value: 'curriculumprogramadminspecialed',
        label: 'CURRICULUM/PROGRAM ADMINISTRATOR - SPECIAL EDUCATION - P-12th',
        group: 'Leadership Licensure Areas',
      },
      {
        value: 'districtleveladmin',
        label: 'DISTRICT-LEVEL ADMINISTRATOR - P-12th',
        group: 'Leadership Licensure Areas',
      },
    ],
  },
  {
    label: 'Endorsement Areas',
    options: [
      {
        value: 'age34endorsement',
        label:
          'AGE 3-4 ENDORSEMENT - FOR TEACHERS HOLDING AN ELEMENTARY K-6 LICENSE',
        group: 'Endorsement Areas',
      },
      {
        value: 'age34specialedendorsement',
        label:
          'AGE 3-4 SPECIAL EDUCATION ENDORSEMENT - FOR TEACHERS HOLDING AN ELEMENTARY K-6 SPEACIAL EDUCATION LICENSE',
        group: 'Endorsement Areas',
      },
      {
        value: 'grade56endorsement',
        label:
          'GRADE 5-6 ENDORSEMENT - FOR 7-12 TEACHER LICENSED IN MATH, SCIENCE, LANG ARTS OR SOCIAL STUDIES',
        group: 'Endorsement Areas',
      },
    ],
  },
  {
    label: 'Ancilliary Licensure Areas',
    options: [
      {
        value: 'psychologyspecialist',
        label: 'SCHOOL PSYCHOLOGY SPECIALIST - K-12th',
        group: 'Ancilliary Licensure Areas',
      },
      {
        value: 'pathology',
        label: 'SPEECH LANGUAGE PATHOLOGY - K-12th',
        group: 'Ancilliary Licensure Areas',
      },
      {
        value: 'dyslexiaancilliary',
        label: 'DYSLEXIA - K-12th',
        group: 'Ancilliary Licensure Areas',
      },
      {
        value: 'esolancilliary',
        label: 'ENGLISH FOR SPEAKERS OF OTHER LANGUAGES - ESOL',
        group: 'Ancilliary Licensure Areas',
      },
    ],
  },
];

export const stateOptions = [
  {
    value: 'AL',
    label: 'Alabama',
  },
  {
    value: 'AK',
    label: 'Alaska',
  },
  {
    value: 'AZ',
    label: 'Arizona',
  },
  {
    value: 'AR',
    label: 'Arkansas',
  },
  {
    value: 'CA',
    label: 'California',
  },
  {
    value: 'CO',
    label: 'Colorado',
  },
  {
    value: 'CT',
    label: 'Connecticut',
  },
  {
    value: 'DE',
    label: 'Delaware',
  },
  {
    value: 'FL',
    label: 'Florida',
  },
  {
    value: 'GA',
    label: 'Georgia',
  },
  {
    value: 'HI',
    label: 'Hawaii',
  },
  {
    value: 'ID',
    label: 'Idaho',
  },
  {
    value: 'IL',
    label: 'Illinois',
  },
  {
    value: 'IN',
    label: 'Indiana',
  },
  {
    value: 'IA',
    label: 'Iowa',
  },
  {
    value: 'KS',
    label: 'Kansas',
  },
  {
    value: 'KY',
    label: 'Kentucky',
  },
  {
    value: 'LA',
    label: 'Louisiana',
  },
  {
    value: 'ME',
    label: 'Maine',
  },
  {
    value: 'MD',
    label: 'Maryland',
  },
  {
    value: 'MA',
    label: 'Massachusetts',
  },
  {
    value: 'MI',
    label: 'Michigan',
  },
  {
    value: 'MN',
    label: 'Minnesota',
  },
  {
    value: 'MS',
    label: 'Mississippi',
  },
  {
    value: 'MO',
    label: 'Missouri',
  },
  {
    value: 'MT',
    label: 'Montana',
  },
  {
    value: 'NE',
    label: 'Nebraska',
  },
  {
    value: 'NV',
    label: 'Nevada',
  },
  {
    value: 'NH',
    label: 'New Hampshire',
  },
  {
    value: 'NJ',
    label: 'New Jersey',
  },
  {
    value: 'NM',
    label: 'New Mexico',
  },
  {
    value: 'NY',
    label: 'New York',
  },
  {
    value: 'NC',
    label: 'North Carolina',
  },
  {
    value: 'ND',
    label: 'North Dakota',
  },
  {
    value: 'OH',
    label: 'Ohio',
  },
  {
    value: 'OK',
    label: 'Oklahoma',
  },
  {
    value: 'OR',
    label: 'Oregon',
  },
  {
    value: 'PA',
    label: 'Pennsylvania',
  },
  {
    value: 'RI',
    label: 'Rhode Island',
  },
  {
    value: 'SC',
    label: 'South Carolina',
  },
  {
    value: 'SD',
    label: 'South Dakota',
  },
  {
    value: 'TN',
    label: 'Tennessee',
  },
  {
    value: 'TX',
    label: 'Texas',
  },
  {
    value: 'UT',
    label: 'Utah',
  },
  {
    value: 'VT',
    label: 'Vermont',
  },
  {
    value: 'VA',
    label: 'Virginia',
  },
  {
    value: 'WA',
    label: 'Washington',
  },
  {
    value: 'WV',
    label: 'West Virginia',
  },
  {
    value: 'WI',
    label: 'Wisconsin',
  },
  {
    value: 'WY',
    label: 'Wyoming',
  },
];

export const designationOptions = [
  {
    value: 'National Board Certified',
    label: 'National Board Certified',
  },
  {
    value: 'Designated Master Teacher',
    label: 'Designated Master Teacher',
  },
  {
    value: 'Designated Lead Teacher',
    label: 'Designated Lead Teacher',
  },
];

export const institutionOptions = [{
  value: 'Arkansas Baptist College',
  label: 'Arkansas Baptist College',
}, {
  value: 'Arkansas State University Mid-South',
  label: 'Arkansas State University Mid-South',
}, {
  value: 'Arkansas State University-Beebe',
  label: 'Arkansas State University-Beebe',
}, {
  value: 'Arkansas State University-Jonesboro',
  label: 'Arkansas State University-Jonesboro',
}, {
  value: 'Arkansas State University-Mountain Home',
  label: 'Arkansas State University-Mountain Home',
}, {
  value: 'Arkansas State University-Newport',
  label: 'Arkansas State University-Newport',
}, {
  value: 'Arkansas State University-System Office',
  label: 'Arkansas State University-System Office',
}, {
  value: 'Arkansas Tech University',
  label: 'Arkansas Tech University',
}, {
  value: 'Bryan University',
  label: 'Bryan University',
}, {
  value: 'Central Baptist College',
  label: 'Central Baptist College',
}, {
  value: 'College of the Ouachitas',
  label: 'College of the Ouachitas',
}, {
  value: 'Crowley\'s Ridge College',
  label: 'Crowley\'s Ridge College',
}, {
  value: 'Ecclesia College',
  label: 'Ecclesia College',
}, {
  value: 'Harding University',
  label: 'Harding University',
}, {
  value: 'Henderson State University',
  label: 'Henderson State University',
}, {
  value: 'Hendrix College',
  label: 'Hendrix College',
}, {
  value: 'John Brown University',
  label: 'John Brown University',
}, {
  value: 'Lyon College',
  label: 'Lyon College',
}, {
  value: 'Ouachita Baptist University',
  label: 'Ouachita Baptist University',
}, {
  value: 'Ozarka College',
  label: 'Ozarka College',
}, {
  value: 'Philander Smith College',
  label: 'Philander Smith College',
}, {
  value: 'Shorter College',
  label: 'Shorter College',
}, {
  value: 'Southern Arkansas University Main Campus',
  label: 'Southern Arkansas University Main Campus',
}, {
  value: 'Southern Arkansas University Tech',
  label: 'Southern Arkansas University Tech',
}, {
  value: 'Strayer University-Arkansas',
  label: 'Strayer University-Arkansas',
}, {
  value: 'University of Arkansas (Fayetteville)',
  label: 'University of Arkansas (Fayetteville)',
}, {
  value: 'University of Arkansas at Little Rock',
  label: 'University of Arkansas at Little Rock',
}, {
  value: 'University of Arkansas at Monticello',
  label: 'University of Arkansas at Monticello',
}, {
  value: 'University of Arkansas at Pine Bluff',
  label: 'University of Arkansas at Pine Bluff',
}, {
  value: 'University of Arkansas-Fort Smith',
  label: 'University of Arkansas-Fort Smith',
}, {
  value: 'University of Arkansas-Pulaski Technical College',
  label: 'University of Arkansas-Pulaski Technical College',
}, {
  value: 'University of Central Arkansas',
  label: 'University of Central Arkansas',
}, {
  value: 'University of Phoenix-Arkansas',
  label: 'University of Phoenix-Arkansas',
}, {
  value: 'University of the Ozarks',
  label: 'University of the Ozarks',
}, {
  value: 'Williams Baptist College',
  label: 'Williams Baptist College',
}, {
  value: 'Other',
  label: 'Other (please specify)',
}];
