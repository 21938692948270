import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../../components/CustomButton/CustomButton';
import { auth } from '../../firebase/Firebase';
import CustomSnackBar from '../../components/CustomSnackBar/CustomSnackBar';
import PageLayout from '../PageLayout/PageLayout';

const ForgotPasswordSchema = Yup.object({
  email: Yup
    .string()
    .required('Please enter your email address')
    .email('Invalid email'),
});

class ForgotPasswordPage extends Component {
  state = {
    isSnackBarOpen: false,
    snackBarMessage: 'If a matching account was found, then an email was sent to the provided email address.',
  };

  closeSnackBar = () => setTimeout(() => this.setState({ isSnackBarOpen: false }), 5000);

  showSnackBarMessage = (actions) => {
    actions.setSubmitting(false);
    actions.resetForm();
    this.setState({isSnackBarOpen: true }, this.closeSnackBar);
  }

  render() {
    return (
      <PageLayout>
        <CustomSnackBar
          isOpen={this.state.isSnackBarOpen}
          message={this.state.snackBarMessage}
          handleClose={() => this.setState({ isSnackBarOpen: false })}
        />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={ForgotPasswordSchema}
              onSubmit={async (values, actions) => {
                auth.sendPasswordResetEmail(values.email)
                  .then(() => {
                    this.showSnackBarMessage(actions);
                  })
                  .catch(() => {
                    this.showSnackBarMessage(actions);
                  });
              }}
            >
              {formik => (
                <Form noValidate onSubmit={formik.handleSubmit}>
                  <Form.Group controlId="email">
                    <Form.Label className={!formik.touched.email ? "" : (formik.errors.email ? "text-danger" : "")}>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      isInvalid={formik.errors.email && formik.touched.email}
                      {...formik.getFieldProps('email')}
                    />
                    <ErrorMessage component="div" className="text-danger" name="email" />
                  </Form.Group>
                  <ButtonToolbar>
                    <CustomButton
                      type="submit"
                      isDisabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
                    >
                      Reset Password
                    </CustomButton>
                    <CustomButton
                      onClick={() => this.props.history.push('/login')}
                      isVisible={this.state.hasClickedForgotPassword}
                    >
                      Cancel
                    </CustomButton>
                  </ButtonToolbar>
                </Form>
              )}
            </Formik>
        </div>
      </PageLayout>
    );
  }
}

export default ForgotPasswordPage;
