import React from 'react';

function PrivacyPolicy() {
  return (
    <div>
      <h1>Website Privacy Policy</h1>
      <h3>Overview</h3>
      <p>
        This policy addresses the collection and use of information in connection
        with the Arkansas Teachers web site maintained by the University of
        Arkansas and describes under what circumstances the university may disclose
        such information, consistent with Ark. Code § 25-1- 114. By using this
        website, you consent to the collection, use, and transfer of your data as
        described in this policy. The university adheres to all applicable federal
        and state laws, as well as general university policies that are applicable
        to the use of computing resources. These include, but are not limited to,
        the laws and policies contained in Appendix A.
      </p>
      <h3>Information Collected</h3>
      <p>
        The university collects and stores personal information about you,
        including any information that you may enter or upload onto the website.
        This may include:
      </p>
      <ul>
        <li>
          your contact information, including your name, address, email address, and phone
          number
        </li>
        <li>your social security number</li>
        <li>demographic information</li>
        <li>your resume including education and job experience</li>
        <li>information contained in job postings</li>
        <li>information used to apply for jobs</li>
        <li>institutional information</li>
      </ul>
      <p>
        Additionally, certain information may be routinely logged as a normal business practice.
        Common information logged includes, but is not limited to, the following:
      </p>
      <ul>
        <li>the IP address of the user’s computer</li>
        <li>the date and time a user’s computer accessed our site</li>
        <li>the IP address and URL of a referring website</li>
        <li>the page the user requested from the university’s site</li>
        <li>
          the information that a user’s Web browser software sends, which typically
          identifies the browser software and may also indicate the operating system
          and type of CPU used in the user’s computer
        </li>
        <li>in the case of email, the sender and recipient&#39;s email addresses</li>
      </ul>
      <h3>Google Analytics</h3>
      <p>
        The website may use Google Analytics, a Web analytics service provided by Google,
        Inc., to collect information such as URLs, Internet domain and host names,
        browser software, date and time site visited, etc.
      </p>
      <h3>Cookies</h3>
      <p>
        The website may use cookies to provide the user with tailored information from the
        website. A cookie is an element of data that a website can send to a browser,
        which may then store it on the user’s system. Some elements of the website may
        require the user to accept cookies in order for the application to work properly.
        Two types of cookies exist, session cookies and persistent cookies. Session cookies
        contain data through which the Web application the visitor is using can maintain
        the continuity and state of a session. Session cookies expire upon user logout,
        closing of the browser or timed-out sessions. Persistent cookies contain information
        that may be useful across multiple sessions, such as identifying the user or other
        persistent attributes, and are typically not deleted.
        <br />
        <br />
        Cookies may provide you with certain convenient features such as saving time at login
        (for example assisting the website in recalling specific information such as username
        and password), storing preferences, and tracking job searches. Data from cookies may
        also be used to identify user trends and patterns, provide services and record session
        information. Web browsers can be configured to refuse cookies, accept cookies, disable
        cookies and remove cookies as needed or desired.
      </p>
      <h3>How Personal Data Is Used</h3>
      <p>
        We use the information that we receive from you to operate the website and provide you
        with services relating to the application for employment positions within Arkansas
        educational institutions. In addition, your information may be used to analyze, improve,
        and optimize the website and its services. If information requested is not entered,
        it is possible that the services and/or requests cannot be accomplished online.
        <br />
        <br />
        We may use your contact information to send you email and other communications regarding
        the website and its services. For example, you may receive email when you join the
        website, apply for a job, post a job description, request username or password
        information, or terminate your account.
      </p>
      <h3>Disclosure of Data Collected</h3>
      <p>
        The university does not sell information collected through university websites to other
        entities. Your information will be shared with any service providers that are used to
        provide the website services to you, such as the services hosting our web server(s)
        and site database. Your information will be shared with the Arkansas Department of
        Education for verification of your credentials and any other purpose associated with
        the provision of services by this website. Additionally, educational institutions
        and related organizations in Arkansas will have access to your job application(s),
        resume(s), and personal information. These institutions and organizations may receive
        this information by searching or browsing resumes, or they may receive the information
        when a user submits an application to such institution or organization. These
        institutions and organizations may contact you using any of the contact information
        that you have submitted to the website via job application, resume, or any other
        method. Job postings may be made publicly available on the website. We may also
        disclose information to third parties when you give us consent to do so.
        <br />
        <br />
        While we attempt to limit access to the resume database to legitimate users, we
        cannot guarantee that other parties will not gain access to the database. Therefore,
        any sensitive information that you put in your resume or profile (such as criminal
        histories, physical/mental health issues, addictions, etc.) are done at your own risk.
        <br />
        <br />
        The university is required to comply with the Arkansas Freedom of Information
        Act (FOIA) (Ark. Code Ann. § 25-19-101 et seq.) and may be required to disclose records
        maintained in the daily operations of the university unless such records are
        exempt from disclosure under federal or state law. Therefore, some data
        collected through university websites may be subject to disclosure upon
        receipt of a valid FOIA request.
        <br />
        <br />
        Additionally, at times, the university may be legally required to disclose information
        collected through the website in response to a valid subpoena or court order or to
        comply with a legally permitted inquiry by a governmental agency or regulatory body.
        <br />
        <br />
        Subject to governing law and other applicable university policies, the university
        reserves the right to disclose information collected on the website to governmental
        authorities in connection with suspected unlawful activity or to aid an investigation
        into suspected unlawful activity. In addition, the university reserves the right to
        release information collected on the website to appropriate governmental authorities
        if university officials determine, in their sole judgment, that university policies
        have been violated or that release of information is necessary to protect the rights,
        health, safety, or property of persons or the university or to protect the integrity
        of university computer networks, if applicable. Further, the university reserves the
        right to disclose information as university officials believe necessary to exercise
        the university’s legal rights, to defend against actual or potential legal claims,
        or as otherwise permitted or required by university policy, including but not limited
        to, the Code of Computing Practices, as it may be amended or modified from time to time.
        <br />
        <br />
        The European Union General Data Protection Regulation (GDPR) places additional
        obligations on organizations that control or process personally identifiable
        information about people in the European Union. Some university activities may
        be covered by the GDPR, and the university has updated its policies on how it collects,
        stores, and processes certain types of data. Further details, as well as a description
        of protective measures undertaken by the university, data retention, and breach
        notification are available in the campus GDPR Policy.
      </p>
      <h3>Third Party Applications and Links</h3>
      <p>
        The website contains links to third-party applications and websites that may or may not
        be operated by university personnel. The university cannot warrant or be responsible
        for the privacy policies of such sites. Users are encouraged to become familiar with
        the privacy policies of third-party sites.
      </p>
      <h3>Security</h3>
      <p>
        In addition to complying with all applicable laws and regulations, the university
        strives to implement and maintain systems and policies to protect the confidentiality
        and integrity of personal information provided by users. Despite these security
        measures, the university does not represent or warrant that personal information will
        be protected against loss, misuse, or alteration by third parties.
      </p>
      <h3>
        Children
      </h3>
      <p>
        This website is not intended for, nor do we knowingly collect information from,
        children under the age of 13.
      </p>
      <h3>Questions</h3>
      <p>
        For more information, please contact the Arkansas Teacher Match at
        arteachermatch@gmail.com
      </p>
      <h3>Updates</h3>
      <p>
        This policy is subject to periodic review and update by university officials.
      </p>
      <h3>Website Privacy Policy Appendix A</h3>
      <p>
        The university adheres to all applicable federal and state laws, as well as general
        university policies that are applicable to the use of computing resources.
      </p>
      <h3>State and Federal Laws</h3>
      <ul>
        <li>Family Education Rights and Privacy Act (FERPA)</li>
        <li>Electronic Communications Privacy Act of 1986</li>
        <li>Gramm-Leach-Bliley Act</li>
        <li>Health Insurance Portability and Accountability Act (HIPAA)</li>
        <li>USA Patriot Act</li>
        <li>Children’s Online Privacy Protection Rule</li>
        <li>Arkansas Freedom of Information Act</li>
        <li>18 U.S.C. § 1030</li>
        <li>Ark. Code. § 5-41-101 et seq.</li>
        <li>Ark. Code. § 13-2-701 et seq.</li>
        <li>Ark. Code. § 25-1-114</li>
      </ul>
      <h3>University Policies</h3>
      <ul>
        <li>University of Arkansas Policies and Procedures</li>
        <li>University of Arkansas Systemwide Policies and Procedures</li>
        <li>University of Arkansas Board of Trustee Policies</li>
        <li>IT Services Computing Policies</li>
      </ul>
      <h3>Web Scraping Policy</h3>
      <p>
        You agree that any information collected through robots, crawlers or scrapers
        will not be sold, transferred or redistributed for monetary gain, because this content
        is protected by our copyright. In gathering data, you agree to use your legitimate
        IP address. You also agree to not use these tools to attempt to uncover personal
        information including applications, any information protected by law, or any information
        secured by a login. Finally, do not crawl or scrape rotbots.txt files.
      </p>
    </div>
);
}

export default PrivacyPolicy;
