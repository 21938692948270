import * as types from '../actions/types';

const INITIAL_STATE = {
  userData: null,
  jobInfo: null,
  resumeLink: null,
  transcriptLink: null,
  referenceLetterLinks: [],
  attachedApplicationInfo: null, // Info that was part of the application document
  loaded: false,
  proceedStatusSending: false,
  failed: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_APPLICATION_ATTACHED_INFO_SUCCESS:
      return { ...state, attachedApplicationInfo: action.payload };
    case types.GET_APPLICATION_INFO_USER_DATA_SUCCESS:
      return { ...state, userData: action.payload };
    case types.GET_APPLICATION_INFO_JOB_INFO_SUCCESS:
      return { ...state, jobInfo: action.payload };
    case types.GET_APPLICATION_INFO_DOCUMENT_LINKS_SUCCESS:
      return { ...state, resumeLink: action.payload };
    case types.GET_APPLICATION_INFO_TRANSCRIPT_LINK_SUCCESS:
      return { ...state, transcriptLink: action.payload };
    case types.SET_APPLICATION_INFO_CLEAR_REFERENCE_LINKS: {
      return { ...state, referenceLetterLinks: []}
    }
    case types.GET_APPLICATION_INFO_REFERENCE_LINK_SUCCESS: {
      // Add the ref link to the list
      const refLinksNew = [...state.referenceLetterLinks];
      refLinksNew.push(action.payload);
      return { ...state, referenceLetterLinks: refLinksNew };
    }
    case types.GET_APPLICATION_INFO_COMPLETE:
      return { ...state, loaded: true };
    case types.GET_APPLICATION_INFO_FAILED:
      return { ...state, loaded: true, failed: true };
    case types.SET_APPLICANT_PROCEED_STATUS:
      return { ...state, proceedStatusSending: true };
    case types.SET_APPLICANT_PROCEED_STATUS_SUCCESS: {
      const attachedInfoNew = {
        ...state.attachedApplicationInfo,
        proceedStatus: action.payload,
      };
      return {
        ...state,
        attachedApplicationInfo: attachedInfoNew,
        proceedStatusSending: false,
      };
    };
    case types.RESET_APPLICATION_INFO: {
      return { ...INITIAL_STATE }
    }
    default:
      return state;
  }
};
