import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import store from './state';
import MainRouter from './pages/MainRouter/MainRouter';
import { automaticLogin } from './state/actions/auth';

const theme = createTheme({
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Nunito',
    ].join(','),
  },
});

class App extends Component {
  async componentDidMount() {
    try {
      await automaticLogin();
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <MainRouter />
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}

export default App;
