const styles = {
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: 20,
    height: '10%',
    marginTop: 10,
    marginBottom: 30,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  }
};

export default styles;
