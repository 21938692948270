import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PageLayout from '../PageLayout/PageLayout';
import TermsAndConditions from '../../components/TermsAndConditions/TermsAndConditions';
import styles from './TermsAndConditionsPage.styles';

class TermsAndConditionsPage extends Component {
    render() {
        return (
          <PageLayout>
            <div style={styles.pageContainer}>
              <TermsAndConditions />
            </div>
          </PageLayout>
        );
    }
}

export default withRouter(TermsAndConditionsPage);
