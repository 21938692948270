import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { connect } from 'react-redux';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { updateTeacherInfo } from '../../state/actions/dashboardUpdateUser';

function UpdateUserDialog(props) {
  const [user, setUser] = useState(props.userInfo);
  const [value, setValue] = React.useState();
  const { addUserHandler } = props;
  const [open, setOpen] = React.useState(true);

  const [switchState, setSwitchState] = React.useState({
    addMultiple: false,
  });

  const handleSwitchChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const resetSwitch = () => {
    setSwitchState({ addMultiple: false });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    props.toggleControl();
    resetSwitch();
  };

  const handleAdd = event => {
    props.updateTeacherInfo(user.id, user.firstName, user.lastName);
    setOpen(false);
    setValue({});
    // 9/28/22 Evidently we're reloading the page so that the data table will be updated
    // with the change we just made.
    // Also note that handleAdd is called when we EDIT a teacher
    // and that "user" everywhere here evidently means "teacher"
    setTimeout(() => {
      window.location.reload();
    }, 3000);
    // props.refreshPage();
  };

  const handleChange = name => ({ target: { value } }) => {
    setUser({ ...user, [name]: value });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Update Info</DialogTitle>
        <DialogContent>
          <DialogContentText>Change User Info And Update.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="First Name"
            type="text"
            fullWidth
            value={user.firstName}
            onChange={handleChange('firstName')}
          />
          <TextField
            margin="dense"
            label="Last Name"
            type="text"
            fullWidth
            value={user.lastName}
            onChange={handleChange('lastName')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  state,
});

export default connect(mapStateToProps,
  { updateTeacherInfo }
  )(UpdateUserDialog);
