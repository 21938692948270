import { storage } from '../../firebase/Firebase';
import django from '../../apis/django';
import * as types from './types';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

const getTeachersSuccess = (dispatch, response) => {
    dispatch({
        type: types.GET_TEACHERS_SUCCESS,
        payload: response.data,
    });
};

const getTeachersFail = (dispatch, error) => {
    console.log(error);
    dispatch({ type: types.GET_TEACHERS_FAIL });
};

export const getTeachers = () => async dispatch => {
    dispatch({ type: types.GET_TEACHERS });
    try {
        const config = await getRequestConfig();
        const response = await django.get(`/teachers/?sharesdata`, config);
        getTeachersSuccess(dispatch, response);
    } catch (error) {
        getTeachersFail(dispatch, error);
    }
};

const getTeacherSuccess = (dispatch, response) => {
    dispatch({
        type: types.GET_TEACHER_SUCCESS,
        payload: response.data,
    });
};

// Get each doc id and doc.name in reference letter collection
// const getTeacherRLSuccess = (dispatch, querySnapshot) => {
//     const userDocs = [];
//     querySnapshot.forEach(doc => {
//         console.log(doc.id, '=>', doc.data().name);
//         userDocs.push([doc.id, doc.data().name]);
//     });
//     dispatch({
//         type: types.GET_TEACHER_REFERENCE_LETTER_SUCCESS,
//         payload: userDocs,
//     });
// };

// const getTeacherRLFail = (dispatch, error) => {
//     console.log(error);
//     dispatch({ type: types.GET_TEACHER_REFERENCE_LETTER_FAIL });
// };

const getTeacherFail = (dispatch, error) => {
    // Don't log an error if we got a 404 back - that can happen
    if (!(error.response && error.response.status == "404")) {
        console.log(error);
    }
    dispatch({ type: types.GET_TEACHER_FAIL });
};


export const getTeacherJobsAppliedFor = (teacherId) => async dispatch => {
    dispatch({ type: types.GET_TEACHER_JOBS_APPLIED_FOR });
    try {
        // /jobs/applications/teacher/dDeEUb8mbBaKUSt4rmSVCEci1lj2/
        const config = await getRequestConfig();
        const response = await django.get(`/jobs/applications/teacher/${teacherId}/`, config);
        dispatch({
            type: types.GET_TEACHER_JOBS_APPLIED_FOR_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        if (!(error.response && error.response.status == "404")) {
            console.log(error);
        }
        dispatch({ type: types.GET_TEACHER_JOBS_APPLIED_FOR_FAIL });
    }
};

export const getTeacherJobsAppliedForDetailed = (teacherId) => async dispatch => {
    dispatch({ type: types.GET_TEACHER_JOBS_APPLIED_FOR_DETAILED });
    try {
        // /jobs/applications/teacher/detailed/dDeEUb8mbBaKUSt4rmSVCEci1lj2/
        const config = await getRequestConfig();
        const response = await django.get(`/jobs/applications/teacher/detailed/${teacherId}/`, config);
        dispatch({
            type: types.GET_TEACHER_JOBS_APPLIED_FOR_DETAILED_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        if (!(error.response && error.response.status == "404")) {
            console.log(error);
        }
        dispatch({ type: types.GET_TEACHER_JOBS_APPLIED_FOR_DETAILED_FAIL });
    }
};

export const getTeacherInfo = (teacherId) => async dispatch => {
    dispatch({ type: types.GET_TEACHER });
    try {
        const config = await getRequestConfig();
        const response = await django.get(`/teachers/id/${teacherId}/`, config);
        getTeacherSuccess(dispatch, response);
    } catch (error) {
        getTeacherFail(dispatch, error);
    }
};

// if there is a references collection but not documents, then an empty array will be returned
export const getTeacherRefLetters = (uid) => async dispatch => {
    try {
        const teacherRefLetters = [];
        dispatch({ type: types.GET_TEACHER_REFERENCE_LETTER });
        const config = await getRequestConfig();
        const response = await django.get(`/attachments/references/${uid}/`, config);
        if (response.data) {
            response.data.forEach(doc => {
                teacherRefLetters.push([doc.id, doc.docId, doc.fileName]);
            });

            teacherRefLetters.forEach(letter => {
                storage
                    .ref(`references/${uid}/${letter[1]}.pdf`)
                    .getDownloadURL()
                    .then(url => letter.push(url))
                    .catch(error => console.log(error));
            });

            dispatch({
                type: types.GET_TEACHER_REFERENCE_LETTER_SUCCESS,
                payload: teacherRefLetters,
            });

        } else {
            dispatch({ type: types.GET_TEACHER_REFERENCE_LETTER_FAIL });
            console.log('There were no references returned.');
        }
    } catch (err) {
        if (err.response?.status == "404") {
            dispatch({
                type: types.GET_TEACHER_REFERENCE_LETTER_SUCCESS,
                payload: [],
            });
            return
        }
        dispatch({ type: types.GET_TEACHER_REFERENCE_LETTER_FAIL });
        console.error(err);
        console.log('There was a problem getting the references.');
    }
};

export const getTeacherResumes = (uid) => async dispatch => {
    try {
        const teacherResumes = [];
        dispatch({ type: types.GET_TEACHER_RESUME });
        const config = await getRequestConfig();
        const response = await django.get(`/attachments/resumes/${uid}/`, config);
        if (response.data) {
            response.data.forEach(doc => {
                // console.log(doc.id, '=>', doc.data().name);
                teacherResumes.push([doc.id, doc.docId, doc.fileName]);
            });

            teacherResumes.forEach(letter => {
                storage
                    .ref(`resumes/${uid}/${letter[1]}.pdf`)
                    .getDownloadURL()
                    .then(url => letter.push(url))
                    .catch(error => console.log(error));
            });

            dispatch({
                type: types.GET_TEACHER_RESUME_SUCCESS,
                payload: teacherResumes,
            });
        } else {
            dispatch({ type: types.GET_TEACHER_RESUME_FAIL });
            console.log('There were no resumes returned.');
        }
    } catch (err) {
        if (err.response?.status == "404") {
            dispatch({
                type: types.GET_TEACHER_RESUME_SUCCESS,
                payload: [],
            });
            return
        }
        dispatch({ type: types.GET_TEACHER_RESUME_FAIL });
        console.error(err);
        console.log('There was a problem getting the resumes.');
    }
};

export const getTeacherTranscripts = (uid) => async dispatch => {
    try {
        const teacherTranscripts = [];
        dispatch({ type: types.GET_TEACHER_TRANCRIPT });
        const config = await getRequestConfig();
        const response = await django.get(`/attachments/transcripts/${uid}/`, config);
        response.data.forEach(doc => {
            // console.log(doc.id, '=>', doc.data().name);
            teacherTranscripts.push([doc.id, doc.docId, doc.fileName]);
        });

        teacherTranscripts.forEach(letter => {
            storage
                .ref(`transcripts/${uid}/${letter[1]}.pdf`)
                .getDownloadURL()
                .then(url => letter.push(url))
                .catch(error => console.log(error));
        });

        dispatch({
            type: types.GET_TEACHER_TRANCRIPT_SUCCESS,
            payload: teacherTranscripts,
        });
    } catch (err) {
        if (err.response?.status == "404") {
            dispatch({
                type: types.GET_TEACHER_TRANCRIPT_SUCCESS,
                payload: [],
            });
            return
        }
        dispatch({ type: types.GET_TEACHER_TRANCRIPT_FAIL });
        console.error(err);
        console.log('There was a problem getting the transcripts.');
    }
};


export const getTeacher = async uid => {
    try {
        const config = await getRequestConfig();
      const response = await django.get(`/teachers/${uid}/`, config);
      if (response.data?.id) {
        return response.data.id;
      } else {
        return null
      }
    } catch (err) {
      console.error(err);
      console.error('There was a problem looking up a teacher by FireBase auth ID');
      return null;
    }
  };