// TODO: FIX THIS FILE AS SOON AS POSSIBLE!!!!!!!!.  THIS IS BAD, AND IT MAKES NO SENSE WHATSOEVER
// TODO: MOVE THE ACTIONS TO ITS OWN FILE INSTEAD OF THROWING RANDOM ACTIONS IN THE INDEX FILE
// TODO: REMOVE INDEX FILE
import store from '..';
import { getDistrictByLEA } from './districtData';

const degreeToNum = (degree) => {
    switch (degree) {
        case 'specialist':
            return 400;
        case 'doctoral':
            return 300;
        case 'master':
            return 200;
        case 'bachelor':
            return 100;
        default:
            return 0;
    }
};

const findSalaryOfTeacher = async (lea, teacherSalaryLevel, yearsOfExperience) => {
    // const min = 20000;
    // const max = 50000;
    try {
        const districtData = await getDistrictByLEA(lea);
        if (!districtData?.salaryData) {
            return 0
        }
        const { salaryData } = districtData;
        // First, try to see if the salary data has an entry for the number of years,
        // if it does not, then take the last entry
        const salaryRow = salaryData[yearsOfExperience] ? salaryData[yearsOfExperience] : salaryData[salaryData.length - 1];
        const salaryRowKeys = Object.keys(salaryRow); // The row corresponding to the number of years of experience.
        const nearestBelow = (input, lookup) => lookup.reduce((prev, curr) => (input >= curr ? curr : prev));
        const salaryKey = nearestBelow(teacherSalaryLevel, salaryRowKeys); // Get the next smallest teacherSalaryLevel
     //   return salaryRow[salaryKey];
    } catch (err) {
        console.error(err);
        console.error('There was a problem trying to get your salary.');
    }
     return 0;
    // return Math.floor(Math.random() * (max - min) + min);
};

export const getSalary = async (job) => {
    const { userData } = store.getState().auth;
    if (userData?.optionalDegreeHours && userData?.yearsOfExperience && userData?.degreeLevel) {
        const optionalHours = parseInt(userData.optionalDegreeHours, 10);
        const yearsOfExperience = parseInt(userData.yearsOfExperience, 10);
        const numeratedDegree = degreeToNum(userData.degreeLevel);
        const teacherSalaryLevel = optionalHours + numeratedDegree;
        const districtLea = job.district.lea;
        let newyearsOfExperience = yearsOfExperience;

        const { obj } = require('./salaryExtract.js').default;
        if (obj[districtLea]) {
            if (yearsOfExperience > (Object.keys(obj[districtLea]).length - 1)) {
                newyearsOfExperience = Object.keys(obj[districtLea]).length - 1;
            }

            if (obj[districtLea][newyearsOfExperience][teacherSalaryLevel.toString()]) {
                return obj[districtLea][newyearsOfExperience][teacherSalaryLevel.toString()];
            } if (obj[districtLea][newyearsOfExperience]) {
                let max = 0;

                for (const property in obj[districtLea][newyearsOfExperience]) {
                max = (max < parseFloat(property) && parseFloat(property) < teacherSalaryLevel.toString()) ? parseFloat(property) : max;
                }
                return obj[districtLea][newyearsOfExperience][max.toString()];
            }
        } else {
            return findSalaryOfTeacher(districtLea, teacherSalaryLevel, yearsOfExperience);
        }
    }
    return 0;
};
