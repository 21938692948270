import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CustomButton from '../../components/CustomButton/CustomButton';
import PageLayout from '../PageLayout/PageLayout';
import { sendSupportEmail } from '../../state/actions/supportEmail';
import CustomModal from '../../components/CustomModal/CustomModal';

const ContactUsSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').max(100, 'Maximum 100 characters').required('Please enter your email address'),
  subject: Yup.string().max(100, 'Maximum 100 characters').required('Please enter a subject line'),
  message: Yup.string().max(500, 'Maximum 500 characters').required('Please enter a message'),
});

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSuccessModalOpen: false,
    };
  }

  sendEmail = async ({email, subject, message}) => {
      await sendSupportEmail(email, subject, message);
      this.setState({isSuccessModalOpen: true });
  }

  renderSuccessModalContent = () => (
    <p>Success: Your message has been sent. We will respond to you shortly.</p>
  );

  render() {
    return (
      <PageLayout>
        <CustomModal
          isOpen={this.state.isSuccessModalOpen}
          onClose={() => this.setState({
            isSuccessModalOpen: false,
          })}
          modalContent={this.renderSuccessModalContent()}
        />
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1, minWidth: '60%' }}>
          <Formik
            initialValues={{
              email: '',
              subject: '',
              message: '',
            }}
            validationSchema={ContactUsSchema}
            onSubmit={async (values, actions) => {
              await this.sendEmail(values);
              actions.resetForm();
              actions.setSubmitting(false);
            }}
          >
            {formik => (
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Form.Group controlId="email">
                  <Form.Label className={!formik.touched.email ? "" : (formik.errors.email ? "text-danger" : "")} >Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email address"
                    {...formik.getFieldProps('email')}
                    isInvalid={formik.errors.email && formik.touched.email}
                    isValid={!formik.errors.email && formik.touched.email}
                  />
                  <ErrorMessage component="div" className="text-danger" name="email" />
                </Form.Group>
                <Form.Group controlId="subject">
                  <Form.Label className={!formik.touched.subject ? "" : (formik.errors.subject ? "text-danger" : "")} >Subject</Form.Label>
                  <Form.Control
                    placeholder="Enter a subject line"
                    {...formik.getFieldProps('subject')}
                    isInvalid={formik.errors.subject && formik.touched.subject}
                    isValid={!formik.errors.subject && formik.touched.subject}
                  />
                  <ErrorMessage component="div" className="text-danger" name="subject" />
                </Form.Group>

                <Form.Group controlId="message">
                  <Form.Label className={!formik.touched.message ? "" : (formik.errors.message ? "text-danger" : "")} >Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="6"
                    placeholder="Enter message"
                    {...formik.getFieldProps('message')}
                    isInvalid={formik.errors.message && formik.touched.message}
                    isValid={!formik.errors.message && formik.touched.message}
                  />
                  <ErrorMessage component="div" className="text-danger" name="message" />
                </Form.Group>
                <CustomButton
                  type="submit"
                  isDisabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
                >
                  {formik.isSubmitting ? 'Sending message...' : 'Send Message'}
                </CustomButton>
              </Form>
            )}
          </Formik>
        </div>
      </PageLayout>
    );
  }
}

export default ContactUs

