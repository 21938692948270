// eslint-disable-next-line
import * as types from './types';
import django from '../../apis/django';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

let districtId = 0;

export const startNewPosting = () => ({ type: types.START_NEW_JOB_POSTING });

export const startAdminJobPosting = () => ({ type: types.START_ADMIN_JOB_POSTING });

export const changeTitle = text => ({
  type: types.CHANGE_NEW_JOB_TITLE,
  payload: text,
});

export const changeDescription = text => ({
  type: types.CHANGE_NEW_JOB_DESCRIPTION,
  payload: text,
});

export const changeLink = text => ({
  type: types.CHANGE_NEW_JOB_LINK,
  payload: text,
});

const postJobSuccess = (dispatch, docRef, job) => {
  dispatch({ type: types.POST_NEW_JOB_SUCCESS });
  dispatch({ type: types.SHOW_NEW_JOB_POSTED_ALERT });
  const payload = job;
  payload.id = docRef.data.id;
  dispatch({
    type: types.ADD_POSTING_TO_STATE,
    payload,
  });
  // AB 1/22/23 I see no reason to do this. Not only that, this causes
  // an error when creating a new job posting, because there are no jobs
  // in state. The reducer tries to slice() a null value.
  //
  // dispatch({
  //   type: types.ADD_JOB_TO_STATE,
  //   payload,
  // });
};

const postAdminJobSuccess = (dispatch, docRef, job) => {
  dispatch({ type: types.POST_NEW_JOB_SUCCESS });
  // dispatch({ type: types.SHOW_NEW_JOB_POSTED_ALERT });
  const payload = job;
  payload.id = docRef.id;
  // dispatch({
  //   type: types.ADD_POSTING_TO_STATE,
  //   payload,
  // });
};

const postJobFail = (dispatch, error) => {
  console.dir(error, {depth:null});
  dispatch({ type: types.POST_NEW_JOB_FAIL });
};

// TODO: add districtAddress, districtAddressGeo, districtName
export const createNewJob = (
  title,
  description,
  selectedQuestions,
  selectedLicensure,
  selectedGradeLevels,
  employerData,
  externalEmployerData,
  selectedPartOrFullTime,
  externalLink,
  expirationDate,
) => async dispatch => {
  dispatch({ type: types.POST_NEW_JOB });
  const userId = employerData.id;
  const job = {
    description,
    title,
    selectedQuestions: JSON.stringify(selectedQuestions),
    selectedLicensure: JSON.stringify(selectedLicensure),
    selectedGradeLevels: JSON.stringify(selectedGradeLevels),
    selectedPartOrFullTime: JSON.stringify(selectedPartOrFullTime),
    owner: userId,
    isClosed: false,
    district: externalEmployerData.id,
    datePosted: new Date(),
    externalLink,
    expirationDate
  };
  const config = await getRequestConfig();
  django.post('/jobs/create/', job, config)
    .then(docRef => postJobSuccess(dispatch, docRef, job))
    .catch(error => postJobFail(dispatch, error));
};

export const createNewAdminJob = (
  jobId,
  title,
  description,
  selectedQuestions,
  selectedLicensure,
  selectedGradeLevels,
  employerData,
  externalEmployerData,
  selectedPartOrFullTime,
  externalLink,
) => async dispatch => {
  dispatch({ type: types.POST_NEW_JOB });
  const userId = employerData.id;
  const job = {
    description,
    title,
    selectedQuestions: JSON.stringify(selectedQuestions),
    selectedLicensure: JSON.stringify(selectedLicensure),
    selectedGradeLevels: JSON.stringify(selectedGradeLevels),
    selectedPartOrFullTime: JSON.stringify(selectedPartOrFullTime),
    owner: userId,
    isClosed: false,
    district: externalEmployerData.id,
    datePosted: new Date(),
    externalLink,
  };
  const config = await getRequestConfig();

  django.post('/jobs/create/', job, config)
    .then(docRef =>
      django.delete(`/scrapedjobs/${jobId}/`, config)
      .then(() => postJobSuccess(dispatch, docRef, job))
      .catch(error => postJobFail(dispatch, error)));
};


// AB 5/14/22: I can't do anything right now about this picking whatever employer
// the matches last. We can look up the district by LEA. We need a way to get back
// an employer that is mapped to that district. Maybe I can make a view of
// employers that is ordered by district IDs, and look up an employer record (or
// records) by district ID. Then, to follow the existing logic, I'd just use the
// last one that matched. I have confirmed by testing the functionality of the app
// before my changes that it indeeds set the owner field in the scrapedJobs
// collection to the last employer that matches the provided district.

// darren@synsesolutions.com 2023-03-02: Not updating these requests to use new
// getRequestConfig because not sure functionality for scraped job is important
// at this stage. Doesn't seem this is used to create a new job via form. If so,
// will circle back and update.

const newJ = (
  title,
  description,
  selectedLicensure,
  selectedGradeLevels,
  externalLink,
  lea) => {
  django
  .get(`/districts/lea/${lea}/`)
  .then((response) => {
    districtId = response.data.id;
    django.get(`/employer_justone/${districtId}/`)
    .then((employerResponse) => {
      const employerId = employerResponse.data.id;
      const job = {
        description,
        title,
        selectedLicensure,
        selectedGradeLevels,
        owner: employerId,
        isClosed: false,
        externalLink,
        district: districtId,
      };
      // console.dir(job, { depth: null });
      django
      .post('/scrapedjobs/', job)
      .catch((error) => {
        if (error.response) {
          console.log(`newJ: Unable to create scraped job. Error was ${error.message}`);
          console.dir(error, { depth: null });
        }
      });
    })
    .catch((error) => {
      if (error.response) {
        console.log(`newJ: Could not find employer for provided district. Error was ${error.message}`);
        console.dir(error, { depth: null });
      }
    });
  })
  .catch((error) => {
    if (error.response) {
      console.log(`newJ: Unable to get district by LEA. Error was ${error.message}`);
    }
  });
    // .then(docR => postAdminJobSuccess(dispatch, docR, job))
    // .catch(error => postJobFail(dispatch, error));
};

export const getDistrictData = (
  lea,
  title,
  description,
  selectedLicensure,
  selectedGradeLevels,
  externalLink,
  ) => async dispatch => {
    dispatch({ type: types.ADMIN_DISTRICT_DATA_SUCCESS });
    newJ(
      title,
      description,
      selectedLicensure,
      selectedGradeLevels,
      externalLink,
      lea
    );
  };

export const cancelNewJob = () => ({
  type: types.CANCEL_NEW_JOB_POSTING,
});

export const cancelAdminJob = () => ({
  type: types.CANCEL_NEW_JOB_POSTING,
});
