const styles = {
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        paddingLeft: 200,
        paddingRight: 200,

    },
    image: {
        width: 50,
        height: 50,
        display: 'inline-block',
        float: '',
        flexDirection: 'row',
    },
    textBox: {

    }
};

export default styles;
