import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

function CustomSwitch({ checked, label, onChange, value }) {
  return (
    <FormGroup row>
      <FormControlLabel
        control={(
          <Switch
            checked={checked}
            onChange={onChange}
            value={value}
          />
      )}
        label={label}
      />
    </FormGroup>
);
}

export default CustomSwitch;
