import React from 'react';
import FileUploader from 'react-firebase-file-uploader';
import { storage } from '../../firebase/Firebase';
import CustomButton from '../CustomButton/CustomButton';

function FileUploadButton({
    accept,
    labelText,
    folder,
    onStart,
    onError,
    onProgress,
    onSuccess,
  }) {
  return (
    <CustomButton>
      <label>
        {labelText}
        <FileUploader
          hidden
          accept={accept}
          name={labelText}
          randomizeFilename
          storageRef={storage.ref(folder)}
          onUploadStart={onStart}
          onUploadError={onError}
          onUploadSuccess={onSuccess}
          onProgress={onProgress}
        />
      </label>
    </CustomButton>
);
}

export default FileUploadButton;
