import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import LandingComponent from '../../components/LandingComponent/LandingComponent';
import SkeletonLoadingCard from '../../components/SkeletonLoadingCard/SkeletonLoadingCard';
import CustomCard from '../../components/CustomCard/CustomCard';
import TimeAgo from 'react-timeago';
import PageLayout from '../PageLayout/PageLayout';
import Theme from '../../theme/Theme';
import { getTeacherJobsAppliedForDetailed } from '../../state/actions/getTeachers';

class TeacherAppliedJobsList extends Component {

  componentDidMount() {
    if (this.props.user !== null) {
      // Fetch the user location from the browser, if we can
      // Use zip from profile (if available), if can't get
      // user location.
      if (!this.props.userIsEmployer) {
        this.props.getTeacherJobsAppliedForDetailed(this.props.user.uid);
      }
    }
  }
    
  componentDidUpdate() {
    const { userIsEmployer } = this.props;
    if (userIsEmployer) {
      this.props.history.push('/postings');
    } else if (this.props.user !== null) {
      // User is a Teacher, fetch jobs applied for if don't have them already
      if (this.props.teacher.teacherJobsAppliedForDetailed == null && this.props.teacher.gettingTeacherJobsAppliedForDetailed == null) {
        this.props.getTeacherJobsAppliedForDetailed(this.props.user.uid);
      }
    }
  }

  renderWelcome = () => {
    const firstName = this.props.userData
    ? this.props.userData.firstName
    : 'teachers';
    return (
        <div>
          <div style={{ marginLeft: 10 }}>
            <h2 style={{ fontWeight: 600, display: 'inline', }}>
            A FREE resource designed to help{' '}
            <span
              style={{
              color: Theme.ThemePrimary,
              fontWeight: 600,
              display: 'inline',
              }}
            >
              {firstName}
            </span>{' '}
            find great jobs.
            </h2>
          </div>
          <p style={{ visibility: this.props.userData ? 'visible' : 'hidden' }}>
            These are the jobs for which you have applied.
          </p>
        </div>
    );
  };

  renderCards = () => {
    const { teacherJobsAppliedForDetailed } = this.props.teacher
    if (teacherJobsAppliedForDetailed === null) {
        return (
          <div>
            <SkeletonLoadingCard />
          </div>
        );
    }
    return (
      <div>
        {this.renderJobResults()}
      </div>
    );
  };

  renderJobResults = () => {
    const { teacherJobsAppliedForDetailed } = this.props.teacher
    if (teacherJobsAppliedForDetailed.length !== 0) {
      return teacherJobsAppliedForDetailed.map(this.renderJob);
    } else {
      return <div>You have not yet applied for any jobs.</div>;
    }
  };

  renderJob = (job, index) => {
    const removedLinkText = job.description.replace(/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/gm, '*********');

    return (
      <CustomCard key={`${job.id}`+`${index}`}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h5">{job.title}</Typography>
          <Typography color="textSecondary">
            {job.isClosed && job.dateClosed ? 'Closed' : job.dateApplied ? 'Applied' : ''} {job.dateApplied ? <TimeAgo date={job.dateApplied} /> : ''}
          </Typography>
        </div>
        <Typography color="textSecondary">{job.ownerName}</Typography>
        <Typography color="textSecondary">{job.coordinatorEmail ? <Link href={`mailto:`+job.coordinatorEmail} color="inherit">{job.coordinatorName}</Link> : job.coordinatorName}</Typography>
        <Typography component="p">{removedLinkText}</Typography>
      </CustomCard>
    );
  };

  render() {
    return (
      <PageLayout>
        <LandingComponent welcomeMessage={this.renderWelcome()} />
        {this.renderCards()}
      </PageLayout>
    );
  }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    isLoggingIn: state.auth.isLoggingIn,
    userIsEmployer: state.auth.userIsEmployer,
    jobs: state.jobs.jobs,
    appsOpen: state.jobs.applicationsOpen,
    userData: state.auth.userData,
    teacher: state.teacher
  });

export default withRouter(
    connect(
      mapStateToProps,
      {
        getTeacherJobsAppliedForDetailed
      }
    )(TeacherAppliedJobsList)
  );