import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginUser } from '../../state/actions/auth';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomSnackBar from '../../components/CustomSnackBar/CustomSnackBar';
import PageLayout from '../PageLayout/PageLayout';

const LoginSchema = Yup.object({
  email: Yup
    .string()
    .required('Please enter your email address')
    .email('Invalid email'),
  password: Yup
    .string()
    .required('Please enter your password')
    .min(6, 'Password must be at least 6 characters'),
});

class LoginForm extends Component {
  state = {
    hasClickedForgotPassword: false,
    isSnackBarOpen: false,
    snackBarMessage: 'The account associated with the email or password does not exist.',
  };

  closeSnackBar = () => setTimeout(() => this.setState({ isSnackBarOpen: false }), 5000);

  render() {
    return (
      <PageLayout>
        <CustomSnackBar
          isOpen={this.state.isSnackBarOpen}
          message={this.state.snackBarMessage}
          handleClose={() => this.setState({ isSnackBarOpen: false })}
        />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={LoginSchema}
              onSubmit={async (values, actions) => {
                const { email, password } = values;
                const errorMessage = await this.props.loginUser({ email, password });
                actions.setSubmitting(false);
                if (errorMessage) {
                  this.setState({
                    isSnackBarOpen: true,
                    snackBarMessage: 'The account associated with the email or password does not exist',
                  }, this.closeSnackBar);
                }
                else {
                  actions.resetForm();
                  this.props.history.push('/');
                }
              }}
            >
              {formik => (
                <Form noValidate onSubmit={formik.handleSubmit}>
                  <Form.Group controlId="email">
                    <Form.Label className={!formik.touched.email ? "" : (formik.errors.email ? "text-danger" : "")}>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      isInvalid={formik.errors.email && formik.touched.email}
                      {...formik.getFieldProps('email')}
                    />
                    <ErrorMessage component="div" className="text-danger" name="email" />
                  </Form.Group>

                  <Form.Group controlId="passwordText" style={{ display: this.state.hasClickedForgotPassword ? 'none' : null }}>
                    <Form.Label className={!formik.touched.password ? "" : (formik.errors.password ? "text-danger" : "")}>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter password"
                      isInvalid={formik.errors.password && formik.touched.password}
                      {...formik.getFieldProps('password')}
                    />
                    <ErrorMessage component="div" className="text-danger" name="password" />
                  </Form.Group>

                  <ButtonToolbar>
                    <CustomButton
                      onClick={() => this.props.history.push('/signup')}
                      isVisible={!this.state.hasClickedForgotPassword}
                    >
                      Sign Up
                    </CustomButton>
                    <CustomButton
                      type="submit"
                      isVisible={!this.state.hasClickedForgotPassword}
                      isDisabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
                    >
                      {formik.isSubmitting ? 'Signing in...' : 'Sign in'}
                    </CustomButton>
                    <CustomButton
                      onClick={() => this.props.history.push('/forgotpassword')}
                    >
                      Forgot your password?
                    </CustomButton>
                  </ButtonToolbar>
                </Form>
              )}
            </Formik>
        </div>
      </PageLayout>
    );
  }
}

const mapStateToProps = state => ({
  email: state.auth.email,
  password: state.auth.password,
  isLoggingIn: state.auth.isLoggingIn,
});

export default withRouter(
  connect(
    mapStateToProps,
    { loginUser }
  )(LoginForm)
);
