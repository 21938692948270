import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TeacherSignUpForm from '../../components/TeacherSignUpForm/TeacherSignUpForm';

class TeacherSignUpPage extends Component {
  componentDidUpdate() {
    if (this.props.user != null) {
      // in the future, wait to see if they're an employer
      this.props.history.push('/');
    }
  }

  render() {
    return <TeacherSignUpForm />;
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  teacherSignup: state.teacherSignup,
});

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(TeacherSignUpPage)
);
