import { firestore, auth } from '../../firebase/Firebase';

// make async
// this function does not need to be in this folder nor file
export const sendDistrictEmail = (recipient_, subject_, body_, employerEmail_) => {
    const { uid } = auth.currentUser;
    try {
        const email = {
            recipient: recipient_,
            subject: subject_,
            body: body_,
            senderId: uid,
            employerEmail: employerEmail_,
        };
        firestore.collection('emailsFromDistrict').doc().set(email);
    } catch (err) {
        console.error(err);
        console.error('There was a problem trying to send an email to applicant.');
    }
};
