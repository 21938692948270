import { auth } from '../../firebase/Firebase';
import * as types from './types';
import store from '..';
import django from '../../apis/django';
// import { getRequestConfig } from '../../apis/firebaseTokenSupport';

export const getJobsList = () => async dispatch => {
    // getJobsList is called as soon as the JobList component is mounted
    // However, the userData (information about the logged-in user, if there is one)
    // is fetched AFTER the JobList component is mounted. So we wind up calling
    // getJobsList twice, it seems: once before we have any data about the logged-in
    // user, and once again when the component reloads because state has changed
    // once we have the userdata, and now we bring back a different job list.
    // To prevent this, we use isLoggingIn in state and don't call getJobsList
    // if the user is in the process of logging in.

    // This is a timing issue.  Find a way to fix this without needing this
    await new Promise((resolve, reject) => {
        try {
            auth.onAuthStateChanged(res => {
                if (res) {
                    resolve(res);
                } else {
                    resolve(null);
                }
            });
        } catch (err) {
            reject(err);
        }
    });

    dispatch({ type: types.GET_JOBS_LIST });

    // find level
    const hasFinishedSetup = store.getState().auth.userData;

    // const config = await getRequestConfig();

    if (auth.currentUser && hasFinishedSetup) {
        // Here we can call /jobs/open/
        django.get('/jobs/open/').then(response => getJobsSuccess(dispatch, response.data))
            .catch(error => getJobsFail(dispatch, error));
    } else {
        try {
            // Here we can call /jobs/open/
            django.get('/jobs/open/').then(response => getJobsInit(dispatch, response.data));
        } catch (err) {
            console.error(err);
            console.error('There was a problem trying to fetch all of the jobs.');
        }
    }
};

// helper
const getJobsFail = (dispatch, error) => {
    console.log(error);
    dispatch({ type: types.GET_JOBS_LIST_FAIL });
};

// helper
const getJobsSuccess = (dispatch, jobs) => {
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    dispatch({
        type: types.GET_JOBS_LIST_SUCCESS,
        payload: jobs,
    });
};

const getJobsInit = (dispatch, jobs) => {
    dispatch({
        type: types.GET_JOBS_LIST_SUCCESS,
        payload: jobs,
    });
};
