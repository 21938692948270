import React, { Component } from 'react';
import { Form, FormCheck } from 'react-bootstrap';
import { Field } from 'formik';
import DatePicker from 'react-datepicker/es';
import InputMask from 'react-input-mask';
import CustomSelect from '../CustomSelect/CustomSelect';
import {
    degreeOptions,
    designationOptions,
    hoursOptions,
    institutionOptions,
    licenseTypeOptions,
    licensureOptions,
    stateOptions,
    trueFalseOptions,
} from '../CustomSelect/Options';

class TeacherInformationForm extends Component {
    state = {
        expectedLicenseDate: '',
    };

    renderTextInput = (formik, name, label, isVisible = true) => (
      <div style={{ marginBottom: 20, width: 500, display: isVisible ? '' : 'none' }}>
        <label>{label}</label>
        <Form.Control
          {...formik.getFieldProps(name)}
          isInvalid={!!formik.errors[name] && formik.touched[name]}
          isValid={!formik.errors[name] && formik.touched[name]}
        />
      </div>
    );

    renderNumberInput = (formik, name, label, isVisible = true) => (
      <div style={{ marginBottom: 20, width: 500, display: isVisible ? '' : 'none' }}>
        <label>{label}</label>
        <br />
        <Field name={name}>
             {({
               field, // { name, value, onChange, onBlur }
               form: { touched, errors, handleBlur, handleChange }
             }) => (
                <InputMask
                  id={field.name}
                  mask="+1 999 999 9999"
                  maskChar="-"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={
                    touched[field.name] && errors[field.name]
                      ? "form-control is-invalid" : (touched[field.name] ? "form-control is-valid" : "form-control")
                  }
                />
             )}
        </Field>
      </div>
    );

    renderTextArea = (formik, name, label, isVisible = true) => (
      <div style={{ marginBottom: 20, width: 500, display: isVisible ? '' : 'none' }}>
        <label>{label}</label>
        <Form.Group>
          <Form.Control
            as="textarea"
            rows="3"
            {...formik.getFieldProps(name)}
            isInvalid={formik.errors[name] && formik.touched[name]}
            isValid={!formik.errors[name] && formik.touched[name]}
          />
        </Form.Group>
      </div>
    );

    renderDateArea = (formik, name, label, isVisible = true) => (
      <div style={{ marginBottom: 20, width: 500, display: isVisible ? '' : 'none' }}>
        <label>{label}</label>
        <Form.Group>
          <Form.Control
            as="textarea"
            rows="1"
            placeholder="mm/dd/yr"
            {...formik.getFieldProps(name)}
            isInvalid={formik.errors[name] && formik.touched[name]}
            isValid={!formik.errors[name] && formik.touched[name]}
          />
        </Form.Group>
      </div>
    );

    renderSelect = (formik, name, label, options, isVisible = true, isMulti) => {
        let defaultValue = [];
        if (name === 'selectedLicensure' || name === 'teachingLicenseWorkingTowards' || name == 'teachingLicenseType') {
            defaultValue = formik.values[name];
        } else {
            // AB 9/18/22 Switched to using type coercion here because hoursOptions
            // don't work otherwise. That's because in django the places where hoursOptions
            // is used are stored as integers, but in the Options file they are strings.
            defaultValue = options.filter(option => option.value == formik.values[name]);
        }

        return (
          <div style={{ marginBottom: 20, width: 500, display: isVisible ? '' : 'none' }}>
            <label>{label}</label>
            <CustomSelect
              onChange={formik.setFieldValue}
              onBlur={formik.setFieldTouched}
              name={name}
              defaultValue={defaultValue}
              options={options}
              isMulti={isMulti}
              isInvalid={formik.errors[name] && formik.touched[name]}
            />
          </div>
        );
    };

    renderCheckBox = (formik, name, label) => (
      <div style={{ marginBottom: 20, width: 500 }}>
        <FormCheck>
          <FormCheck.Input
            {...formik.getFieldProps(name)}
            isInvalid={formik.errors[name] && formik.touched[name]}
            isValid={!formik.errors[name] && formik.touched[name]}
            style={{ borderColor: 'red' }}
            defaultChecked={formik.values[name]}
          />
          <FormCheck.Label>{label}</FormCheck.Label>
        </FormCheck>
      </div>
    );

    renderDatePicker = (props, name, label, isVisible = true) => (
      <div style={{ marginBottom: 20, width: 500, display: isVisible ? '' : 'none' }}>
        <label>{label}</label>
        <DatePicker
          name={name}
          selected={this.state.expectedLicenseDate}
          onChange={props.expectedLicenseDate}
        />
      </div>
    );

    render() {
        const { props } = this.props;
        const showLicensureMessage = false;
        // "Select your expected Licensure areas";
        return (
          <div>
            {this.renderTextInput(props, 'streetAddress', 'Street address *')}
            {this.renderTextInput(props, 'apartmentUnitNumber', 'Apartment / Unit Number')}
            {this.renderTextInput(props, 'city', 'City *')}
            {this.renderSelect(props, 'state', 'State', stateOptions)}
            {this.renderTextInput(props, 'zipCode', 'Zip Code *')}
            {/* {this.renderTextInput(props, 'phoneNumber', 'Phone Number *')} */}
            {this.renderNumberInput(props, 'phoneNumber', 'Phone Number *')}
            {this.renderSelect(props, 'hasTeachingLicense', 'Do you have a teaching license? *', trueFalseOptions)}
            {this.renderSelect(props, 'teachingLicenseType', 'Type of teaching license', licenseTypeOptions, props.values.hasTeachingLicense, true)}
            {this.renderSelect(props, 'hasArTeachingLicense', 'Are you licensed to teach in Arkansas?', trueFalseOptions, props.values.hasTeachingLicense)}
            {this.renderTextInput(props, 'licensureAndState', 'What is your Licensure and State?', props.values.hasTeachingLicense === true && props.values.hasArTeachingLicense === false)}
            {this.renderSelect(props, 'selectedLicensure', 'Select all the licensures that apply', licensureOptions, props.values.hasTeachingLicense, true)}
            {this.renderSelect(props, 'towardsTeachingLicense', 'Are you working towards a licensure?', trueFalseOptions, !props.values.hasTeachingLicense)}
            {this.renderDateArea(props, 'expectedLicenseDate', 'When do you expect to receive a teaching license? *', props.values.towardsTeachingLicense === true && props.values.hasTeachingLicense === false)}
            {this.renderSelect(props, 'willToWorkTowardsLicense', 'Are you willing to work towards a licensure? *', trueFalseOptions, props.values.hasTeachingLicense === false && props.values.towardsTeachingLicense === false)}
            {this.renderSelect(props, 'teachingLicenseWorkingTowards', 'Select the teaching license you are working towards', licensureOptions, props.values.hasTeachingLicense === false && props.values.towardsTeachingLicense===true, true)}
            {this.renderSelect(props, 'degreeLevel', 'Highest level of degree *', degreeOptions)}
            {this.renderSelect(props, 'institution', 'Institution (Select "Other (please specify)" if the institution is outside of Arkansas) *', institutionOptions)}
            {this.renderTextInput(props, 'institutionOther', 'Name of Institution', props.values.institution === 'Other')}
            {this.renderSelect(props, 'optionalDegreeHours', 'Hours put into next degree level *', hoursOptions)}
            {this.renderSelect(props, 'yearsOfExperience', 'Years of experience in education *', hoursOptions)}
            {this.renderTextArea(props, 'extracurricularActivities', 'Extracurricular activities willing to sponsor')}
            {this.renderTextArea(props, 'educationalPhilosophy', 'Describe your philosophy of education')}
            {this.renderTextArea(props, 'contribution', 'What do you consider to be your most important contribution to the field of education and/or most significant accomplishment as a school employee?')}
            {this.renderSelect(props, 'designations', 'What additional designations do you have?', designationOptions)}
            {this.renderTextInput(props, 'microcredentials', 'Micro-credentials')}
            {this.renderSelect(props, 'legallyAbleToWork', 'Are you legally able to work in the US? *', trueFalseOptions)}
            {this.renderSelect(props, 'veteranOrSpouse', 'Are you a veteran or the spouse of a deceased veteran? *', trueFalseOptions)}
            {this.renderSelect(props, 'violationOfLaw', 'Have you been convicted of a violation of law other than a minor traffic violation? *', trueFalseOptions)}
            {this.renderTextInput(props, 'violationOfLawExplain', 'Can you elaborate?', !!props.values.violationOfLaw)}
            {this.renderSelect(props, 'revokedCertificate', 'Have you had a professional certificate revoked or suspended before? *', trueFalseOptions)}
            {this.renderTextInput(props, 'revokedCertificateExplain', 'Can you elaborate?', !!props.values.revokedCertificate)}
            {this.renderSelect(props, 'sexuallyAbusedChild', 'Have you been convicted of an offense for physical or sexual abuse of a child before? *', trueFalseOptions)}
            {this.renderTextInput(props, 'sexuallyAbusedChildExplain', 'Can you elaborate?', !!props.values.sexuallyAbusedChild)}
            {this.renderSelect(props, 'substantiatedChildAbuseCharge', 'Has a charge of child abuse against you been substantiated before? *', trueFalseOptions)}
            {this.renderTextInput(props, 'substantiatedChildAbuseChargeExplain', 'Can you elaborate?', !!props.values.substantiatedChildAbuseCharge)}
            {this.renderSelect(props, 'hasBeenFired', 'Have you been involuntarily terminated or asked to resign, or resigned in lieu of termination from the employment of another school district before? *', trueFalseOptions)}
            {this.renderTextInput(props, 'hasBeenFiredExplain', 'Can you elaborate?', !!props.values.hasBeenFired)}
          </div>
        );
    }
}

export default TeacherInformationForm;
