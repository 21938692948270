import { auth } from '../../firebase/Firebase';
import * as types from './types';
import django from '../../apis/django';
import { getRequestConfig } from '../../apis/firebaseTokenSupport';

const applyJobWithUserDetails = async (
    dispatch,
    response,
    job,
    resumeId,
    userId,
    transcriptId,
    letterIds,
    extraEssays,
    coverLetter
) => {
    const userData = response.data;
    const jobCreate = {
        applicant: userData.id,
        firebaseId: "",
        job: job.id,
        resumeId,
        transcriptId,
        letterIds,
        extraEssays,
        coverLetter,
        rejected: false,
        proceedStatus: null,
        dateApplied: new Date()
    }
    if (userData) {
        const config = await getRequestConfig();
        django.post(`/jobs/applications/`, jobCreate, config)
            .then(() => applyJobSuccess(dispatch, job))
            .catch(error => applyJobFail(dispatch, error));
    } else {
        applyJobFail(dispatch, 'User data was empty!');
    }
};

export const applyJob = (
    job,
    resumeId,
    transcriptId,
    letterIds,
    extraEssays,
    coverLetter
) => async dispatch => {
    dispatch({ type: types.TEACHER_APPLY_JOB, payload: job.id });
    const userId = auth.currentUser.uid;
    var response;
    try {
        const config = await getRequestConfig();
        response = await django.get(`/teachers/${userId}/`, config);
    } catch (error) {
        applyJobFail(dispatch, error);
    }

    try {
        await applyJobWithUserDetails(
            dispatch,
            response,
            job,
            resumeId,
            userId,
            transcriptId,
            letterIds,
            extraEssays,
            coverLetter
        )
    } catch (error) {
       applyJobFail(dispatch, error);
    }
};

export const getAppliedTo = (dispatch, querySnapshot, job) => {
    if (!querySnapshot.empty) {
        applyJobSuccess(dispatch, job);
    } else {
        // In the future, check to see if a job can really be applied to here
        openForApplications(dispatch, job);
    }
};

const openForApplications = (dispatch, job) => {
    dispatch({ type: types.OPEN_JOB_FOR_APPLICATIONS, payload: job.id });
};


// helper
const applyJobSuccess = (dispatch, job) => {
    dispatch({
        type: types.TEACHER_APPLY_JOB_SUCCESS,
        payload: job.id,
    });
};

const applyJobFail = (dispatch, error) => {
    console.dir(error, {depth:null});
    console.log('there was a problem applying to this job.');
    dispatch({
        type: types.TEACHER_APPLY_JOB_FAIL,
    });
};
