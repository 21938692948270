import React, { useMemo, useCallback } from 'react';
// import Dropzone from 'react-dropzone';
import csv from 'csv';
import Papa from 'papaparse';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  minHeight: 500,
  minWidth: 600,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

function UploadCsv(props) {
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
          const reader = new FileReader();

          reader.onabort = () => console.log('file reading was aborted');
          reader.onerror = () => console.log('file reading has failed');
          reader.onload = () => {
            const binaryStr = reader.result;
            console.log(binaryStr);
              try {
                  console.log('trying it our');
                  Papa.parse(binaryStr, (err, data) => {
                    console.log('I am here');
                });
                // csv.pipe(
                //     csv.parse(binaryStr, (err, data) => {
                //         console.log('here');
                //         for (let i = 1; i <= data.length - 1; i++) {
                //             const lea = data[i][0];
                //             const districtName = data[i][1];
                //             const title = data[i][2];
                //             const description = data[i][3];
                //             const selectedLicensure = data[i][5];
                //             const newUser = {
                //                 lea,
                //                 districtName,
                //                 title,
                //                 description,
                //                 selectedLicensure
                //              };
                //             // guestList.push(newUser);

                //             console.log(newUser);
                //         }
                //     }),
                // );
              } catch (err) {
                  console.log(err);
              }

          // Do whatever you want with the file contents
          };
          reader.readAsArrayBuffer(file);
        });
      }, []);
    //   const {getRootProps, getInputProps} = useDropzone({onDrop})
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
    </div>
  );
}

export default UploadCsv;
