import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './Faqs.styles';
import PageLayout from '../PageLayout/PageLayout';
import formPic from '../../assets/form.png';
import formPic1 from '../../assets/1.png';
import formPic2 from '../../assets/2.png';
import formPic3 from '../../assets/3.png';
import formPic4 from '../../assets/4.png';
import formPic5 from '../../assets/5.png';
import formPic6 from '../../assets/6.png';
import formPic7 from '../../assets/successEmail.png';
import formPic8 from '../../assets/8.png';
import formPic9 from '../../assets/10.png';
import formPic10 from '../../assets/11.png';
import formPic11 from '../../assets/12.png';
import formPic12 from '../../assets/13.png';
import formPic13 from '../../assets/14.png';
import Theme from '../../theme/Theme';

class Faqs extends Component {
  state = {
    currentFaqs: 'Teacher',
  };

  renderTeacherFaqs = () => (
    <div style={{ textAlign: 'left' }}>
      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>Who can create an account?</strong> <br />
        Anyone interested in applying for a teaching position in Arkansas can
        create a teacher account on ARteachers.org. It’s free and easy!
        In order to post a job, the superintendent of record for
        a Public school district in Arkansas must approve personnel to post the jobs
        for his/her district. It’s free and easy!
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>How do I signup for a teacher account? </strong><br />
        Click the “Login” button,
        and then click “Sign Up”.  Select “Teacher” and fill in the form.
        On the next page click the red banner to finish your “setup” application. You must complete
        all required fields (marked with an *). Once your application is complete, you can click “Apply”,
        and will be prompted to complete any additional information requested by the district.
        <img src={formPic} style={styles.image} alt="form" />
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>How do I apply for a teaching position?</strong><br />
        It’s easy and free!  Step 1: Create an account and complete the application.
        Once your application is complete, you can click the “Apply” button for a specific
        job posting, and will be prompted to complete any additional information requested by the district.
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>Where do the job postings on ARteachers.org come from?</strong><br />
        ARteachers.org relies on school districts to post their vacancies.
        In order to post a job, the superintendent of record for a Public school district
        in Arkansas must approve the personnel to post the jobs for his/her district.
        Note: ARteachers.org does not verify the job postings.
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>What types of files does ARteachers.org accept
          for Resume, Reference, or Transcript?
        </strong><br />
        All files attached to your application must be in pdf format. If you need to upload
        an image or scan, you must convert it. Note: Support for other file formats would be added
        in later release.
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>Do I have to re-upload the files for each job I apply to?</strong><br />
        No! The files you initially select will still be associated with your application, but you CAN select different
        documents based on your preferences (see below).
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>Can I upload different attachments
          for each job posting to which I apply?
        </strong><br />
        Yes! You can upload different files in ‘settings’ and select the files that you want to use for each job posting.
        Please note that the files to be uploaded should be in .pdf format.
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>Is this a secure website, and is my data secure?</strong><br />
        Yes. All user data are stored in Firebase, which is based on Google’s Cloud Storage.
        We know it is secure because it is a trusted cloud provider used by people like Twitter and Snapchat.
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>I forgot my username or password. How can I retrieve it?</strong><br />
        From the home page, click the link "I Forgot My Password." Enter your email address into the
        box and information to reset your password will be sent to that email address.
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>Can I save my incomplete application and
          return to complete it at a later time?
        </strong><br />
        Nope. You must complete the required field in the setup application and hit “submit” to save
        the information. You may, however, edit your application and/or upload additional documents
        at a later time.
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>Who will see my information?</strong><br />
        If you select to let ARteachers.org share your information, districts will be able to ‘look for’ you
        based on your qualifications. If you do not select that option, only authorized personnel in the
        district(s) to which you apply will see your information. Anonymized versions of the
        information collected through ARteachers.org will be available to authorized researchers at the
        University of Arkansas.
      </p>
    </div>
    )

  renderDistrictFaqs = () => (
    <div style={{ textAlign: 'left' }}>
      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>Who can post a job?</strong> <br />
        Any public school in Arkansas can post a job on ARteachers.org. It’s free and easy!
        The superintendent of record for the school district in Arkansas must approve personnel to post the jobs for his/her district.
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>How do I sign up for a district account? </strong><br />
        Click the “Login” button, <br />
        <img src={formPic1} style={{ width: 200, height: 50, }} alt="form" /><br /><br />

        and then click “Sign Up”.<br /><br />
        <img src={formPic2} style={{ width: 400, height: 300, }} alt="form" /><br /> <br />
        On the next page select “District” and fill in the form.<br /><br />
        <img src={formPic3} style={{ width: 300, height: 400, }} alt="form" /><br /><br />
        The ARteachers.org system will send an email to the Superintendent to verify that you may post jobs for the district.
        Once verified, you will get an email and be able to sign in to create a job posting.<br />
        <img src={formPic7} style={{ width: 500, height: 300, }} alt="form" />
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>How do I post a job?</strong><br />
        On the Home page, click on the button labeled “Start new job posting” then fill out the required information.
        Once completed, click “Submit new job” and your post is complete.<br />
        <img src={formPic8} style={{ width: 600, height: 300, }} alt="form" /><br /><br />
        <img src={formPic4} style={{ width: 300, height: 400, }} alt="form" /><br />
        <img src={formPic5} style={{ width: 200, height: 70, }} alt="form" /><br />
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>Can I edit my post after posting?</strong><br />
        Yes you can. On the right-hand side of your post, if you hover over the three vertical dots,
        you will see two options: “Edit the posting” or “Close the job posting.” You can choose to close
        or edit the post at this time.<br /><br />
        <img src={formPic6} style={{ width: 600, height: 300, }} alt="form" />
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>Can I customize the essay questions?
        </strong><br />
        You bet. On the <b>Settings</b> tab, you will see the options to add or remove essay questions there.<br />
        <img src={formPic9} style={{ width: 600, height: 100, }} alt="form" />
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>Can I save my incomplete post and return to complete it at a later time?</strong><br />
        Nope. You must complete the required field in the setup application and hit “Submit new job”
        to save the information. You may, however, edit your post at a later time.
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>How do I review applicants?
        </strong><br />
        When you login, the red box under each posting shows you the number of applicants.<br />
        <img src={formPic10} style={{ width: 400, height: 200, }} alt="form" /><br /><br />
        Clicking on the box brings up the names of the applicants.<br />
        <img src={formPic11} style={{ width: 500, height: 500, }} alt="form" /><br />
        Clicking on the applicant name brings up a new window with all the applicant information
        and documents (resume, transcript, references). You can download and save the documents,
        or you can print. You can email the applicant directly from the page by clicking <b>“send email”</b>.
        You can make note if you want to proceed with
        the applicant or not by clicking the appropriate button.<br />
        <img src={formPic12} style={{ width: 700, height: 400, }} alt="form" /><br />
        Applicants for whom you select “Proceed with this applicant” will be highlighted in
        green in the applicant list.<br />
        <img src={formPic13} style={{ width: 400, height: 100, }} alt="form" />

      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>How do I hire a teacher?</strong><br />
        That is up to you! We know that you have a process- we just want to get you matched up!
        But please go in and mark the job as “Closed” after you hire.
      </p>

      <p style={{ fontStyle: 'italic' }}>
        <strong style={styles.quesHead}>I forgot my username or password. How can I retrieve it?</strong><br />
        From the home page, click the link "I Forgot My Password." Enter your email address into the
        box and information to reset your password will be sent to that email address.
      </p>

      <p style={{ fontStyle: 'italic' }}>
        Have more questions? Email <a href="mailto:ARteachermatch@gmail.com">
          ARteachermatch@gmail.com
                                   </a>
        <br />

      </p>

    </div>
    )

  renderFaqsPage = () => {
    switch (this.state.currentFaqs) {
      case 'Teacher': {
        return this.renderTeacherFaqs();
      }
      case 'District': {
        return this.renderDistrictFaqs();
      }
      default: {
        return null;
      }
    }
  };

  render() {
    const { currentFaqs } = this.state;
    return (
      <PageLayout>
        <h1 style={{ display: 'inline-block' }}>FAQs</h1>
        <div style={{
          display: 'inline-block',
          align: 'center',
        }}
        >
          <b
            onClick={() => this.setState({ currentFaqs: 'Teacher' })}
            style={{
              margin: 55,
              fontWeight: currentFaqs === 'Teacher' ? 'bold' : 'normal',
              color: currentFaqs === 'Teacher' ? Theme.ThemePrimary : 'black',
              cursor: 'pointer',
            }}
          >
            Teacher FAQs
          </b>
          <b
            onClick={() => this.setState({ currentFaqs: 'District' })}
            style={{
              margin: 90,
              fontWeight: currentFaqs === 'District' ? 'bold' : 'normal',
              color: currentFaqs === 'District' ? Theme.ThemePrimary : 'black',
              cursor: 'pointer',
            }}
          >
            District FAQs
          </b>
        </div>

        <div style={styles.pageDiv}>
          {this.renderFaqsPage()}
        </div>
      </PageLayout>
    );
  }
}

export default withRouter(Faqs);
