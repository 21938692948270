import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, ButtonToolbar } from 'react-bootstrap';
import {
  changeTitle,
  changeLink,
  changeDescription,
  createNewJob,
  cancelNewJob,
} from '../../state/actions/newJobForm';
import LicensureSelect from '../LicensureSelect/LicensureSelect';
import GradeLevelSelect from '../GradeLevelSelect/GradeLevelSelect';
import CustomButton from '../CustomButton/CustomButton';
import styles from './NewJobPosting.styles';
import { timeOptions } from '../CustomSelect/Options';

class NewJobPosting extends Component {
  state = {
    selectedQuestions: [],
    selectedLicensure: [],
    selectedGrade: [],
    selectedPartOrFullTime: [],
    expirationDate: null,
  };

  onTitleTextChange = e => {
    this.props.changeTitle(e.target.value);
  };

  onDescriptionTextChange = e => {
    this.props.changeDescription(e.target.value);
  };

  onLinkChange = e => {
    // console.log("the link we are looking for" + e.target.value);
    this.props.changeLink(e.target.value);
  };

  onExpirationDateChange = e => {
    this.setState({
      expirationDate: e.target.value
    });
  };

  onSubmitNewJobClick = () => {
    const { selectedQuestions, selectedLicensure, selectedPartOrFullTime, selectedGrade, expirationDate } = this.state;

    this.props.createNewJob(
      this.props.title,
      this.props.description,
      selectedQuestions,
      selectedLicensure,
      selectedGrade,
      this.props.employerData,
      this.props.externalEmployerData,
      selectedPartOrFullTime,
      this.props.externalLink,
      expirationDate,
    );
  };

  handleSelectedQuestionsChanged = questions => {
    this.setState({
      selectedQuestions: questions,
    });
  };

  handleLicensureSelectChanged = licensure => {
    this.setState({
      selectedLicensure: licensure,
    });
  };

  handleSelectedGradesChanged = grades => {
    this.setState({
      selectedGrade: grades,
    });
  };

  render() {
    const submitDisabled =
      this.props.title.length <= 0 ||
      this.props.description.length <= 0 ||
      this.state.selectedLicensure.length <= 0 ||
      this.props.isPosting;
    const submitText = this.props.isPosting
      ? 'Submitting...'
      : 'Submit new job';

    // the textarea has a bug after you first click on it for some reason
    return (
      <div style={styles.formCenterStyle}>
        <Form>
          <Form.Group controlId="newJobTitle">
            <Form.Label>Job title *</Form.Label>
            <Form.Control
              type="text"
              value={this.props.title}
              placeholder="Enter job title"
              onChange={this.onTitleTextChange}
            />
          </Form.Group>

          <Form.Group controlId="newJobDescription">
            <Form.Label>Job description *</Form.Label>
            <Form.Control
              as="textarea"
              value={this.props.description}
              placeholder="Enter job description"
              onChange={this.onDescriptionTextChange}
            />
          </Form.Group>
          <br />

          <Form.Label>Desired areas of licensure *</Form.Label>
          <LicensureSelect
            value={this.state.selectedLicensure}
            onChange={this.handleLicensureSelectChanged}
          />
          <br />

          <div style={{ marginBottom: 20, width: 318 }}>
            <label>(Optional) Is this job full or part-time?</label>
            <Select
              onChange={e => this.setState({ selectedPartOrFullTime: e })}
              options={timeOptions}
            />
          </div>

          <Form.Label>(Optional) Extra essay questions</Form.Label>
          <br />
          <em>
            To add more questions to your pool, go to your{' '}
            <Link to="/employersettings">settings page</Link>
          </em>
          <Select
            value={this.state.selectedQuestions}
            components={makeAnimated()}
            onChange={this.handleSelectedQuestionsChanged}
            placeholder="Select additional essay questions..."
            options={this.props.questions}
            isMulti
          />
          <br />

          <Form.Label>(Optional) Grade levels (if applicable)</Form.Label>
          <GradeLevelSelect
            value={this.state.selectedGrade}
            onChange={this.handleSelectedGradesChanged}
          />
          <br />

          <Form.Group controlId="expirationDate">
            <Form.Label>(Optional) Posting expiration date</Form.Label>
            <Form.Group>
              <Form.Control
                as="textarea"
                rows="1"
                placeholder="mm/dd/yy"
                value={this.state.expirationDate ? this.state.expirationDate : ''}
                onChange={this.onExpirationDateChange}
              />
            </Form.Group>
          </Form.Group>
          <br />

          <Form.Group controlId="externalLink">
            <Form.Label>(Optional) External Apply Link</Form.Label>
            <Form.Control
              type="text"
              value={this.props.externalLink}
              placeholder="https://www.applylink.com"
              onChange={this.onLinkChange}
            />
          </Form.Group>
          <br />

          <ButtonToolbar style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
            <CustomButton
              variant="default-contained"
              isDisabled={submitDisabled}
              onClick={this.onSubmitNewJobClick}
            >
              {submitText}
            </CustomButton>
          </ButtonToolbar>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  title: state.newJobForm.title,
  description: state.newJobForm.description,
  externalLink: state.newJobForm.externalLink,
  isPosting: state.newJobForm.isPosting,
  questions: state.employer.questions,
});

export default connect(
  mapStateToProps,
  {
    changeTitle,
    changeLink,
    changeDescription,
    createNewJob,
    cancelNewJob,
  }
)(NewJobPosting);
