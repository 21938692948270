import * as types from '../actions/types';

const INITIAL_STATE = {
  email: '',
  password: '',
  user: null,
  userIsEmployer: null,
  userIsAdmin: null,
  userData: null,
  isLoggingIn: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOGIN_USER:
      return { ...state, isLoggingIn: true };
    case types.LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoggingIn: false,
      };
    case types.LOGIN_USER_FAIL:
      // do something here
      return {
        ...state,
        isLoggingIn: false,
      };
    case types.SET_USER_IS_EMPLOYER:
      return { ...state, userIsEmployer: action.payload };
    case types.SET_USER_IS_ADMIN:
      return { ...state, userIsAdmin: action.payload };
    case types.GET_USER_DATA:
      // selectedLicensure, teachingLicenseWorkingTowards, and teachingLicenseType
      // are all JavaScript objects. They have to be JSON.stringify()'d before being
      // sent to the backend. When they come back from the backend, they have to be
      // JSON.parse()'d back into JavaScript objects.
      return { ...state, userData: { ...action.payload, selectedLicensure: action.payload.selectedLicensure ? JSON.parse(action.payload.selectedLicensure): [], teachingLicenseWorkingTowards: action.payload.teachingLicenseWorkingTowards ? JSON.parse(action.payload.teachingLicenseWorkingTowards) : [], teachingLicenseType: action.payload.teachingLicenseType ? JSON.parse(action.payload.teachingLicenseType) : [] }};
    case types.UPDATE_CACHED_TEACHER_DATA: {
      const newUserData = { ...state.userData, ...action.payload, selectedLicensure: action.payload.selectedLicensure ? JSON.parse(action.payload.selectedLicensure): [], teachingLicenseWorkingTowards: action.payload.teachingLicenseWorkingTowards ? JSON.parse(action.payload.teachingLicenseWorkingTowards) : [], teachingLicenseType: action.payload.teachingLicenseType ? JSON.parse(action.payload.teachingLicenseType) : [] };
      return { ...state, userData: newUserData };
    }
    default:
      return state;
  }
};
