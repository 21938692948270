import * as types from './types';

export const beginJobApplication = jobId => ({
  type: types.BEGIN_JOB_APPLICATION,
  payload: jobId,
});

export const endJobApplication = jobId => ({
  type: types.END_JOB_APPLICATION,
  payload: jobId,
});

export const selectResume = (jobId, resumeId) => {
  const payload = { [jobId]: resumeId };
  return {
    type: types.SELECT_RESUME,
    payload,
  };
};
