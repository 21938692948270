/* eslint-disable react/sort-comp */
// Similar to Jobs.js, but for the employer view
import React, { Component } from 'react';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TimeAgo from 'react-timeago';
import Fuse from 'fuse.js';
import { connect } from 'react-redux';
import { Form, ButtonToolbar } from 'react-bootstrap';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import ApplicantListItem from '../ApplicantListItem/ApplicantListItem';
import CustomButton from '../CustomButton/CustomButton';
import CustomCard from '../CustomCard/CustomCard';
import {
  updatePostingTitle,
  updatePostingDescription,
  updatePostingGradeLevel,
  updatePostingLicensure,
  updatePostingPartOrFullTime,
  updatePostingQuestions,
  updateJobPostingStatus,
  updatePostingExpirationDate
} from '../../state/actions/postings';
import './Icon.css';
import styles from '../NewJobPosting/NewJobPosting.styles';
import LicensureSelect from '../LicensureSelect/LicensureSelect';
import Select from 'react-select';
import { timeOptions } from '../CustomSelect/Options';
import { Link } from 'react-router-dom';
import makeAnimated from 'react-select/animated';
import GradeLevelSelect from '../GradeLevelSelect/GradeLevelSelect';

class Posting extends Component {
  state = {
    expanded: false,
    searchResults: [],
    stringSearch: '',
    hasDoneFirstSearch: false,
    isEditing: false,
    modifiedTitle: this.props.posting.title,
    modifiedDescription: this.props.posting.description,
    modifiedQuestions: this.props.posting.selectedQuestions,
    modifiedLicensure: this.props.posting.selectedLicensure,
    modifiedGrade: this.props.posting.selectedGradeLevels,
    modifiedPartOrFullTime: this.props.posting.selectedPartOrFullTime,
    modifiedExpirationDate: this.props.posting.expirationDate,
    anchorEl: null,
  };

  onApplicantClick(resumeLink) {
    const win = window.open(resumeLink, '_blank');
    win.focus();
  }

  onSearchTextChange = e => {
    this.setState({ stringSearch: e.target.value });
    this.updateSearchResults(e.target.value);
  };

  getTimeAgo = () => {
    if (this.props.posting.isClosed && this.props.posting.dateClosed) {
      return this.props.posting.dateClosed;
    }
    return this.props.posting.datePosted;
  };

  updateSearchResults = searchString => {
    this.setState({ hasDoneFirstSearch: true });

    // If no inputs are good, searchResults contains all applicants.
    let currentResults = this.props.applicants;
    // TODO: description matching is wonky, can we fix this?
    // TODO: Search in more fields (district name, etc)
    if (searchString !== '') {
      const options = {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ['firstName', 'lastName'],
      };
      const fuse = new Fuse(currentResults, options);
      currentResults = fuse.search(searchString);
    }

    // update the final search results
    this.setState({ searchResults: currentResults });
  };

  renderApplicant(applicant, jobId) {
    // TODO: optimize to only send the relevant resume link
    const { proceedStatus } = applicant;
    const { firstName, lastName, id } = applicant;
    const nameString = `${firstName} ${lastName}`;
    const key = `${jobId}${applicant.id}`
    const linkString = `/application/${jobId}/${applicant.id}`;
    return (
      <ApplicantListItem
        key={key}
        nameString={nameString}
        linkTo={linkString}
        proceedStatus={proceedStatus}
      />
    );
  }

  renderApplicantResults = () => {
    const { posting } = this.props;
    if (!this.state.hasDoneFirstSearch) {
      return this.props.applicants.map(applicant => this.renderApplicant(applicant, posting.id));
    }
    if (this.state.searchResults.length === 0) {
      return <div>No search results found!</div>;
    }
    return this.state.searchResults.map(applicant => this.renderApplicant(applicant, posting.id));
  };

  renderSearch = () => (
    <div>
      <form style={{ display: 'flex', flexWrap: 'wrap' }}>
        <TextField
          id="standard-name"
          label="Search"
          margin="normal"
          helperText="Search for applicants"
          value={this.state.stringSearch}
          onChange={this.onSearchTextChange}
          style={{ marginLeft: 10, marginRight: 10 }}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
            }
          }}
        />
      </form>
    </div>
  );

  renderMenu = () => (
    <Menu
      id="long-menu"
      anchorEl={this.state.anchorEl}
      keepMounted
      open={!!this.state.anchorEl}
      onClose={() => this.setState({ anchorEl: null })}
    >
      <MenuItem
        onClick={() => {
          this.setState({ isEditing: true });
          this.setState({ anchorEl: null });
          // this.renderUpdatePost();
        }}
      >
        Edit the posting
      </MenuItem>
      <MenuItem
        onClick={() => {
          this.props.updateJobPostingStatus(this.props.posting, !this.props.posting.isClosed);
          this.setState({ anchorEl: null });
        }}
      >
        {`${this.props.posting.isClosed ? 'Open' : 'Close'} this job posting`}
      </MenuItem>
    </Menu>
  );

  handleSelectedQuestionsChanged = questions => {
    if (questions !== '') {
    this.setState({
      modifiedQuestions: questions,
    });
  }
  };

  handleLicensureSelectChanged = licensure => {
    if (licensure !== '') {
    this.setState({
      modifiedLicensure: licensure
    });
  }
  };

  handleSelectedGradesChanged = grades => {
    if (grades !== '') {
    this.setState({
      modifiedGrade: grades,
    });
  }
  };

  handleExpirationDateChanged = e => {
    this.setState({
      modifiedExpirationDate: e.target.value,
    });
  };

  renderEditForm = () =>
    // the textarea has a bug after you first click on it for some reason
     (
       <div style={styles.formCenterStyle}>
         <Form>
           <Form.Group controlId="newJobTitle">
             <Form.Label>Job title *</Form.Label>
             <Form.Control
               type="text"
               defaultValue={this.props.posting.title}
               placeholder="Enter job title"
               onChange={(e) => this.setState({ modifiedTitle: e.target.value })}
             />
           </Form.Group>

           <Form.Group controlId="newJobDescription">
             <Form.Label>Job description *</Form.Label>
             <Form.Control
               as="textarea"
               defaultValue={this.props.posting.description}
               placeholder="Enter job description"
               onChange={(e) => this.setState({ modifiedDescription: e.target.value })}
             />
           </Form.Group>
           <Form.Label>Desired areas of licensure *</Form.Label>
           <LicensureSelect
             defaultValue={this.props.posting.selectedLicensure}
             onChange={this.handleLicensureSelectChanged}
           />

           <br />

           <div style={{ marginBottom: 20, width: 318 }}>
             <label>(Optional) Is this job full or part-time?</label>
             <Select
               onChange={e => this.setState({ modifiedPartOrFullTime: e })}
               defaultValue={this.props.posting.selectedPartOrFullTime}
               options={timeOptions}
             />
           </div>

           <Form.Label>(Optional) Extra essay questions</Form.Label>
           <br />
           <em>
             To add more questions to your pool, go to your{' '}
             <Link to="/employersettings">settings page</Link>
           </em>
           <Select
             defaultValue={this.props.posting.selectedQuestions}
             components={makeAnimated()}
             onChange={this.handleSelectedQuestionsChanged}
             placeholder="Select additional essay questions..."
             options={this.props.questions}
             isMulti
           />
           <br />

           <Form.Label>(Optional) Grade levels (if applicable)</Form.Label>
           <GradeLevelSelect
             defaultValue={this.props.posting.selectedGradeLevels}
             onChange={this.handleSelectedGradesChanged}
           />
           <br />

           <Form.Group controlId="expirationDate">
            <Form.Label>(Optional) Posting expiration date</Form.Label>
            <Form.Group>
              <Form.Control
                as="textarea"
                rows="1"
                placeholder="mm/dd/yy"
                defaultValue={this.props.posting.expirationDate}
                onChange={this.handleExpirationDateChanged}
              />
            </Form.Group>
          </Form.Group>
          <br />
           {/* <Form.Group controlId="newExternalLink">
            <Form.Label>External Job Link</Form.Label>
            <Form.Control
              type="text"
              defaultValue={this.props.posting.externalLink}
              placeholder="Enter external application link"
              onChange={(e) => this.setState({ modifiedTitle: e.target.value })}
            />
          </Form.Group>
          <br/> */}

           <ButtonToolbar style={{ display: 'flex', justifyContent: 'center', marginTop: 100 }}>
             {this.renderCardEditButtons()}
           </ButtonToolbar>
         </Form>
       </div>
    )

  // to view job posting
  renderUpdatePost = () => {
    const { isEditing } = this.state;
    if (isEditing) {
      return (
        <Dialog
          open={this.state.isEditing}
          onClose={() => this.handleClose}
        >

          <div style={{ padding: 10 }}>
            {/* <NewJobPosting */}
            {/*    employerData={this.props.posting} */}
            {/*    externalEmployerData={this.props.externalEmployerData} */}
            {/* /> */}

            {this.renderEditForm()}
          </div>
          <DialogActions>
            {/* <Button onClick={() => this.props.history.replace('/login')} color="primary" autoFocus> */}
            {/*  Ok */}
            {/* </Button> */}
          </DialogActions>
        </Dialog>
      );
    }
  };
  /// //////////////////////

  renderPostHeader = () => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {this.renderTitle()}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography color="textSecondary">
          {this.props.posting.isClosed ? 'Closed' : 'Posted'} <TimeAgo
            date={this.getTimeAgo()}
          />
        </Typography>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
          size="large">
          <MoreVertIcon />
        </IconButton>
        {this.renderMenu()}
      </div>
    </div>
  );

  renderTitle = () => {
    const { isEditing } = this.state;

    // if (isEditing) {
    //   return (
    //     <div style={{ display: 'flex', alignItems: 'center' }}>
    //       <TextField
    //         id="standard-name"
    //         defaultValue={this.props.posting.title}
    //         style={{ width: 400 }}
    //         inputProps={{
    //           style: { fontSize: 22 }
    //         }}
    //         onChange={(e) => this.setState({ modifiedTitle: e.target.value })}
    //       />
    //     </div>
    //   );
    // }
    return (
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
      >
        {this.props.posting.title}
      </Typography>
    );
  };

  renderDescription = () => {
    const { isEditing } = this.state;

    // if (isEditing) {
    //   return (
    //     <div style={{ display: 'flex', alignItems: 'center' }}>
    //       <TextField
    //         id="standard-name"
    //         multiline
    //         defaultValue={this.props.posting.description}
    //         style={{ width: '100%' }}
    //         inputProps={{
    //           style: { fontSize: 13 }
    //         }}
    //         onChange={(e) => this.setState({ modifiedDescription: e.target.value })}
    //       />
    //     </div>
    //   );
    // }
    return (
      <Typography>
        {this.props.posting.description}
      </Typography>
    );
  };

  renderCardEditButtons = () => {
    const { modifiedDescription, modifiedLicensure,
      modifiedGrade, modifiedPartOrFullTime, modifiedQuestions, modifiedTitle, modifiedExpirationDate, isEditing } = this.state;
    const { posting } = this.props;
    
    if (isEditing) {
      return (
        <CardActions>
          <CustomButton
            variant="success-contained"
            onClick={async () => {
              this.setState({ isEditing: false });
              await this.props.updatePostingDescription(posting, modifiedDescription);
              await this.props.updatePostingTitle(posting, modifiedTitle);
              await this.props.updatePostingLicensure(posting, modifiedLicensure);
              await this.props.updatePostingGradeLevel(posting, modifiedGrade);
              await this.props.updatePostingPartOrFullTime(posting, modifiedPartOrFullTime);
              await this.props.updatePostingQuestions(posting, modifiedQuestions);
              await this.props.updatePostingExpirationDate(posting, modifiedExpirationDate);
              // console.log(modifiedGrade);
            }}
          >
            Save
          </CustomButton>
          <CustomButton
            onClick={() => this.setState({
              isEditing: false,
              modifiedDescription: posting.description,
              modifiedTitle: posting.title
            })}
          >
            Cancel
          </CustomButton>
        </CardActions>
      );
    }

    return null;
  };

  render() {
    const { applicants } = this.props;
    const { expanded } = this.state;

    const canExpand = applicants.length > 0;
    let btnText = canExpand
      ? `View applicants (${applicants.length})`
      : 'No applicants';
    if (expanded) {
      btnText = 'Hide applicants';
    }
    const btnColor = canExpand ? 'primary' : 'secondary';

    return (
      <CustomCard>
        {this.renderPostHeader()}
        {this.renderDescription()}
        {this.renderUpdatePost()}
        <CardActions>
          <CustomButton
            onClick={() => this.setState({ expanded: !expanded })}
            isDisabled={!canExpand}
            variant={btnColor}
          >
            {btnText}
          </CustomButton>
        </CardActions>
        <Collapse in={this.state.expanded}>
          {this.renderSearch()}
          <CardContent>
            <List>{this.renderApplicantResults()}</List>
          </CardContent>
        </Collapse>
      </CustomCard>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  postings: state.employer.postings,
  questions: state.employer.questions,
});

export default connect(
  mapStateToProps,
  {
    updatePostingDescription,
    updatePostingGradeLevel,
    updatePostingPartOrFullTime,
    updatePostingQuestions,
    updatePostingLicensure,
    updatePostingTitle,
    updateJobPostingStatus,
    updatePostingExpirationDate,
  }
)(Posting);
