import React from 'react';
import styles from './Header.styles';
import logo from '../../assets/logo_horiz.png';
import uarkLogo from '../../assets/uofa-horizontal.png';
import { Desktop, Tablet, Mobile } from '../ResponsiveUtilities/BreakPoint';

function Header({ children, onClick }) {
  return (
    <div style={styles.headerContainer}>
      <Desktop>
        <img
          src={uarkLogo}
          alt="University of Arkansas"
          style={{
        height: 50,
        alignSelf: 'center',
        margin: 25,
      }}
        />
        <img
          src={logo}
          alt="University of Arkansas"
          style={{
        height: 75,
        alignSelf: 'center',
        margin: 25,
        cursor: 'pointer',
      }}
          onClick={onClick}
        />
      </Desktop>

      <Tablet>
        <img
          src={uarkLogo}
          alt="University of Arkansas"
          style={{
        height: 50,
        alignSelf: 'center',
        margin: 25,
      }}
        />
        <img
          src={logo}
          alt="University of Arkansas"
          style={{
        height: 75,
        alignSelf: 'center',
        margin: 25,
        cursor: 'pointer',
      }}
          onClick={onClick}
        />
      </Tablet>

      <div style={styles.buttonContainer}>
        {children}
      </div>
    </div>
);
}
export default Header;
