import React, { Component } from 'react';
import TimeAgo from 'react-timeago';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Iframe from 'react-iframe';
import { Page, Text, View, Document, PDFViewer, Link } from '@react-pdf/renderer';
import { auth } from '../../firebase/Firebase';
import {
  getApplicationInfo,
  setApplicantProceedStatus,
} from '../../state/actions/applicationView';
import CustomButton from '../../components/CustomButton/CustomButton';
import styles from './ApplicationView.styles';

// TODO: MAKE THIS WORK WITH NO RESUME SELECTED
// TODO: add feature to edit employerEmail
class ApplicationView extends Component {
  state = {
  };

  componentDidMount() {
    const { jobId, applicationId } = this.props.match.params;
    if (this.props.isLoggingIn == false) {
      if (this.props.user !== null && this.props.userIsEmployer == true) {
        this.props.getApplicationInfo(jobId, applicationId);
      } else {
        this.props.history.push('/');
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { jobId, applicationId } = this.props.match.params;
    if (prevProps.isLoggingIn !== this.props.isLoggingIn || prevProps.appInfo.loaded !== this.props.appInfo.loaded || prevProps.appInfo.failed !== this.props.appInfo.failed) {
      if (this.props.isLoggingIn == false) {
        if (this.props.user !== null && this.props.userIsEmployer == true && this.props.appInfo.loaded == false && this.props.appInfo.failed != true) {
          this.props.getApplicationInfo(jobId, applicationId);
        } else if (this.props.user == null || this.props.userIsEmployer == false) {
          this.props.history.push('/');
        }
      }
    }
  }

  // Create Document Component
  MyDocument = () => {
    const userData = this.props.appInfo.attachedApplicationInfo.applicant;
    const fullName = `${userData.firstName} ${userData.lastName}`;
    const designations = userData.designations ? userData.designations : null;
    const { microcredentials, expectedLicenseDate, licensureAndState, willToWorkTowardsLicense, towardsTeachingLicense, hasArTeachingLicense } = userData;
    const { coverLetter } = this.props.appInfo.attachedApplicationInfo;
    const date = expectedLicenseDate !== '' ? expectedLicenseDate : '[No Date Provided]';
    let { extraEssays } = this.props.appInfo.attachedApplicationInfo;
    var extraEssaysFormatted = []
    if (extraEssays !== null) {
      try {
        extraEssays = JSON.parse(extraEssays);
        if (extraEssays) {
          Object.keys(extraEssays).forEach((value,index) => {
            // Display essay question answers only if the object representing the answer
            // has a key named 'answer'
            if ('answer' in extraEssays[value]) {
              extraEssaysFormatted.push({[value]: extraEssays[value]['answer']})
            }
          });
        }
      } catch (err) {
        // Nothing
      }
    }

    const willing = willToWorkTowardsLicense === true ? `${fullName} is willing to work toward a licensure` : `${fullName} is not willing to work toward a licensure`;

    const workingToward = towardsTeachingLicense === true ? `${fullName} is working toward a licensure and is expected to recieve it on ${date}` :
      `${fullName} is not working toward a licensure. \n ${willing}`;

    const Arlicense = hasArTeachingLicense === true ? `${fullName} is licensed to teach in Arkansas.` : `
      ${fullName} is licensed to teach in ${licensureAndState}.`; // add willing to gain a teaching license in arkansas

    // teachingLicenseType is an array with one or more entries in it: an object with a value and label keys
    // We want to display the value of the label key
    let teachingLicenseTypeJSON = userData.teachingLicenseType;
    var teachingLicenseTypeParsed, teachingLicenseTypeLabels;
    var teachingLicenseType = 'Unknown';
    try {
      teachingLicenseTypeParsed = JSON.parse(teachingLicenseTypeJSON)
      if (Array.isArray(teachingLicenseTypeParsed)) {
        teachingLicenseTypeLabels = teachingLicenseTypeParsed.map(item => (
          item.label
        ));
        teachingLicenseType = teachingLicenseTypeLabels.join(', ')
      }
    } catch (err) {
      // Nothing
    }

    var selectedLicensure = <View><Text style={styles.itemContent}>Unknown</Text></View>
    var selectedLicensureInner = ""
    try {
      var selectedLicensureInner = JSON.parse(userData.selectedLicensure).map((licensure, index) => (
        <View key={index} style={styles.item}>
          <Text style={styles.bulletPoint}>•</Text>
          <Text style={styles.itemContent}>{licensure.label}</Text>
        </View>
      ))
    } catch (err) {
      // Nothing
    }
    if (selectedLicensureInner != "") {
      selectedLicensure = (
        <View>
          {selectedLicensureInner}
        </View>
      )
    }

    const licenseString = userData.hasTeachingLicense === true // whether can teach in Arkansas or not
      ? `${fullName} has a teaching license. \n ${Arlicense} \n Type of license: ${teachingLicenseType}`
      : `${workingToward}`;

    const { goodStyle, badStyle } = styles;
    return (
      <Document title={`Applicant_${userData.firstName}_${userData.lastName}`}>
        <Page style={styles.page}>
          <View style={styles.section}>
            <Text style={{ textAlign: 'center' }}>{`Information for ${fullName}`}</Text>
            <Text style={styles.heading}>Contact Information</Text>
            <View>
              <Text style={styles.text}>
                Email:
                <Link> {` ${userData.email}`} </Link>
              </Text>
              <Text style={styles.text}>{`Phone: ${userData.phoneNumber}`}</Text>
              <Text style={styles.text}>
                {`Address: ${userData.streetAddress}, ${userData.city}, ${
                  userData.state
                  }, ${userData.zipCode}`}
              </Text>
            </View>
            <Text style={styles.heading}>Applicant Credentials</Text>
            <View>
              <View>
                <Text style={styles.subheading}>Teaching License:</Text>
              </View>
              <View>
                <Text style={styles.text}>{licenseString}</Text>
                {/* <Text style={styles.text}>{`Type of license: ${userData.teachingLicenseType}.`}</Text> */}
              </View>
              <View>
                <Text style={styles.subheading}>Licensure Areas:</Text>
              </View>
              {selectedLicensure}
              <Text style={styles.subheading}>Designations:</Text>
              <View>
                <Text style={styles.text}>{designations}</Text>
                {/* {designations.map(designation => (
                <Text key={designation}>{designation}</Text>
              ))} */}
              </View>
              <Text style={styles.subheading}>Degree Information:</Text>
              <View>
                <Text style={styles.text}> {`Received degree from: ${userData.institution}`}</Text>
                <Text style={styles.text}>{`Degree Level: ${userData.degreeLevel}`}</Text>
              </View>
              <Text style={styles.subheading}>Micro-credentials:</Text>
              <View>
                <Text style={styles.text}>{microcredentials}</Text>
              </View>
            </View>
          </View>
        </Page>
        <Page style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.heading}>Essays and Cover Letter</Text>
            <View>
              <Text style={styles.text}>
                Extracurricular activities willing to sponsor:
              </Text>
              <Text style={styles.paragraph}>{userData.extracurricularActivities}</Text>{/* // userData.extracurricularActivities */}

              {/* Render essay questions and responses */}
              {this.renderEssayPDF(
                'Describe your philospohy of education:',
                userData.educationalPhilosophy
              )}

              {this.renderEssayPDF(
                'What do you consider to be your most important contribution to the field of education and/or most significant accomplishment as a school employee?',
                userData.contribution
              )}
              {extraEssaysFormatted.map((value, index) =>
                this.renderEssayPDF(
                  Object.keys(value)[0],
                  Object.values(value)[0],
                  'Additional questions:'
                )
              )}
              {this.renderEssayPDF('Cover letter:',
                coverLetter
              )}
            </View>
          </View>
        </Page>
        <Page style={styles.page}>
          <View style={styles.section}>
            <Text style={styles.heading}>Legal Information</Text>
            <Text style={styles.subheading}>{`${fullName}:`}</Text>
            <View>

              <View style={styles.item}>
                <Text style={styles.bulletPoint}>•</Text>
                <Text style={[userData.legallyAbleToWork ? goodStyle : badStyle, styles.itemContent]}>
                  {`is ${userData.legallyAbleToWork ? '' : 'NOT'} legally able to work in the United States`}
                </Text>
              </View>

              <View style={styles.item}>
                <Text style={styles.bulletPoint}>•</Text>
                <Text style={styles.itemContent}>
                  {`is ${userData.veteranOrSpouse ? '' : 'NOT'
                    } a veteran or a deceased veteran's spouse`}
                </Text>
              </View>

              <View style={styles.item}>
                <Text style={styles.bulletPoint}>•</Text>
                <Text style={[userData.violationOfLaw ? badStyle : goodStyle, styles.itemContent]}>
                  {`has ${userData.violationOfLaw ? '' : 'NOT'} been convicted of a violation of law other than a minor traffic violation before`}
                </Text>
              </View>

              <View style={styles.item}>
                <Text style={styles.bulletPoint}>•</Text>
                <Text style={[userData.revokedCertificate ? badStyle : goodStyle, styles.itemContent]}>
                  {`has ${userData.revokedCertificate ? '' : 'NOT'} had a professional certificate revoked or suspended before`}
                </Text>
              </View>

              <View style={styles.item}>
                <Text style={styles.bulletPoint}>•</Text>
                <Text style={[userData.sexuallyAbusedChild ? badStyle : goodStyle, styles.itemContent]}>
                  {`has ${userData.sexuallyAbusedChild ? '' : 'NOT'} been convicted of an offense for physical or sexual abuse of a child before`}
                </Text>
              </View>

              <View style={styles.item}>
                <Text style={styles.bulletPoint}>•</Text>
                <Text style={[userData.substantiatedChildAbuseCharge ? badStyle : goodStyle, styles.itemContent]}>
                  {`has ${userData.substantiatedChildAbuseCharge ? '' : 'NOT'} had a charge of child abuse substantiated against before`}
                </Text>
              </View>

              <View style={styles.item}>
                <Text style={styles.bulletPoint}>•</Text>
                <Text style={[userData.hasBeenFired ? badStyle : goodStyle, styles.itemContent]}>
                  {`has ${userData.hasBeenFired ? '' : 'NOT'} been involuntarily terminated or asked to resign, or 
                  resigned in lieu of termination from the employment of another school district before`}
                </Text>
              </View>

            </View>
          </View>
        </Page>
      </Document>
    );
  };

  App = () => (
    <div>
      <h3>Applicant Info </h3>
      <PDFViewer style={styles.iframe}>
        {this.MyDocument()}
      </PDFViewer>
    </div>
  );

  // TODO: allow undoing these
  onProceedClick = () => {
    const { jobId, applicationId } = this.props.match.params;
    this.props.setApplicantProceedStatus(jobId, applicationId, 'green');
  };

  onRejectClick = () => {
    const { jobId, applicationId } = this.props.match.params;
    this.props.setApplicantProceedStatus(jobId, applicationId, 'red');
  };

  onGoBackClick = () => {
    this.props.history.goBack()
  };

  renderTitle = () => {
    const { firstName } = this.props.appInfo.attachedApplicationInfo.applicant;
    const { title } = this.props.appInfo.jobInfo;
    const {
      proceedStatus,
      dateApplied,
    } = this.props.appInfo.attachedApplicationInfo;
    const { proceedStatusSending } = this.props.appInfo;
    const canProceed = proceedStatus === null || proceedStatus === '' || proceedStatus === 'red';
    const canReject = proceedStatus === null || proceedStatus === '' || proceedStatus === 'green';

    return (
      <div style={styles.padLeftLightStyle}>
        <h2>
          <b>{firstName}</b> applied to
          <b>{` ${title} `}</b> (
          <TimeAgo date={dateApplied} />)
        </h2>
        <span>
          <CustomButton
            href={`mailto:`+this.props.appInfo.attachedApplicationInfo.applicant.email}
            target="_blank"
            isDisabled={proceedStatusSending}
          >
            Send Email
          </CustomButton>
          <CustomButton
            onClick={this.onProceedClick}
            isDisabled={!canProceed || proceedStatusSending}
          >
            {proceedStatusSending && canProceed
              ? 'Updating...'
              : 'Proceed with applicant'}
          </CustomButton>
          <CustomButton
            onClick={this.onRejectClick}
            isDisabled={!canReject || proceedStatusSending}
            customStyle={{ width: 300 }}
            variant="danger-contained"
          >
            {proceedStatusSending && canReject
              ? 'Updating...'
              : 'Do not proceed with applicant'}
          </CustomButton>
          <CustomButton
            onClick={this.onGoBackClick}
            isDisabled={proceedStatusSending}
          >
            Go Back
          </CustomButton>
        </span>
        <p>Below are documents for {firstName}. You can download or print each document by clicking the respective download or print buttons in the upper right corner of each window.</p>
      </div>
    );
  };

  renderEssayPDF = (title, answer, prepend) => {
    const pretext = prepend === undefined ? '' : prepend;
    const answerText = !answer ? 'n/a' : answer;
    return (
      <View key={title}>
        <View>
          <Text style={styles.text}>{`${pretext} ${title}`}</Text>
        </View>
        {/* TODO: Well replacement */}
        <View>
          <Text style={styles.paragraph}>{answerText}</Text>
        </View>
      </View>
    );
  };

  renderLicensureItem = (licensure, index) => (
    <li
      key={index}
    >
      {licensure.label}
    </li>
  );

  renderDocumentIframe = (url, name, index=undefined) => {
    if(url) {
      const key = index ? `${url}${index}` : key
      return (
        <div key={key}>
          <h3>{name}</h3>
          <Iframe
            url={url}
            width="90%"
            height="900px"
            id={url}
            display="initial"
            position="relative"
            allowFullScreen
          />
        </div>
      )
    } else {
      return <div />
    }
  };

  renderDocs = () => {
    const {
      resumeLink,
      transcriptLink,
      referenceLetterLinks,
    } = this.props.appInfo;
    return (
      <div style={styles.padLeftStyle}>
        {this.App()}
        {this.renderDocumentIframe(resumeLink, 'Resume', 'resume')}
        {this.renderDocumentIframe(transcriptLink, 'Transcript', 'transcript')}
        {referenceLetterLinks.map((refLink, index) =>
          this.renderDocumentIframe(refLink, `Reference Letter ${index + 1}`, `refletter${index}`)
        )}
      </div>
    );
  };

  render() {
    const { appInfo } = this.props;

    if (!appInfo || (!appInfo.loaded && !appInfo.failed)) {
      return <h2>Loading applicant information...</h2>;
    } else if (appInfo.loaded && appInfo.failed) {
      return <h2>Error loading applicant information</h2>
    } else if (appInfo.loaded) {
      return (
        <div>
          {this.renderTitle()}
          {this.renderDocs()}
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  appInfo: state.applicationView,
  email: state.auth.email,
  user: state.auth.user,
  isLoggingIn: state.auth.isLoggingIn,
  userIsEmployer: state.auth.userIsEmployer,
});

export default withRouter(
  connect(mapStateToProps,
    {
      getApplicationInfo,
      setApplicantProceedStatus,
    }
  )(ApplicationView)
);