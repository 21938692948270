import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
import Typography from '@mui/material/Typography';
import CustomCard from '../CustomCard/CustomCard';
import { withRouter } from 'react-router-dom';

// TODO: might have to eventually pass in other attrubutes of each teacher as props
class TeacherListItem extends Component {
    onTeacherClick = () => {
      this.props.history.push(this.props.linkTo)
    };

    render() {
        const { interestStatus, nameString, city, state, Licensure, Degree, YearsOfExp } = this.props;
        let color = interestStatus === null ? '' : '';
        if (interestStatus === 'green') {
            color = 'succes';
        }

        return (
          <ListGroup.Item onClick={this.onTeacherClick} action variant={color} style={{ cursor: 'pointer' }}>
            <CustomCard customStyle={{ width: 590 }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h5">{nameString}</Typography>
              </div>
              <Typography color="textSecondary">{city}, {state}</Typography>
              <Typography component="p">{Licensure}</Typography>
              <Typography component="p">{Degree}</Typography>
              <Typography component="p">{YearsOfExp}</Typography>
            </CustomCard>
          </ListGroup.Item>
        );
    }
}

export default withRouter(TeacherListItem);
