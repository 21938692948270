import * as types from '../actions/types';

const INITIAL_STATE = {
    teacherRefLetters: null,
    gettingTeacherRL: false,
    uploadError: null,
};

// 0: id
// 1: docid (firebase)
// 2: filename
// 3: downloadURL
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_TEACHER_REFERENCE_LETTER:
            return { ...state, gettingTeacherRL: true };
        case types.GET_TEACHER_REFERENCE_LETTER_SUCCESS:
            return { ...state, teacherRefLetters: action.payload, gettingTeacherRL: false };
        case types.GET_TEACHER_REFERENCE_LETTER_FAIL:
            return { ...state, gettingTeacherRL: false };
        case types.UPLOAD_TEACHER_REFERENCE_LETTER_SUCCESS: {
            const refLetters = state.teacherRefLetters.slice();
            refLetters.push(action.payload)
            return { ...state, teacherRefLetters: refLetters, uploadError: false };
        }
        case types.UPLOAD_TEACHER_REFERENCE_LETTER_FAIL:
            return { ...state, uploadError: true };
        case types.RENAME_TEACHER_REFERENCE_LETTER: {
            // payload here is: {docId, newName}
            const refLetters = state.teacherRefLetters.slice();
            return { ...state, teacherRefLetters: refLetters.map( doc => {
                // payload.docId is the uuid making up the filename on Firebase
                // that value is stored in state in index 0 of the array
                // array index 2 in the array in state is the friendly name of the file
                if (doc[1] == action.payload.docId) {
                    doc[2] = action.payload.newName
                }
                return doc
            })}
        }
        default:
            return state;
    }
};
