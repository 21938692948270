import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  addQuestionToPool,
  removeQuestionsFromPool,
} from '../../state/actions/employerSettings';
import {
  hideNewQuestionAddedAlert,
  hideDeleteQuestionsAlert,
} from '../../state/actions/alerts';
import { logoutUser } from '../../state/actions/auth';
import NewQuestionAddedAlert from '../../components/NewQuestionAddedAlert/NewQuestionAddedAlert';
import DeleteQuestionsAlert from '../../components/DeleteQuestionsAlert/DeleteQuestionsAlert';
import CustomButton from '../../components/CustomButton/CustomButton';
import PageLayout from '../PageLayout/PageLayout';
import { getQuestions } from '../../state/actions/postings';

// TODO: We should add the new question immediately to state.employer.questions,
// would need another dispatch
class DistrictSettings extends Component {
  // TODO: redirect to homepage if not employer
  state = { newQuestion: '', selectedToRemove: [] };

  // Send user to the home page if they're not a logged-in employer
  componentDidMount() {
    const { user, history, userIsEmployer } = this.props;
    if ((user == null && this.props.isLoggingIn == false) || userIsEmployer == false) {
      history.push('/');
    // To get here, userIsEmployer has to be not false, but it could be null
    } else if (user !== null && this.props.isLoggingIn == false && userIsEmployer == true) {
      this.props.getQuestions();
    }
  }

  componentDidUpdate() {
    const { user, history, userIsEmployer } = this.props;
    if ((user == null && this.props.isLoggingIn == false) || userIsEmployer == false) {
      history.push('/');
    } else if (user !== null && this.props.isLoggingIn == false && userIsEmployer == true) {
      if (this.props.gettingEmployerQuestions == null) {
        this.props.getQuestions();
      }
    }
  }

  onAddQuestionClick = () => {
    this.props.addQuestionToPool(this.state.newQuestion);
    this.setState({ newQuestion: '' });
  };

  onRemoveQuestionsClick = () => {
    this.props.removeQuestionsFromPool(this.state.selectedToRemove);
    this.setState({ selectedToRemove: [] });
    // setTimeout(window.location.reload(), 3000);
  };

  // TODO: need to update local state after updating questions
  // removeFromLocalState = () => {
  //   this.state.selectedToRemove.forEach(questionquery => {
  //     let i;
  //     for (i = 0; i < this.state.questions.length; i += 1) {
  //       if (this.state.quesitons[i].value === questionquery) {
  //         this.state.questions.splice(i, 1);
  //       }
  //     }
  //   });
  // }

  onReturnButtonClick = () => {
    // TODO: Make a single function that clears all alerts
    this.props.hideNewQuestionAddedAlert();
    this.props.hideDeleteQuestionsAlert();
    this.props.history.push('/postings');
  };

  render() {
    const { isAddingQuestion, isDeletingQuestions } = this.props;
    const addQuestionButtonText = isAddingQuestion
      ? 'Adding...'
      : 'Add question';
    const deleteQuestionsButtonText = isDeletingQuestions
      ? 'Deleting...'
      : 'Remove questions';

    return (
      <PageLayout>
        <div>
          <NewQuestionAddedAlert />
          <DeleteQuestionsAlert />
          <h4>Add additional essay questions</h4>
          <h5>
            <em>
              Any questions you add here will be able to be added to any future
              jobs you post
            </em>
          </h5>
          <Form>
            <Form.Group controlId="formControlsTextarea">
              <Form.Label>New essay question:</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter your essay question here..."
                value={this.state.newQuestion}
                disabled={isAddingQuestion}
                onChange={e => this.setState({ newQuestion: e.target.value })}
              />
            </Form.Group>
            <CustomButton
              onClick={this.onAddQuestionClick}
              isDisabled={
                this.state.newQuestion.length <= 0 || isAddingQuestion
              }
            >
              {addQuestionButtonText}
            </CustomButton>
          </Form>
          <br />
          <h4>Remove essay questions</h4>
          <h5>
            <em>
              Select all of the essay questions you want to remove from your
              pool and click the remove questions button
            </em>
          </h5>
          <Select
            value={this.state.selectedToRemove}
            components={makeAnimated()}
            onChange={newSelections =>
              this.setState({ selectedToRemove: newSelections })}
            options={this.props.questions}
            placeholder="Select all questions to remove..."
            isMulti
          />
          <br />
          <CustomButton
            onClick={this.onRemoveQuestionsClick}
            isDisabled={
              this.state.selectedToRemove.length <= 0 || isDeletingQuestions
            }
          >
            {deleteQuestionsButtonText}
          </CustomButton>
        </div>
      </PageLayout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  userIsEmployer: state.auth.userIsEmployer,
  isLoggingIn: state.auth.isLoggingIn,
  isAddingQuestion: state.employerSettings.isAddingNewQuestion,
  isDeletingQuestions: state.employerSettings.isDeletingQuestions,
  questions: state.employer.questions,
  gettingEmployerQuestions: state.employer.gettingEmployerQuestions,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      addQuestionToPool,
      removeQuestionsFromPool,
      hideNewQuestionAddedAlert,
      hideDeleteQuestionsAlert,
      logoutUser,
      getQuestions,
    }
  )(DistrictSettings)
);
