import * as types from './types';

export const showNewJobPostedAlert = () => ({
  type: types.SHOW_NEW_JOB_POSTED_ALERT,
});

export const hideNewJobPostedAlert = () => ({
  type: types.HIDE_NEW_JOB_POSTED_ALERT,
});

// These are shown via dispatch in employerSettings.js
export const hideNewQuestionAddedAlert = () => ({
  type: types.HIDE_NEW_QUESTION_ALERT,
});

export const hideDeleteQuestionsAlert = () => ({
  type: types.HIDE_DELETE_QUESTIONS_ALERT,
});
