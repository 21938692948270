import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
import Iframe from 'react-iframe';
import CustomModal from '../CustomModal/CustomModal';
import CustomButton from '../CustomButton/CustomButton';

class DocListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };
    }

    openSendEmailModal = () => {
        this.setState({ isModalOpen: true });
    };

    onCloseClick = () => {
        const close = window.close();
        close();
    };

    onCloseModalClick = () => {
        this.setState({ isModalOpen: false });
    }

    onApplicantClick = () => {
        this.openSendEmailModal();
    };

    renderModalContent = () => (
      <div key={this.props.link}>
        <div style={{ textAlign: 'right' }}>
          <CustomButton
            onClick={this.onCloseModalClick}
            customStyle={{ width: 30, height: 30 }}
          >
            X
          </CustomButton>
        </div>
        <Iframe
          url={this.props.link}
          width="600px"
          height="700px"
          id={this.props.link}
          display="initial"
          position="relative"
          allowFullScreen
        />
      </div>

        )

    render() {
        const color = '';
        return (
          <div>
            <CustomModal
              isOpen={this.state.isModalOpen}
              onClose={() => this.setState({
                        isModalOpen: false
                    })}
              modalContent={this.renderModalContent()}
            />
            <ListGroup.Item onClick={this.onApplicantClick} action variant={color} style={{ cursor: 'pointer' }}>
              {this.props.nameString}
            </ListGroup.Item>
          </div>
        );
    }
}

export default DocListItem;
