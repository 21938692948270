import React, { Component } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const options = [
  { value: 'pre-kindergarten', label: 'Pre - Kindergarten' },
  { value: 'kindergarten', label: 'Kindergarten' },
  { value: '1st grade', label: '1st Grade' },
  { value: '2nd grade', label: '2nd Grade' },
  { value: '3rd grade', label: '3rd Grade' },
  { value: '4th grade', label: '4th Grade' },
  { value: '5th grade', label: '5th Grade' },
  { value: '6th grade', label: '6th Grade' },
  { value: '7th grade', label: '7th Grade' },
  { value: '8th grade', label: '8th Grade' },
  { value: '9th grade', label: '9th Grade' },
  { value: '10th grade', label: '10th Grade' },
  { value: '11th grade', label: '11th Grade' },
  { value: '12th grade', label: '12th Grade' },
];

class GradeLevelSelect extends Component {
  render() {
    return (
      <Select
        defaultValue={this.props.defaultValue}
        components={makeAnimated()}
        onChange={this.props.onChange}
        options={options}
        placeholder="Grade level - select all that apply"
        maxMenuHeight={200}
        isMulti
      />
    );
  }
}

export default GradeLevelSelect;
