import Theme from '../../theme/Theme';

const baseStyle = {
  height: 40,
  margin: 8,
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
};

const styles = {
  containedButton: {
    ...baseStyle,
    color: 'white',
    backgroundColor: Theme.ThemePrimary,
    borderColor: Theme.ThemePrimary,
    fontFamily: [
      'Nunito',
    ].join(','),
    '&:hover': {
      backgroundColor: Theme.ThemePrimaryHovered,
      borderColor: Theme.ThemePrimaryHovered,
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: Theme.ThemePrimary,
      borderColor: Theme.ThemePrimary,
    },
    '&:disabled': {
      backgroundColor: Theme.ThemeDisabled,
      borderColor: Theme.ThemeDisabled,
    },
  },
  outlinedButton: {
    ...baseStyle,
    color: Theme.ThemePrimary,
    borderColor: Theme.ThemePrimary,
    fontFamily: [
      'Nunito',
    ].join(','),
    '&:hover': {
      backgroundColor: Theme.ThemePrimaryHovered,
      color: 'white',
      borderColor: Theme.ThemePrimaryHovered,
    },
    '&:active': {
      boxShadow: 'none',
      borderColor: Theme.ThemePrimary,
    },
    '&:disabled': {
      backgroundColor: Theme.ThemeDisabled,
      borderColor: Theme.ThemeDisabled,
    },
  },
  dangerOutlinedButton: {
    ...baseStyle,
    color: Theme.ThemeDanger,
    borderColor: Theme.ThemeDanger,
    fontFamily: [
      'Nunito',
    ].join(','),
    '&:hover': {
      backgroundColor: Theme.ThemeDangerHovered,
      borderColor: Theme.ThemeDangerHovered,
      color: 'white',
    },
    '&:active': {
      boxShadow: 'none',
      borderColor: Theme.ThemeDanger,
    },
    '&:disabled': {
      backgroundColor: Theme.ThemeDisabled,
      borderColor: Theme.ThemeDisabled,
    },
  },
  dangerContainedButton: {
    ...baseStyle,
    color: 'white',
    backgroundColor: Theme.ThemeDanger,
    borderColor: Theme.ThemeDanger,
    fontFamily: [
      'Nunito',
    ].join(','),
    '&:hover': {
      backgroundColor: Theme.ThemeDangerHovered,
      borderColor: Theme.ThemeDangerHovered,
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: Theme.ThemeDanger,
      borderColor: Theme.ThemeDanger,
    },
    '&:disabled': {
      backgroundColor: Theme.ThemeDisabled,
      borderColor: Theme.ThemeDisabled,
    },
  },
  successOutlinedButton: {
    ...baseStyle,
    color: Theme.ThemeSuccess,
    borderColor: Theme.ThemeSuccess,
    fontFamily: [
      'Nunito',
    ].join(','),
    '&:hover': {
      backgroundColor: Theme.ThemeSuccessHovered,
      borderColor: Theme.ThemeSuccessHovered,
    },
    '&:active': {
      boxShadow: 'none',
      borderColor: Theme.ThemeSuccess,
    },
    '&:disabled': {
      backgroundColor: Theme.ThemeDisabled,
      borderColor: Theme.ThemeDisabled,
    },
  },
  successContainedButton: {
    ...baseStyle,
    color: 'white',
    backgroundColor: Theme.ThemeSuccess,
    borderColor: Theme.ThemeSuccess,
    fontFamily: [
      'Nunito',
    ].join(','),
    '&:hover': {
      backgroundColor: Theme.ThemeSuccessHovered,
      borderColor: Theme.ThemeSuccessHovered,
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: Theme.ThemeSuccess,
      borderColor: Theme.ThemeSuccess,
    },
    '&:disabled': {
      color: 'white',
      backgroundColor: Theme.ThemeSuccess,
      borderColor: Theme.ThemeSuccess,
    },
  },
  noOutlineButton: {
    ...baseStyle,
    border: '',
    color: Theme.ThemePrimary,
    fontFamily: [
      'Nunito',
    ]
  },
};

export default styles;
