import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PageLayout from '../PageLayout/PageLayout';
import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy';
import styles from '../TermsAndConditionsPage/TermsAndConditionsPage.styles';

class TermsAndConditionsPage extends Component {
    render() {
        return (
          <PageLayout>
            <div style={styles.pageContainer}>
              <PrivacyPolicy />
            </div>
          </PageLayout>
        );
    }
}

export default withRouter(TermsAndConditionsPage);
