import * as types from '../actions/types';

const INITIAL_STATE = {
    teachers: null,
    gettingTeachers: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.GET_TEACHERS:
            return { ...state, gettingTeachers: true };
        case types.GET_TEACHERS_SUCCESS:
            return { ...state, teachers: action.payload, gettingTeachers: false };
        case types.GET_TEACHERS_FAIL:
            return { ...state, gettingTeachers: false };
        default:
            return state;
    }
};
