import * as types from '../actions/types';

const INITIAL_STATE = {
  latitude: null,
  longitude: null,
  gettingUserGeo: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.UPDATE_CURRENT_GEO:
      return {
        ...INITIAL_STATE,
        latitude: action.payload.coords.latitude,
        longitude: action.payload.coords.longitude,
        gettingUserGeo: false,
      };
      case types.UPDATE_CURRENT_GEO_FROM_ZIPCODE:
        return {
          ...INITIAL_STATE,
          latitude: action.payload.latitude,
          longitude: action.payload.longitude,
          gettingUserGeo: false,
        };
      case types.LOOKUP_CURRENT_GEO:
        return {
          ...INITIAL_STATE,
          gettingUserGeo: true,
        };
        case types.UPDATE_CURRENT_GEO_FAILED:
          return {
            ...INITIAL_STATE,
            gettingUserGeo: false,
          };
    default:
      return state;
  }
};
