import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

class ApplicantListItem extends Component {
  onApplicantClick = () => {
    this.props.history.push(this.props.linkTo)
  };

  render() {
    const { proceedStatus, nameString } = this.props;
    let color = proceedStatus === null || proceedStatus === "" ? '' : 'success';
    if (proceedStatus === 'red') {
      color = 'danger';
    }

    return (
      <ListGroup.Item onClick={this.onApplicantClick} action variant={color} style={{ cursor: 'pointer' }}>
        {nameString}
      </ListGroup.Item>
    );
  }
}

export default withRouter(ApplicantListItem);
